import { each } from "lodash";
import { getObject, setObject } from "./storage-helpers";

export const STORE_PERSIST_KEY = "ap_store_persist";

let dataMap = getObject(STORE_PERSIST_KEY);

const updateStore = (key, value) => {
  dataMap = {
    ...dataMap,
    [key]: value
  };
  setObject(STORE_PERSIST_KEY, dataMap);
};

export const fromStorage = (key, defaultValue) =>
  typeof dataMap[key] !== "undefined" ? dataMap[key] : defaultValue;

export const toStorage = selectorMap => state => {
  each(selectorMap, (selector, key) => {
    const val = selector(state);
    if (!dataMap[key] || val !== dataMap[key]) {
      updateStore(key, val);
    }
  });
};

export const resetDataMap = newDataMap => {
  dataMap = newDataMap;
};
