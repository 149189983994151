export const INIT_PREBIND_MODAL = "esign-prebind/INIT_PREBIND_MODAL";
const LOADING = "esign-prebind/LOADING";
const RESULT = "esign-prebind/RESULT";
const RESET_FORM = "esign-prebind/RESET_FORM";
const BACK_TO_FORM = "esign-prebind/BACK_TO_FORM";

export const initPreBindModal = data => ({
  type: INIT_PREBIND_MODAL,
  quote: data.quote || {},
  showPreBind: data.showPreBind,
  dontNavigateOnCompletion: data.dontNavigateOnCompletion
});

export const loading = data => ({
  type: LOADING,
  isLoading: data.isLoading
});

export const result = data => ({
  type: RESULT,
  isSuccess: data.isSuccess,
  error: data.error,
  insuredEmail: data.insuredEmail,
  producerEmail: data.producerEmail,
  signers: data.signers,
  isFLSIC: data.isFLSIC
});

export const resetForm = () => ({
  type: RESET_FORM
});

export const backToForm = () => ({
  type: BACK_TO_FORM
});

export const initState = {
  showPreBind: false,
  quote: {},
  showForm: true,
  isLoading: false,
  showResult: false,
  error: "",
  isSuccess: false,
  emailSentTo: ""
};

export const esignPreBind = (
  state = {
    ...initState
  },
  data
) => {
  switch (data.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
        showForm: false,
        showResult: false
      };
    }
    case RESULT: {
      return {
        ...state,
        isLoading: false,
        showResult: true,
        showForm: false,
        isSuccess: data.isSuccess,
        error: data.error,
        insuredEmail: data.insuredEmail,
        producerEmail: data.producerEmail,
        signers: data.signers,
        isFLSIC: data.isFLSIC
      };
    }
    case RESET_FORM: {
      return initState;
    }
    case INIT_PREBIND_MODAL: {
      return {
        ...initState,
        quote: data.quote,
        showPreBind: data.showPreBind,
        dontNavigateOnCompletion: data.dontNavigateOnCompletion
      };
    }
    case BACK_TO_FORM: {
      return {
        ...state,
        isLoading: false,
        showResult: false,
        error: "",
        showForm: true
      };
    }
    default:
      return state;
  }
};
