import { pick } from "lodash";

import { selectQuoteViewData } from "./quote-view";

export * from "./feature-flags";

export const selectAll = (state, selectors) =>
  selectors.map(selector => selector(state));

const pickStateCreator =
  stateKey =>
  (state, ...keys) => {
    const selectedState =
      typeof stateKey === "function" ? stateKey(state) : state[stateKey];
    return keys.length ? pick(selectedState, keys) : selectedState;
  };
export const pickEnvInfo = pickStateCreator("envInfo");
export const pickNotices = pickStateCreator("notices");
export const pickContacts = pickStateCreator("contacts");
export const pickQuoteQuickStart = pickStateCreator("quoteQuickStart");
export const pickRecentActivity = pickStateCreator("recentActivity");
export const pickQuoteGrid = pickStateCreator("quoteGrid");
export const pickQuoteView = pickStateCreator("quoteView");
export const pickQTC = pickStateCreator("quoteTransactionComponent");
export const pickQuoteViewData = pickStateCreator(selectQuoteViewData);
export const pickTransactions = pickStateCreator("transactions");
export const pickUsers = pickStateCreator("users");
export const pickPaymentPlan = pickStateCreator("paymentPlan");
export const pickEsign = pickStateCreator("esign");
export const pickEsignPreBind = pickStateCreator("esignPreBind");
export const pickNavigation = pickStateCreator("navigation");
export const pickIPM = pickStateCreator("ipm");

export const selectAuthString = state =>
  state.envInfo.agentToken != null
    ? `AgentToken ${state.envInfo.agentToken}`
    : `Basic ${state.envInfo.b64}`;

export const selectIsFednat = state => state.envInfo.organizationID === "fnic";

const organizationNames = {
  cru: "SageSure",
  fnic: "Monarch National"
};
export const selectOrganizationName = state =>
  organizationNames[state.envInfo.organizationID] || "";

export const selectMessages = state => state.messages;
