import $ from "jquery";
import AP from "../legacy/global";

export default quoteId =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${AP.config.services.pop.url}cru-4/${
        AP.config.services.pxcentral.baseUrl
      }policies/${quoteId}/paymentplans`,
      type: "GET",
      dataType: "text",
      beforeSend: xhr => {
        xhr.setRequestHeader(
          "Authorization",
          `Basic ${AP.config.usePxClientAuthForIxServices.authHash}`
        );
      }
    })
      .done(data => {
        resolve({
          plans: xml2json(data)
        });
      })
      .fail((xhr, status, error) => {
        reject(error);
      });
  });

const xml2json = xml => {
  const jsonResult = [];
  const re = /<PaymentPlan (.+?)\/>/gi;
  let myArray;
  while ((myArray = re.exec(xml)) !== null) {
    const attrsStr = myArray[1] && myArray[1].trim();
    if (!attrsStr) return;
    jsonResult.push(
      attrsStr.split(" ").reduce((acc, attr) => {
        const matches = attr.match(/([a-z]+)="(.*?)"/i);
        return {
          ...acc,
          [matches[1]]: matches[2]
        };
      }, {})
    );
  }

  return jsonResult;
};
