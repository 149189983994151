import { getSearchResults, getSuggestResults } from "../promises/quotes";
import shouldRenderSuggestions from "../utils/should-render-suggestions";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickEnvInfo, pickQuoteGrid } from "./selectors";
import debounce from "../utils/debounce";

const PAGE_SIZE = 20;
const SUGGEST_PAGE_SIZE = 5;
const SUGGEST_DEBOUNCE_TIME = 10;

const RESULTS = "quote-grid/RESULTS";
const ERROR = "quote-grid/ERROR";
const SUGGESTIONS = "quote-grid/SUGGESTIONS";
const SEARCH_TEXT_CHANGE = "quote-grid/SEARCH_TEXT_CHANGE";
const SEARCH_TEXT_SUBMITTED = "quote-grid/SEARCH_TEXT_SUBMITTED";
const FILTER_CHANGE = "quote-grid/FILTER_CHANGE";
const SORT_CHANGE = "quote-grid/SORT_CHANGE";
const PAGE_CHANGE = "quote-grid/PAGE_CHANGE";

const setResults = ({ results, total, page }) => ({
  type: RESULTS,
  results,
  totalResultsCount: total,
  page,
  totalPages: Math.ceil(total / PAGE_SIZE)
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

const setSuggestions = suggestions => ({
  type: SUGGESTIONS,
  suggestions
});

const searchTextSubmitted = text => ({
  type: SEARCH_TEXT_SUBMITTED,
  text
});

const searchTextChange = text => ({
  type: SEARCH_TEXT_CHANGE,
  text
});

const filterChange = filters => ({
  type: FILTER_CHANGE,
  filters
});

const sortChange = sortField => ({
  type: SORT_CHANGE,
  sortField
});

const pageChange = page => ({
  type: PAGE_CHANGE,
  page
});

let searchCount = 0;
const searchQuotes = (dispatch, getState, page = 1) => {
  const state = getState();
  const { username } = pickEnvInfo(state);
  const {
    submittedText,
    filters: { createdByUser, quotes, policies },
    sortField,
    sortAscending
  } = pickQuoteGrid(state);
  const payload = {
    searchTerm: submittedText,
    showAll: !createdByUser,
    quotes,
    policies,
    pageSize: PAGE_SIZE,
    from: (page - 1) * PAGE_SIZE,
    sortFields: sortField,
    sortDir: sortAscending ? "asc" : "desc",
    userId: username
  };
  searchCount += 1;
  const thisSearch = searchCount;
  return getSearchResults(filterEmpty(payload))
    .then(
      response =>
        thisSearch === searchCount &&
        dispatch(
          setResults({
            results: response.hits.hits,
            total: response.hits.total,
            page
          })
        )
    )
    .catch(
      error => thisSearch === searchCount && dispatch(setError(error.message))
    );
};

export const submitTextSearch = text => (dispatch, getState) => {
  dispatch(searchTextSubmitted(text));
  return searchQuotes(dispatch, getState);
};

export const submitFilterChange = filters => (dispatch, getState) => {
  dispatch(filterChange(filters));
  return searchQuotes(dispatch, getState);
};

export const submitSortChange = sortField => (dispatch, getState) => {
  dispatch(sortChange(sortField));
  return searchQuotes(dispatch, getState);
};

export const submitPageChange = page => (dispatch, getState) => {
  dispatch(pageChange(page));
  return searchQuotes(dispatch, getState, page);
};

const searchSuggestionsDebounced = debounce((dispatch, getState) => {
  const {
    searchText,
    suggestions,
    filters: { createdByUser }
  } = pickQuoteGrid(getState());
  if (shouldRenderSuggestions(searchText)) {
    return getSuggestResults({
      searchTerm: searchText,
      pageSize: SUGGEST_PAGE_SIZE,
      showAll: !createdByUser
    }).then(response => {
      const {
        quoteGrid: { searchText: currentSearchText }
      } = getState();
      if (searchText.toLowerCase() !== currentSearchText.toLowerCase()) {
        return;
      }
      if (response && response.suggestions && response.suggestions.length) {
        return dispatch(
          setSuggestions(
            response.suggestions[0].options.filter(option => option.text)
          )
        );
      }
      return dispatch(setSuggestions([]));
    });
  } else {
    if (suggestions.length > 0) {
      dispatch(setSuggestions([]));
    }
  }
}, SUGGEST_DEBOUNCE_TIME);

export const updateSearchText = text => (dispatch, getState) => {
  dispatch(searchTextChange(text));
  searchSuggestionsDebounced(dispatch, getState);
  const { submittedText } = pickQuoteGrid(getState());
  if (!text && submittedText) {
    dispatch(submitTextSearch(""));
  }
};

export const quoteGrid = (
  state = {
    loadingState: PRELOAD,
    results: [],
    totalResultsCount: 0,
    errorMessage: null,
    suggestions: [],
    searchText: "",
    submittedText: "",
    filters: {
      createdByUser: false,
      quotes: true,
      policies: true
    },
    sortField: "InsurancePolicy.updateTimestamp",
    sortAscending: false,
    page: 1,
    totalPages: 1
  },
  data
) => {
  switch (data.type) {
    case RESULTS: {
      return {
        ...state,
        results: data.results,
        totalResultsCount: data.totalResultsCount,
        loadingState: LOADED,
        errorMessage: null,
        page: data.page,
        totalPages: data.totalPages
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        results: [],
        totalResultsCount: 0,
        page: 1,
        totalPages: 0
      };
    }
    case SUGGESTIONS: {
      return {
        ...state,
        suggestions: data.suggestions
      };
    }
    case SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: data.text
      };
    }
    case SEARCH_TEXT_SUBMITTED: {
      return {
        ...state,
        submittedText: data.text,
        loadingState: LOADING
      };
    }
    case FILTER_CHANGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...data.filters
        },
        loadingState: LOADING
      };
    }
    case SORT_CHANGE: {
      return data.sortField === state.sortField
        ? {
            ...state,
            sortAscending: !state.sortAscending,
            loadingState: LOADING
          }
        : {
            ...state,
            sortField: data.sortField,
            loadingState: LOADING
          };
    }
    case PAGE_CHANGE: {
      return {
        ...state,
        page: data.page,
        loadingState: LOADING
      };
    }
    default:
      return state;
  }
};

function filterEmpty(data) {
  const r = {};
  Object.keys(data).forEach(key => {
    let val = data[key];
    if (typeof val === "string") {
      val = val.trim();
    }
    if (typeof val === "undefined" || val === null || val === "") return;
    r[key] = val;
  });
  return r;
}
