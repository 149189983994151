const MODAL_SHOW = "modals/MODAL_SHOW";
const MODAL_HIDE = "modals/MODAL_HIDE";

export const modalShow = ({ quoteId, policyId }) => ({
  type: MODAL_SHOW,
  quoteId,
  policyId
});

export const modalHide = () => ({
  type: MODAL_HIDE
});

export const ipm = (
  state = {
    showModal: false,
    quoteId: null,
    policyId: null
  },
  { type, ...payload }
) => {
  switch (type) {
    case MODAL_SHOW: {
      return {
        ...state,
        showModal: true,
        quoteId: payload.quoteId,
        policyId: payload.policyId
      };
    }
    case MODAL_HIDE: {
      return {
        ...state,
        showModal: false
      };
    }
    default:
      return state;
  }
};
