const convertStringToBoolean = str => {
  const booleanString = str.toLowerCase();
  if (booleanString === "true") {
    return true;
  } else if (booleanString === "false") {
    return false;
  }
  return null;
};
export default applicationSettings => {
  const pxServerObject = applicationSettings.filter(
    obj => obj.applicationName === "pxserver"
  );
  const defaultObject = {
    dateAppointed: null,
    isLicensee: null,
    isPrimaryContact: null,
    isPrincipal: null,
    licenseNumber: null
  };
  if (!pxServerObject.length) {
    return defaultObject;
  } else {
    return pxServerObject[0].children.reduce((acc, curr) => {
      if (curr.name === "DataItem") {
        const {
          attrs: { name = null, value = null }
        } = curr;
        if (name === "dateAppointed") {
          acc.dateAppointed = value;
        }
        if (name === "isLicensee") {
          acc.isLicensee = convertStringToBoolean(value);
        }
        if (name === "isPrimaryContact") {
          acc.isPrimaryContact = convertStringToBoolean(value);
        }
        if (name === "isPrincipal") {
          acc.isPrincipal = convertStringToBoolean(value);
        }
        if (name === "licenseNumber") {
          acc.licenseNumber = +value;
        }
      }
      return acc;
    }, defaultObject);
  }
};
