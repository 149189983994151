import $ from "jquery";

import AP from "../legacy/global";

export default ({
  commit = false,
  policyId,
  insightPolicyId,
  type,
  user,
  version,
  reasonCode,
  reason,
  IPMAction = null,
  specificChanges = null,
  effectiveDate = null,
  authString
}) =>
  new Promise((resolve, reject) => {
    try {
      $.ajax({
        type: "POST",
        url: `${AP.config.services.pop.proxy}${ENV_INFO.programID}/${AP.config.services.pxcentral.baseUrl}policies/${insightPolicyId}`,
        data: buildTran({
          policyId,
          insightPolicyId,
          type,
          user,
          version,
          reasonCode,
          reason,
          specificChanges,
          effectiveDate
        }),
        dataType: "text",
        beforeSend: xhr => {
          xhr.setRequestHeader(
            "Authorization",
            authString
              ? authString
              : `Basic ${AP.config.application.mxAuth.authHash}`
          );
          xhr.setRequestHeader(
            "Content-Type",
            "application/xml; schema=transactionrequest.1.4"
          );
          xhr.setRequestHeader("X-Commit", commit);
          if (IPMAction) {
            xhr.setRequestHeader("X-IPM-Action", IPMAction);
          }
          xhr.setRequestHeader(
            "Accept",
            "application/vnd.ics360.insurancepolicy.2.8+xml"
          );
        }
      })
        .done(resolve)
        .fail(reject);
    } catch (e) {
      reject(e);
    }
  });

const buildTran = ({
  policyId,
  insightPolicyId,
  type,
  user,
  version,
  reasonCode,
  reason,
  specificChanges,
  effectiveDate
}) => {
  return `<?xml version="1.0"?>
<TransactionRequest schemaVersion="1.4" type="${type}">
  <Initiation>
    <Initiator type="user">${user}</Initiator>
  </Initiation>
  <Target>
    <Identifiers>
      <Identifier name="InsightPolicyId" value="${insightPolicyId}"/>
      <Identifier name="PolicyID" value="${policyId}"/>
    </Identifiers>
    <SourceVersion>${version}</SourceVersion>
  </Target>
  ${effectiveDate ? `<EffectiveDate>${effectiveDate}</EffectiveDate>` : ""}
  <ReasonCode>${reasonCode}</ReasonCode>
  <Comment>${reason}</Comment>
  ${specificChanges ? specificChanges : ""}
</TransactionRequest>`;
};
