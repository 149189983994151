import {
  chain,
  flowRight,
  partition,
  identity,
  sortBy,
  template
} from "lodash";

import { printDate } from "../../../utils/dates";
import formatCurrency from "../format-currency";

const compile = templateString =>
  template(templateString, {
    interpolate: /(?:\{\{|<%=)([\s\S]+?)(?:\}\}|%>)/g
  });

const mapDisplayFormat = {
  currency: formatCurrency,
  currencyPrecise: value => formatCurrency(value, true)
};

export const parseRenderingSequence = flowRight(
  sections => sections.map(meta => meta.map(({ name }) => name)),
  sections => sections.map(meta => meta.sort((a, b) => a.value - b.value)),
  meta => partition(meta, ({ section }) => section === "H"),
  meta =>
    meta.map(({ name, value }) => ({
      name,
      value: parseInt(value.substr(1), 10),
      section: value.substr(0, 1)
    }))
);

export const splitContextItemsWithPipe = context =>
  context.reduce((acc, item) => {
    if (item.name === "RenderingSequence") {
      return acc;
    }
    const category = item.meta.find(({ name }) => name === "Category");
    return category && category.value.includes("|")
      ? [
          ...acc,
          ...category.value.split("|").map((categoryValue, pipeIndex) => ({
            ...item,
            meta: item.meta.map(({ name, value }) =>
              name === "Category"
                ? {
                    name,
                    value: categoryValue
                  }
                : {
                    name,
                    value: value.includes("|")
                      ? value.split("|")[pipeIndex]
                      : value
                  }
            )
          }))
        ]
      : [...acc, item];
  }, []);

export const mapMetaArrayToObject = context =>
  context.map(item => ({
    ...item,
    meta: item.meta.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value
      }),
      {}
    )
  }));

export const filterOutNonDisplayed = context =>
  context.filter(
    ({ meta: { Displayed = "" } }) => Displayed.toLowerCase() === "true"
  );

export const execDisplayHandling = (template, data) => {
  try {
    return compile(template)({
      ...data,
      $: Object.assign(name => data[name] || "", data)
    })
      .replace("\\n", "\n")
      .trim();
  } catch (ex) {
    console.error(`Missing data in QV context template: ${template}`, ex);
    return "";
  }
};

export const mapContextToData = data => context =>
  context.map(item => {
    const { enumerations = {}, meta = {}, name } = item;
    if (meta.DisplayHandling) {
      return {
        ...item,
        value: execDisplayHandling(meta.DisplayHandling, data)
      };
    }
    const inputValue = data[name] || "";
    if (enumerations.enumerations) {
      const enumItem = enumerations.enumerations.find(
        ({ value }) => value === inputValue
      );
      return {
        ...item,
        value: enumItem ? enumItem.label : inputValue
      };
    }
    return {
      ...item,
      value: inputValue
    };
  });

export const filterOutDisplayIfEmpty = context =>
  context.filter(
    ({ value, meta: { DisplayIfEmpty } = {} }) =>
      !DisplayIfEmpty || value || DisplayIfEmpty.toLowerCase() === "true"
  );

export const filterOutLocations = context => {
  const numLocations = context.find(item => item.name === "NumberOfLocations");
  if (numLocations) {
    const num = parseInt(numLocations.value, 10);
    if (num < 5) {
      // RegExp for unused location fields, like /Location[2-5]$/ for 1 location
      const re = new RegExp(`Location[${num + 1}${num < 4 ? "-5" : ""}]$`);
      return context.filter(
        item => !re.test(item.name) && !addNewLocationFilter(item)
      );
    }
    return context.filter(item => !addNewLocationFilter(item));
  }
  return context;
};

const addNewLocationFilter = item =>
  item.meta.Category.startsWith("Add New Location");

export const formatData = context =>
  context.map(
    ({
      meta: { Category = "", Sequence = "0", DisplayFormat = "" } = {},
      dataType,
      label = "",
      value,
      name
    }) => {
      const passData = {
        category: Category,
        sequence: parseInt(Sequence, 10),
        displayFormat: DisplayFormat,
        label,
        value,
        name
      };
      if (dataType?.toLowerCase() === "datetime") {
        return {
          ...passData,
          value: printDate(value)
        };
      }
      if (DisplayFormat) {
        return {
          ...passData,
          value: (mapDisplayFormat[DisplayFormat] || identity)(value)
        };
      }
      return passData;
    }
  );

// executeContext process
// 1. Save and parse RenderingSequence to later sort the categories
// 2. Replace context items with piped meta data into separate context items
// 3. Map meta to object for easier lookup
// 4. Filter out based on Displayed meta
// 5. Get value from quote inputs and recognize DisplayHandling and Enum dataTypes
// 6. Filter out based on DisplayIfEmpty meta
// +  When multi-location is supported filter out empties and Add New Location section
// 7. Apply DisplayFormat meta and dataType DateTime
// 8. Group by Category meta
// 9. Sort category data by Sequence meta
// 10. Return the categories in the RenderingSequence order

export default (context, data) => {
  // 1.
  const [[generalTitle, ...headerSequence], bodySequence] =
    parseRenderingSequence(
      context.find(({ name }) => name === "RenderingSequence").meta
    );

  const contextItems = flowRight(
    // 7.
    formatData,
    // 6.
    filterOutDisplayIfEmpty,
    filterOutLocations,
    // 5.
    mapContextToData(data),
    // 4.
    filterOutNonDisplayed,
    // 3.
    mapMetaArrayToObject,
    // 2.
    splitContextItemsWithPipe
  )(context);

  const sectionMap = chain(contextItems)
    // 8.
    .groupBy("category")
    // 9.
    .mapValues(category => sortBy(category, "sequence"))
    .value();

  // 10.
  return {
    generalInfoSection: {
      title: generalTitle,
      items: sectionMap[generalTitle]
    },
    headerSections: headerSequence
      .filter(name => sectionMap[name])
      .map(name => ({
        title: name,
        items: sectionMap[name]
      })),
    bodySections: bodySequence
      .filter(name => sectionMap[name])
      .map(name => ({
        title: name,
        items: sectionMap[name]
      }))
  };
};
