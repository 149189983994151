export const CURRENT_STATEMENT_SUMMARY = [
  {
    label: "Total Balance",
    key: "OutstandingBalance",
    location: "accounting",
    type: "currencyPrecise",
    defaultValue: "0"
  },
  {
    label: "Past Due Balance",
    key: "PastDueBalance",
    location: "accounting",
    type: "currencyPrecise",
    defaultValue: "0"
  },
  {
    label: "Minimum Payment",
    key: "MinimumPaymentDue",
    location: "accounting",
    type: "currencyPrecise",
    defaultValue: "0"
  },
  {
    label: "Payment Due Date",
    key: "InvoiceDueDateCurrent",
    location: "accounting",
    type: "date"
  },
  {
    label: "Most Recent Invoice",
    key: "InvoiceDateCurrent",
    location: "accounting",
    type: "date"
  }
];

export const ACCOUNT_ACTIVITY_VALUES = {
  POLICY_ISSUED_NEW_BUSINESS: "New Business Policy Issued",
  INSTALLMENT_CHARGE: "Installment Charge",
  PAYMENT: "Payment",
  DISBURSE: "Disbursement",
  REVERSE_DISBURSE: "Reverse Disbursement",
  ENDORSE: "Endorsement",
  WRITE_OFF_CHARGE: "Charge Write Off",
  REVERSE_UNCOLLECTIBLE_CREDIT: "Reverse Uncollectible Credit",
  UNCOLLECTIBLE_CREDIT: "Uncollectible Credit",
  LATE_PAYMENT_CHARGE: "Late Payment Charge",
  REVERSE_PAYMENT: "Reverse Payment",
  CANCEL: "Cancel",
  REINSTATE: "Reinstate"
};

export const PAYMENT_ACTIVITY_VALUES = {
  "0": "Transfer",
  "200": "Check",
  "300": "ACH",
  "400": "Credit Card",
  ach: "ACH",
  creditcard: "Credit Card"
};

export const PAYMENT_INFORMATION = [
  {
    label: "Payor",
    key: accounting => accounting.Payor.OpPayorName,
    location: "accounting"
  },
  {
    label: "Payment Plan",
    key: accounting => accounting.PaymentPlan.type,
    location: "accounting",
    type: "payplan"
  },
  {
    label: "Next Payment Due Date",
    key: "InvoiceDueDateNext",
    location: "accounting",
    type: "date"
  },
  {
    label: "Next Payment Amount",
    key: "InvoiceAmountNext",
    location: "accounting",
    type: "currencyPrecise",
    defaultValue: "0"
  }
];

export const POLICYSTATE_LABELS = {
  ACTIVEPOLICY: "Active",
  CANCELLEDPOLICY: "Cancelled",
  NONRENEWEDPOLICY: "Non-Renewed",
  TRANSFERREDPOLICY: "Transferred"
};

export const POLICYSTATUS_LABELS = {
  PendingCancellation: "Pending Cancellation",
  PendingNonRenewal: "Pending Non-Renewal"
};
