import _ from "underscore";

// https://mathiasbynens.be/notes/localstorage-pattern
export const hasLocalStorage = _.memoize(() => {
  var uid = new Date();
  var result;
  try {
    localStorage.setItem(uid, uid);
    result = localStorage.getItem(uid) == uid;
    localStorage.removeItem(uid);
    return result;
  } catch (exception) {
    return false;
  }
});

export const hasSessionStorage = _.memoize(() => {
  var uid = new Date();
  var result;
  try {
    sessionStorage.setItem(uid, uid);
    result = sessionStorage.getItem(uid) == uid;
    sessionStorage.removeItem(uid);
    return result;
  } catch (exception) {
    return false;
  }
});
