import { isString } from "lodash";

export const isPresent = val => isString(val) && val.length > 0;

const reEmail = /^([a-zA-Z0-9+_.-]+)@([a-zA-Z0-9+_.-]+)\.([a-zA-Z]{2,12})$/;
export const isEmail = val => reEmail.test(val);

const reZip = /^\d{5}(?:[-\s]\d{4})?$/;
export const isZip = val => reZip.test(val);

export function isCheckNumberValid(checkNum) {
  const pattern = /^[0-9]*$/;

  return pattern.test(checkNum);
}

export function isAccountValid(accountNum) {
  const pattern = /^[0-9]*$/;

  return pattern.test(accountNum);
}

export function isRoutingValid(routingNum) {
  const pattern = /^[0-9]{9}$/;

  return pattern.test(routingNum);
}

export function isValidCard(cardNumber) {
  const pattern = /^\d{15,16}$/;

  return pattern.test(cardNumber);
}

export function isValidMonth(month) {
  return month ? month <= 12 && month > 0 : false;
}

export function isValidYear(year) {
  return year ? year >= new Date().getFullYear() : false;
}

export function isValidExpirationDate(month, year) {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const monthVal = parseInt(month);
  const yearVal = parseInt(year);

  if (!month || !year) {
    return false;
  }

  return yearVal === currentYear
    ? monthVal >= currentMonth
      ? isValidMonth(monthVal)
      : false
    : isValidYear(yearVal) && isValidMonth(monthVal);
}
