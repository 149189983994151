let AP;

// export reset for testing purposes
export const reset = () => {
  AP = {};
  window.AP = AP;
};

reset();

export default AP;
