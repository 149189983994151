// This file is used for the old quick quote page. /react-src/components/create-quote-page/old

import $ from "jquery";
import { has } from "lodash";
import { ADDRESS_VALIDATION } from "../units/address-input";

const ADDRESS_SERVICING_URL = "/addressservicing/api/rest/v1/properties";
const beforeSend = xhr => {
  xhr.setRequestHeader("Cache-Control", "no-cache");
};

const postOrPut = (type, url, data) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url,
      type,
      beforeSend,
      contentType: "application/json",
      data: JSON.stringify(data)
    })
      .done((responseData, textStatus, xhr) => {
        const location = xhr.getResponseHeader("Location");
        if (location && responseData.result.response) {
          resolve({
            url: location,
            propertyId: responseData.result.response.property.id
          });
        } else {
          reject();
        }
      })
      .fail(() => {
        reject();
      });
  });

export const post = data => postOrPut("POST", ADDRESS_SERVICING_URL, data);
export const put = (data, propertyId) =>
  postOrPut("PUT", `${ADDRESS_SERVICING_URL}/${propertyId}`, data);
export const get = url =>
  new Promise(resolve => {
    $.ajax({
      url,
      type: "GET",
      beforeSend,
      dataType: "json",
      timeout: 30000
    })
      .done(data => {
        if (
          data &&
          data.done &&
          data.result &&
          data.result.response &&
          data.result.response.property
        ) {
          resolve(data.result.response.property);
        } else {
          resolve();
        }
      })
      .fail(() => {
        resolve();
      });
  });

let propertyIdPromise = null;
let propertyUrlPromises = {};
export const clearProperty = () => {
  propertyIdPromise = null;
  propertyUrlPromises = {};
};
export const addPropertyWithProductId = (address, productId) => {
  if (!address || has(propertyUrlPromises, productId)) {
    return;
  }
  const validation =
    address.validation === ADDRESS_VALIDATION.VALIDATED ? "Validated" : "";
  const data = {
    propertyStreetNumber: address.streetNumber,
    propertyStreetName: address.route,
    propertyAddressLine2: address.subpremise,
    propertyCity: address.city,
    propertyState: address.state,
    propertyZipCode: address.postalCode,
    latitude: address.lat,
    longitude: address.lng,
    geocodeMatchLevel: address.locationType || validation,
    productId,
    quoteOriginationSystem: "Agent Portal"
  };
  if (propertyIdPromise) {
    propertyUrlPromises[productId] = propertyIdPromise
      .then(({ propertyId }) => put(data, propertyId))
      .catch(() => {});
  } else {
    propertyIdPromise = post(data).catch(() => {
      clearProperty();
    });
    propertyUrlPromises[productId] = propertyIdPromise;
  }
};

export const getPropertyByProductId = productId =>
  propertyUrlPromises[productId] || Promise.resolve();

export const getPropertyDetails = ({ productId }) =>
  getPropertyByProductId(productId)
    .then(({ url } = {}) => (url ? get(url) : undefined))
    .then(({ details } = {}) => details);
