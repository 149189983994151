import { isNaN } from "lodash";

import { numberFormat } from "../../utils/deterministic-string";

const formatCurrency = (value, isPrecise = false) => {
  if (!value) return value;
  const num = Math.abs(parseFloat(value.replace(",", "")));
  if (isNaN(num)) return value;
  const isNegative = value.indexOf("-") === 0;
  return `${isNegative ? "-" : ""}$${numberFormat(num, isPrecise ? 2 : 0)}`;
};

export default formatCurrency;
