import { FormControl, Icon, MenuItem } from "@icg360/ui-toolkit";
import { useInputDebounce } from "@icg360/rex";
import PT from "prop-types";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { chain, invert, uniqBy } from "lodash";
import { toSentence } from "../../../utils/deterministic-string";
import {
  ADDRESS_VALIDATION,
  singleLineAddress
} from "../../../units/address-input";
import toString from "../../../utils/deterministic-string";
import AddressInput from "../../toolkit/address-input";
import AddressSearch from "../../toolkit/address-search";
import LocatePropertyModal from "../../toolkit/locate-property";
import { InputMeta } from "../renderers/input-meta";
import {
  changePremisesBuildingNumbers,
  flattenInputs,
  formatAddress
} from "../utils";
import { Action } from "../renderers/action";

// This is for cloning addresses between components
const inMemoryAddresses = {};

const isPartialAddress = address => {
  if (!address) return false;
  return [
    ADDRESS_VALIDATION.PARTIALLY_CITY,
    ADDRESS_VALIDATION.PARTIALLY_STATE,
    ADDRESS_VALIDATION.PARTIALLY_STREET
  ].includes(address.validation);
};

const fusionNameToAddressName = {
  streetName: "route",
  addressLine2: "subpremise",
  zipcode: "postalCode",
  zipcodePlus4: "postalCodeSuffix"
};

const addressNameToFusionName = invert(fusionNameToAddressName);
const mapObjectKeysWith = (object, mapper) =>
  chain(object)
    .toPairs()
    .map(([key, val]) => [mapper[key] || key, val])
    .fromPairs()
    .value();

AddressFusion.propTypes = {
  disabled: PT.bool,
  groupLabel: PT.string.isRequired,
  isCommercial: PT.bool,
  onChange: PT.func.isRequired,
  value: PT.object
};

AddressFusion.defaultProps = {
  disabled: false,
  isCommercial: false
};

function AddressFusion({
  value: address,
  isCommercial,
  disabled,
  onChange,
  groupLabel
}) {
  const [value, setValue] = useState("");
  const [partialAddress, setPartialAddress] = useState(null);
  const [useLocatePropertyModal, setUseLocatePropertyModal] = useState(false);
  const addressInputRef = useRef(null);

  const setAddress = nextAddress => {
    if (!isCommercial && isPartialAddress(nextAddress)) {
      setPartialAddress(nextAddress);
    } else if (nextAddress) {
      setValue("");
      onChange(nextAddress);
    } else {
      onChange();
    }
  };

  const display = address ? singleLineAddress(address) : "";
  const cloneAddresses = uniqBy(
    Object.entries(inMemoryAddresses)
      .filter(([key, clone]) => clone && key !== groupLabel)
      .map(([, clone]) => ({
        clone,
        label: singleLineAddress(clone)
      })),
    ({ label }) => label
  );

  return (
    <Fragment>
      <AddressInput
        ref={addressInputRef}
        includeEstablishments={isCommercial}
        isLocked={!!display}
        readOnly={disabled}
        onAddress={setAddress}
        onChange={setValue}
        onEnter={({ type, highlightIndex }) => {
          if (
            type === AddressInput.DROPDOWN_TYPES.DEFAULT &&
            highlightIndex >= 0
          ) {
            setAddress(cloneAddresses[highlightIndex].clone);
          }
        }}
        defaultMenuItemCount={cloneAddresses.length}
        renderDefault={
          cloneAddresses.length > 0
            ? // eslint-disable-next-line react/display-name, react/prop-types
              ({ highlightIndex }) => (
                <Fragment>
                  <MenuItem header>Existing Locations</MenuItem>
                  {cloneAddresses.map(({ clone, label }, index) => (
                    <MenuItem
                      key={`${label}-${index}`}
                      onSelect={() => {
                        setAddress(clone);
                        addressInputRef.current.focus();
                      }}
                      className="AddressInput-suggestion"
                      active={highlightIndex === index}
                      title={label}
                    >
                      <Icon name="history" className="icon" size="sm" /> {label}
                    </MenuItem>
                  ))}
                </Fragment>
              )
            : undefined
        }
        renderFallback={() => (
          <li>
            <AddressSearch
              query={value}
              onAddress={setAddress}
              render={({ data, dataMatchesRequest }) => {
                if (!dataMatchesRequest) {
                  return null;
                }
                if (!isCommercial) {
                  return (
                    <p>
                      {dataMatchesRequest && "Try a different search or "}
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setUseLocatePropertyModal(true);
                        }}
                      >
                        {dataMatchesRequest ? "l" : "L"}ocate the property on a
                        map.
                      </a>
                    </p>
                  );
                }
                if (!data.length) {
                  return <p>Please try a different search.</p>;
                }
              }}
            />
          </li>
        )}
        size="large"
        value={display || value}
        inputProps={{
          placeholder: isCommercial
            ? "Search by address or business name..."
            : "Search by address..."
        }}
      />
      <LocatePropertyModal
        initialAddress={partialAddress}
        show={!isCommercial && (useLocatePropertyModal || !!partialAddress)}
        onDone={property => {
          setAddress(property);
          setPartialAddress(null);
          setUseLocatePropertyModal(false);
        }}
      />
    </Fragment>
  );
}

Subpremise.propTypes = {
  value: PT.string,
  onChange: PT.func.isRequired,
  disabled: PT.bool
};

function Subpremise({ value, onChange, disabled }) {
  const [internalVal, setInternalVal, flush] = useInputDebounce({
    value,
    onChange,
    wait: 1200
  });
  return (
    <FormControl
      type="text"
      value={internalVal}
      onChange={setInternalVal}
      onBlur={() => {
        setTimeout(flush, 100);
      }}
      readOnly={disabled}
    />
  );
}

// Fusion
// name: address
// inputs: display, streetNumber, streetName, addressLine2, city, state, zipcode, zipcodePlus4
// submission: validate
export const Address = ({
  inputs,
  onChange,
  onSubmit,
  passthroughProps,
  groupLabel
}) => {
  const { addressInputs, addressLine2, validateButton } = extractAddressInputs(
    inputs,
    passthroughProps.isCommercial
  );
  const address = addressInputs.reduce(
    (acc, { fusionName, value }) =>
      fusionName
        ? { ...acc, [fusionNameToAddressName[fusionName] || fusionName]: value }
        : acc,
    {}
  );

  const hasValue = chain(address)
    .pick(
      "streetNumber",
      "streetName",
      "city",
      "state",
      "zipcode",
      "zipcodePlus4"
    )
    .some()
    .value();

  useEffect(() => {
    inMemoryAddresses[groupLabel] = hasValue ? address : undefined;
    return () => {
      delete inMemoryAddresses[groupLabel];
    };
  }, [toString(address), hasValue, groupLabel]);
  const dispatch = useDispatch();

  // We are automatically triggering a validate call so let's
  // wait 5 seconds before we possibly show the button.
  // A user will only need the button to trigger a retry.
  const [isSubmitting, setSubmitting] = useState(false);
  const submittingTimer = useRef(null);
  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit("validate");
    clearTimeout(submittingTimer.current);
    submittingTimer.current = setTimeout(() => {
      setSubmitting(false);
    }, 5000);
  };

  const handleChange = nextAddress => {
    if (nextAddress && !nextAddress.subpremise) {
      delete nextAddress.subpremise;
    }
    const nextFusionAddress = nextAddress
      ? mapObjectKeysWith(nextAddress, addressNameToFusionName)
      : {
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          zipcode: "",
          zipcodePlus4: ""
        };
    onChange(nextFusionAddress);
    changePremisesBuildingNumbers({
      groupLabel,
      nextAddress: formatAddress(nextFusionAddress),
      addressFusionDetails: passthroughProps.addressFusionDetails,
      dispatch
    });
    if (nextAddress && !isPartialAddress(nextAddress)) {
      handleSubmit();
    }
  };

  const handleLine2Change = addressLine2 => {
    onChange({ addressLine2 });
  };

  const generateCustomMessage = ({ errorType, inputType }) => {
    if (
      ["minvalue", "maxvalue"].includes(errorType) &&
      inputType === "zipcode"
    ) {
      return {
        customMessage:
          "Property location is not eligible for the current product."
      };
    }
  };

  const validation = addressInputs.reduce(
    (
      { required, errors },
      { isRequired, validationErrors = [], label, value, type: inputType }
    ) => ({
      required: isRequired ? [...required, label] : required,
      errors: value
        ? [
            ...errors,
            ...validationErrors.map(error => ({
              ...error,
              message: `${label}: ${error.message}`,
              ...generateCustomMessage({
                errorType: error.type,
                inputType
              })
            }))
          ]
        : errors
    }),
    {
      required: [],
      errors: []
    }
  );

  return (
    <Fragment>
      <InputMeta
        className="QTCInput--address-fusion"
        {
          /* The streetNumber fusion will be the best representative for meta props such as read-only */
          ...addressInputs.find(
            ({ fusionName }) => fusionName === "streetNumber"
          )
        }
        ids={addressInputs.map(({ id }) => id).filter(i => i)}
        touched={hasValue}
        isRequired={validation.required.length > 0}
        hint={
          hasValue
            ? [
                toSentence(validation.required),
                validation.required.length === 1 ? "is" : "are",
                "required"
              ].join(" ")
            : ""
        }
        validationErrors={validation.errors}
        value={hasValue ? address : null}
        onChange={handleChange}
        groupLabel={groupLabel}
        passthroughProps={passthroughProps}
        controlProps={{
          groupLabel,
          isCommercial: passthroughProps.isCommercial
        }}
        Control={AddressFusion}
        layoutType={InputMeta.LAYOUT_TYPES.FULL}
      />
      {!isSubmitting &&
        validateButton &&
        validateButton.value !== "100" &&
        !validateButton.isReadOnly && (
          <Action
            {...validateButton}
            passthroughProps={passthroughProps}
            handleClick={handleSubmit}
          />
        )}
      {addressLine2 && (
        <InputMeta
          {...addressLine2}
          passthroughProps={passthroughProps}
          onChange={handleLine2Change}
          groupLabel={groupLabel}
          Control={Subpremise}
        />
      )}
    </Fragment>
  );
};

Address.propTypes = {
  onChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  inputs: PT.array.isRequired,
  passthroughProps: PT.object,
  groupLabel: PT.string.isRequired
};

// flatten the inputs and seperate addressLine2 and the validate button
function extractAddressInputs(inputs, isCommercial) {
  const flatInputs = flattenInputs(inputs, isCommercial);
  const addressInputs = [];
  let addressLine2 = null;
  let validateButton = null;

  flatInputs.forEach(input => {
    if (input.fusionName === "addressLine2") {
      addressLine2 = input;
      return;
    }
    if (input.fusionName === "validate") {
      validateButton = input;
      return;
    }
    addressInputs.push(input);
  });
  return { addressInputs, addressLine2, validateButton };
}
