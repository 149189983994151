import { chain, isString, isDate } from "lodash";

export const readFromCookies = (cookies, name) => {
  const cookie = chain(cookies.split(";"))
    .map(cookie => cookie.trim())
    .find(cookie => cookie.indexOf(`${name}=`) === 0)
    .value();
  return isString(cookie) ? cookie.substring(name.length + 1) : null;
};

const defaultCookieOptions = {
  path: "/"
};
// does not encode the value
export const createCookie = (name, value, options) => {
  const opts = chain({ ...defaultCookieOptions, ...options })
    .map((value, key) => [isDate(value) ? value.toUTCString() : value, key])
    .filter(([value]) => isString(value))
    .map(([value, key]) => `;${key}=${value}`)
    .value()
    .join("");
  return `${name}=${value}${opts}`;
};

export const read = name => readFromCookies(document.cookie, name);

export const write = (...args) => {
  document.cookie = createCookie(...args);
};
