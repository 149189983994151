import { hasLocalStorage } from "./feature-detection";
import * as cookies from "./cookies";

const cookieFallback = {
  setItem: (name, value) => {
    cookies.write(encodeURIComponent(name), encodeURIComponent(value), {
      expires: "Fri, 31 Dec 9999 23:59:59 GMT"
    });
  },
  getItem: name => {
    const value = cookies.read(encodeURIComponent(name));
    return value ? decodeURIComponent(value) : value;
  },
  removeItem: name => {
    cookies.write(encodeURIComponent(name), "", {
      expires: "Thu, 01 Jan 1970 00:00:00 GMT"
    });
  }
};

export default hasLocalStorage() ? localStorage : cookieFallback;
