import htmlparser from "htmlparser2";
import { isEmpty, last } from "lodash";

export default identity => {
  const state = {
    context: [],
    appSettings: null,
    appSettingsChild: null,
    appSettingsContext: []
  };
  const result = {
    data: {},
    affiliations: [],
    applicationSettings: []
  };
  new htmlparser.Parser(
    {
      onopentag: (name, attrs) => {
        if (name === "Identity") {
          result.attrs = attrs;
        }
        if (last(state.context) === "Identity") {
          if (name === "DataItem" && last(state.context) === "Identity") {
            result.data[attrs.name] = attrs.value;
          }
          if (name === "Affiliation") {
            result.affiliations.push(attrs);
          }
          if (name === "ApplicationSettings") {
            state.appSettings = attrs;
            state.appSettingsContext = [state.appSettings];
          }
        }
        if (state.context.includes("ApplicationSettings")) {
          state.appSettingsChild = isEmpty(attrs)
            ? {
                name
              }
            : { name, attrs };
          const parent = last(state.appSettingsContext);
          if (parent.children) {
            parent.children.push(state.appSettingsChild);
          } else {
            parent.children = [state.appSettingsChild];
          }
          state.appSettingsContext.push(state.appSettingsChild);
        } else {
          state.context.push(name);
        }
      },
      ontext: rawText => {
        // collapse whitespace
        const text = rawText.replace(/\s+/g, " ").trim();
        if (!text) return;
        if (state.appSettings && state.appSettingsChild) {
          state.appSettingsChild.text = text;
          return;
        }
        const lastContext = last(state.context);
        if (["Name", "Email"].includes(lastContext)) {
          result[lastContext] = text;
        }
      },
      onclosetag: name => {
        if (name === "ApplicationSettings") {
          result.applicationSettings.push(state.appSettings);
          state.appSettings = null;
        }
        if (state.appSettings) {
          state.appSettingsChild = null;
          state.appSettingsContext.pop();
        } else {
          state.context.pop();
        }
      }
    },
    { xmlMode: true, decodeEntities: true }
  ).end(identity);
  return result;
};
