const ADD_MESSAGE = "messages/ADD_MESSAGE";
const CLOSE_MESSAGE = "messages/CLOSE_MESSAGE";

export const addMessage = message => ({
  type: ADD_MESSAGE,
  message
});

export const closeMessage = message => ({
  type: CLOSE_MESSAGE,
  message
});

export const messages = (state = [], { type, message }) => {
  switch (type) {
    case ADD_MESSAGE: {
      return [...state, message];
    }
    case CLOSE_MESSAGE: {
      const index = state.indexOf(message);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};
