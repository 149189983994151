import $ from "jquery";

import AP from "../legacy/global";

export default ({ inactive, programID, programConfigID }) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${AP.config.services.pop.proxy}${programID}/${AP.config.services
        .pxprogram2.baseUrl}programs/${programConfigID}/notices`,
      type: "GET",
      beforeSend: xhr => {
        const authString = AP.getAuthString();
        xhr.setRequestHeader("X-Crippled-Client", "yes");
        xhr.setRequestHeader("include-password", "true");
        xhr.setRequestHeader("Authorization", authString);
        xhr.setRequestHeader("X-Authorization", authString);
      },
      data: {
        inactive,
        nocache: Date.now()
      }
    })
      .done(resolve)
      .fail((xhr, status, error) => {
        reject(error);
      });
  });
