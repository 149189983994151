import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Checkbox } from "@icg360/ui-toolkit";
import PreloaderButton from "../../toolkit/preloader-button";
import { CloseIcon } from "../../svg-icons";
import { track } from "../../../units/tracking";
import { useFormik } from "formik";

const Feedback = ({
  vote,
  onNextStep,
  trackingKey,
  title = "Feedback",
  headingText = "We Value Your Feedback",
  upText = "Please give as much detail as you can on why the feature was helpful.",
  downText = "Please give as much detail as you can on why the feature was not helpful.",
  finalText = "Thanks for your feedback and for helping us improve!"
}) => {
  const [show, setShow] = useState(true);

  const formik = useFormik({
    initialValues: {
      feedbackComments: "",
      feedbackCanContact: false
    },
    onSubmit: ({ feedbackComments, feedbackCanContact }) => {
      track(`Submit Additional Feedback`, {
        trackingKey,
        vote,
        feedbackCanContact,
        feedbackComments
      });
      onNextStep();
    },
    validate: values => {
      return values.feedbackComments.trim()
        ? {}
        : { feedbackComments: "Please type a comment to submit" };
    }
  });

  const handleClose = () => {
    track(`Cancel Additional Feedback`, { trackingKey, vote });
    setShow(false);
    onNextStep({}, true);
  };

  return (
    <Modal
      dialogClassName="QTCSimpleModal-feedback"
      className="custom-modal"
      show={show}
      size="sm"
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <CloseIcon />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="FeedbackContainer">
          <div className="Instructions">
            <h2>{headingText}</h2>
            <p>{vote === "up" ? upText : downText}</p>
            <p>{finalText}</p>
          </div>
          <div className="FeedbackContainer__inputsection">
            <textarea
              name="feedbackComments"
              placeholder="Tell us more..."
              value={formik.values.feedbackComments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.touched.feedbackComments &&
            formik.errors.feedbackComments && (
              <div className="error-message">
                {formik.errors.feedbackComments}
              </div>
            )}

          <div className="FeedbackContainer__footer">
            <div className="FeedbackContainer__footer-left">
              <Checkbox
                checked={formik.values.canContact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="feedbackCanContact"
              >
                SageSure can contact me for more information
              </Checkbox>
            </div>
            <div className="FeedbackContainer__footer-right">
              <Button onClick={handleClose}>Cancel</Button>
              <PreloaderButton
                aria-label="submit-form"
                preloaderProps={{ color: "white" }}
                bsStyle="primary"
                type="submit"
                disabled={!formik.isValid}
                isLoading={formik.isSubmitting}
                data-bdd="send-message-preloader-btn"
              >
                Send Message
              </PreloaderButton>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

Feedback.propTypes = {
  vote: PropTypes.string.isRequired,
  onNextStep: PropTypes.func.isRequired,
  trackingKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  headingText: PropTypes.string,
  upText: PropTypes.string,
  downText: PropTypes.string,
  finalText: PropTypes.string
};

export default Feedback;
