import $ from "jquery";

import mapTransactionTypes from "../map-transaction-types";

export default response =>
  $(response)
    .find("Event")
    .toArray()
    .map(function(event) {
      var $event = $(event);
      var timeStamp = $event.attr("timeStamp");
      return {
        timeStamp: timeStamp,
        transaction: mapTransactionTypes($event.attr("type")),
        policyNumber: $event.attr("policyNumber"),
        quoteNumber: $event.attr("quoteNumber"),
        insuredLastName: $event.attr("insuredLastName"),
        insuredFirstName: $event.attr("insuredFirstName"),
        boundBy: $event.attr("bindingUser"),
        status: $event.attr("policyState"),
        transactionDate:
          $event.find('[name="AppliedDate"]').attr("value") ||
          $event.find('[name="TransactionDate"]').attr("value") ||
          timeStamp,
        effectiveDate: $event.find('[name="EffectiveDate"]').attr("value")
      };
    })
    .filter(
        ({ transaction }) => transaction !== "Sent to Print Vendor"
    );

