import $ from "jquery";
import AP from "../legacy/global";

export default easypay =>
  new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url:
        AP.config.services.shared.url +
        AP.config.services.esign.baseUrl +
        AP.config.services.esign.easyPayUrl,
      data: JSON.stringify({
        ...easypay,
        systemOfOrigin: "1000" //PPQ-5242 enum from ixVocab PaymentClientID
      }),
      dataType: "json",
      headers: {
        Authorization: "Basic " + AP.config.application.mxAuth.authHash,
        "Content-Type": "application/json"
      },
      success: resolve,
      error: reject
    });
  });
