import $ from "jquery";

let promise = null;
export default function() {
  if (promise) {
    return promise;
  }
  promise = new Promise((resolve, reject) => {
    $.ajax({
      url: `/AP7/config?nocache=${new Date().getTime()}`,
      type: "GET",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      error: reject,
      success: data => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      }
    });
  });
  return promise;
}

// For testing purposes
export const reset = () => {
  promise = null;
};
