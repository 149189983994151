import { Auth } from "@icg360/auth-js";
import $ from "jquery";

import AP from "./legacy/global";
import getOrganization from "./promises/get-organization";
import getTerms from "./promises/get-terms";
import handleIXDirectoryAuth from "./promises/handle-ixdirectory-auth";
import handlePXServerAuth from "./promises/handle-pxserver-auth";
import handleSAMLPassthrough from "./promises/handle-saml-passthrough";
import loadConfig from "./promises/load-config";
import { loadEnvInfo } from "./store/env-info";
import { initFeatureFlags } from "./store/feature-flags";
import { hasFeature, pickEnvInfo } from "./store/selectors";
import setupGoogleMapAPI from "./units/setup-google-map-api";
import * as tracking from "./units/tracking";
import { logout } from "./utils/creds";
import userDataFromAppSettings from "./utils/pxserver-settings-parser";
import storage from "./utils/storage";
import parseIdentity from "./utils/xml-parsers/identity";
import qualtrics from "./utils/qualtrics";

export default async function (store, envInfo, updateStatus, reporter) {
  const config = await loadConfig();
  AP.config = config;

  // setup qualtrics tracking
  qualtrics(config.application.qualtrics);

  // Either the b64 cookie or the authString cookie (SAML) has to be set,
  // otherwise we need to send the user to the login screen
  if ((!envInfo.b64 && !envInfo.authString) || isInactive()) {
    logout();
    return;
  }

  // Use ixAuth to check if cookie is valid otherwise logout
  const client = new Auth({
    servicesUrl: config.services.ixauth.url
  });
  const auth = await client.identify().catch(reporter);
  if (!auth?.identity?.id) {
    logout();
    return;
  }

  updateStatus("Loading user...");
  // Next Todo: Replace `getUserDetails` and `parseUserData` with just
  // user data returned from ixAuth
  const {
    pxServerUser,
    identity,
    loginType = ""
  } = await getUserDetails({
    config,
    envInfo
  });

  const userData = parseUserData({
    pxServerUser,
    identity,
    env: config.application.environmentName,
    organizationID: envInfo.organizationID
  });

  // Load org data, terms, and Google API code.
  // All of these should fallback safely
  const [org, terms] = await Promise.all([
    userData.alc
      ? getOrganization({
          alc: userData.alc,
          agentToken: envInfo.agentToken,
          config
        }).catch(() => {
          reporter("Failed to load organization data.");
          return {};
        })
      : {},
    getTerms().catch(() => {
      reporter("Failed to load vocab terms.");
      return {};
    }),
    setupGoogleMapAPI(config.services.google.maps.apiKey)
  ]);

  store.dispatch(
    loadEnvInfo({
      ...envInfo,
      ...userData,
      loginType,
      org: {
        agencyLocationCode: org.agencyLocationCode || "",
        pupStatus: org.producerProgramStatus || "",
        agencyName: org.agencyName || ""
      }
    })
  );

  // Assign user data to Qualtrics Survey data
  // eslint-disable-next-line no-unused-labels
  window.SURVEY_USER_ALC = userData.alc;
  // // eslint-disable-next-line no-unused-labels
  window.SURVEY_USER_NAME = userData.fullname;
  // // eslint-disable-next-line no-unused-labels
  window.SURVEY_USER_ID = userData.username;
  // The encoding is done in a way to ensure no Character out of Range error that atob produces.
  // // eslint-disable-next-line no-unused-labels
  window.EmailAddressID = window.btoa(
    unescape(encodeURIComponent(userData.email))
  );

  // Legacy, set user info to AP object
  Object.assign(AP, envInfo, {
    vocab: terms,
    pxServerUser: $(pxServerUser),
    identity: $(identity),
    u: userData.username,
    uname: userData.fullname,
    email: userData.email,
    agencyLocationId: userData.alc || null,
    isAdmin: userData.isAdmin,
    isBackoffice: userData.isBackoffice,
    adminModeActive: false,
    doLogout: logout,
    getAuthString: createGetAuthString(envInfo),
    getEventTarget
  });

  const state = store.getState();

  // Setup tracking
  tracking.init({
    ...config.application.mixpanel,
    enabled:
      !!config.application.mixpanel?.enabled && hasFeature(state, "mixpanel")
  });
  tracking.configureUser(pickEnvInfo(state));

  updateStatus("Loading app...");
  return Promise.all([
    import(/* webpackChunkName: "app" */ "./portal-app"),
    // Feature flags couldn't start sooner because it requires org data.
    config.application.launchDarklyID &&
      store
        .dispatch(initFeatureFlags(config.application.launchDarklyID))
        .catch(e => {
          reporter("Launch Darkly failed to get feature flags");
          reporter(e);
          return;
        })
  ]).then(([app]) => app);
}

function getUserDetails({ config, envInfo }) {
  const username = window.atob(envInfo.b64).split(":")[0];
  if (envInfo.agentToken) {
    return handleSAMLPassthrough(envInfo.authString).catch(() => {
      alert("MXHR FAILURE - BAD AUTH-SESSION-URI DATA");
    });
  }
  return Promise.all([
    handlePXServerAuth(username, envInfo.b64, envInfo.programID, config),
    handleIXDirectoryAuth(username, envInfo.b64, config)
  ]).then(([pxServerUser, identity]) => ({
    pxServerUser,
    identity
  }));
}

function isInactive() {
  const storedLastActive = storage.getItem("last-active");
  if (storedLastActive) {
    const lastActive = parseInt(storedLastActive, 10);
    // Is the difference between now and our last active session greater than 6 hours
    if (new Date().getTime() - lastActive > 6 * 60 * 60 * 1000) {
      return true;
    }
  }
  return false;
}

function createGetAuthString(envInfo) {
  return () =>
    envInfo.agentToken != null
      ? `AgentToken ${envInfo.agentToken}`
      : `Basic ${envInfo.b64}`;
}

function getEventTarget(e) {
  var target;
  if (!e) e = window.event;
  if (e.target) target = e.target;
  else if (e.srcElement) target = e.srcElement;
  //if safari
  if (target.nodeType == 3) target = target.parentNode;
  return target;
}

function selectRoles($pxServerUser) {
  return $pxServerUser
    .find("role")
    .toArray()
    .map(role => ($(role).attr("id") || "").toUpperCase());
}
function selectIsLocalAdmin($pxServerUser) {
  return (
    $pxServerUser.find(
      'right[softwareapplication="pxserver"][resource="users"][segment="agency-own"][action="manage"][access="grant"]'
    ).length > 0
  );
}

function parseUserData({ pxServerUser, identity, env, organizationID }) {
  const $pxServerUser = $(pxServerUser);
  const {
    attrs: { id: username },
    data: { channel = "", phoneNumber = "" },
    Name: fullname,
    Email: email,
    affiliations,
    applicationSettings
  } = parseIdentity(identity);

  const { target: alc = "", targetName: affiliation = "" } =
    affiliations.find(({ type }) => type === "agent_location") || {};
  const roles = rolesFromAppSettings({
    applicationSettings,
    env,
    organizationID
  });

  return {
    alc,
    affiliation,
    channel,
    email,
    fullname,
    isLocalAdmin: selectIsLocalAdmin($pxServerUser),
    isAdmin: roles.includes("CONTENT_ADMIN"),
    isBackoffice: roles.includes("backoffice"),
    phone: phoneNumber,
    roles: selectRoles($pxServerUser),
    username,
    ...userDataFromAppSettings(applicationSettings) // returns {dateAppointed, isLicensee, isPrimaryContact, isPrincipal, licenseNumber}
  };
}
function rolesFromAppSettings({ applicationSettings, env, organizationID }) {
  const appSettings = applicationSettings.find(
    ({ applicationName, organizationId, environmentName }) =>
      applicationName === "agentportal" &&
      organizationId === organizationID &&
      environmentName === env
  );
  if (!appSettings || !appSettings.children) return "";
  const roles = appSettings.children.find(({ name }) => name === "roles");
  return roles && roles.text ? roles.text : "";
}
