import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Preloader } from "@icg360/ui-toolkit";
import "./clear-form-control.scss";

const LoaderFormControl = ({
  children,
  className = "",
  isLoading = false,
  size = "large"
}) => {
  return (
    <div className={`loader-form-container ${size} ${className}`}>
      {children}
      {isLoading && <Preloader data-bdd="preloader" size={size} />}
    </div>
  );
};

LoaderFormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "large"])
};

const StyledLoaderFormControl = styled(LoaderFormControl)`
  position: relative;

  svg.preloader {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 5px;
    top: 5px;
  }
`;

export default StyledLoaderFormControl;
