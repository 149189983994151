import React from "react";
import PropTypes from "prop-types";
import { Radio } from "@icg360/ui-toolkit";

export const RadioButtons = ({ details, selected, onChange, disabled, id }) => {
  return (
    <div className="radioContainer">
      {details.map(option => (
        <Radio
          name={id}
          checked={option === selected}
          value={option.value}
          onChange={onChange}
          key={option.value}
          disabled={disabled}
        >
          {option.label}
        </Radio>
      ))}
    </div>
  );
};

RadioButtons.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};
