import { Button, Preloader } from "@icg360/ui-toolkit";
import PT from "prop-types";
import React from "react";

const cssClass = "PreloaderButton";

const PreloaderButton = ({
  className,
  isLoading,
  preloaderProps,
  children,
  ...props
}) => (
  <Button
    className={className ? [className, cssClass].join(" ") : cssClass}
    {...props}
    data-loading={isLoading}
  >
    <span className="buttonText">{children}</span>
    <Preloader size="24" {...preloaderProps} />
  </Button>
);

PreloaderButton.propTypes = {
  className: PT.string,
  isLoading: PT.bool,
  preloaderProps: PT.object,
  children: PT.node
};

PreloaderButton.defaultProps = {
  className: "",
  isLoading: false,
  preloaderProps: {},
  children: null
};

export default PreloaderButton;
