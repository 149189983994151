import $ from "jquery";
// eslint-disable-next-line
import "mxhr";

export default authURI =>
  new Promise((resolve, reject) => {
    const agentToken = authURI.split("/").pop();
    let count = 0;
    let identity;
    let pxServerUser;

    $.mxhr({
      url: authURI,
      type: "GET",
      data: "",
      beforeSend: xhr => {
        xhr.setRequestHeader("X-Crippled-Client", "yes");
        xhr.setRequestHeader("X-Method", "GET");
        xhr.setRequestHeader("X-Rest-Method", "GET");
        xhr.setRequestHeader("Authorization", `AgentToken ${agentToken}`);
        xhr.setRequestHeader("X-Authorization", `AgentToken ${agentToken}`);
      },
      failure: err => {
        reject(err);
        return;
      },
      "application/xml": response => {
        count += 1;
        const data = $.trim(response);

        if (data.indexOf("<Identity") === 0) {
          identity = data;
        } else if (data.indexOf("<user") === 0) {
          pxServerUser = data;
        }

        if (identity && pxServerUser) {
          resolve({
            identity,
            pxServerUser,
            agentToken,
            loginType: "SAML"
          });
          return;
        }

        if (count === 2) {
          reject("Invalid SAML data.");
        }
      }
    });
  });
