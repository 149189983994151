import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import PT from "prop-types";
import styled from "styled-components";
import classnames from "classnames";
import { useQuery } from "react-query";
import { chain, find, debounce } from "lodash";
import { Dropdown, FormControl, MenuItem } from "@icg360/ui-toolkit";
import { externalResetChildForm } from "@icg360/rex";

import { QTC_FORM_ID } from "../constants";
import ClearFormControl from "../../toolkit/clear-form-control";
import LoaderFormControl from "../../toolkit/loader-form-control";
import { InputMeta } from "../renderers/input-meta";
import useContainsFocus from "../../../hooks/use-contains-focus";
import { toggleMortgageeModal } from "../../../store/quote-transaction-component";

/**
 * ---------------------------------------------------------------------------------
 * There are three functional components in this file:
 * 1. MortgageeSearchFusion - the actual input field with dropdown
 * 2. MortgageeDisplay - the textarea input that is used to display the value chosen
 * 3. MortgageeSearch - the fusion component that handles the onChange event and whether to display
 *    MortgageeSearchFusion or MortgageeDisplay (depending on if there is a value chosen)
 * ---------------------------------------------------------------------------------
 */

const SEARCH_THRESHOLD = 3;

// The fusionName value of all the inputs that are required for this fusion to work
const REQUIRED_INPUTS = [
  "company",
  "addressLine1",
  "addressLine2",
  "city",
  "state",
  "zip"
];

/**
 * This mapping is necessary because the "keys" coming from product (fusionName)
 * are not the same as the keys coming from the backend api (where we get the listings for the Mortgagees search).
 * So this maps backend api keys to product fusion names.
 */
const mortgageeApiKeyToFusionName = {
  Mortgagee0Name: "company",
  Mortgagee0AddressLine1: "addressLine1",
  Mortgagee0AddressLine2: "addressLine2",
  Mortgagee0City: "city",
  Mortgagee0State: "state",
  Mortgagee0ZipCode: "zip"
};

const mapObjectKeysWith = (object, mapper) =>
  chain(object)
    .toPairs()
    .map(([key, val]) => [mapper[key] || key, val])
    .fromPairs()
    .value();

const MortgageeSearchInput = styled.div`
  .has-error && {
    margin-bottom: 5px;
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  max-height: 322px;
  width: 266px;
  left: -1px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const DropdownHeader = styled(MenuItem).attrs({ header: true })`
  background-color: #f5f5f5;
  color: #999999;
  padding: 10px 14px;
`;
const DropdownSuggestion = styled(MenuItem)`
  && {
    border-top: 1px solid #cccccc;
    padding: 0;
    width: 266px;
    overflow-x: hidden;

    a {
      color: #777777;
      display: block;
      padding: 10px;
      white-space: normal;
    }

    span {
      display: block;
    }
  }
`;
const MortgageeName = styled.span`
  color: #000000;
`;
const DropdownAction = styled(MenuItem)`
  && {
    border-top: 1px solid #cccccc;
    padding: 0;

    a {
      text-decoration: underline;
      color: #5b6b13;
      padding: 15px;
    }
  }
`;
const SearchError = styled.div`
  padding: 15px;
  color: #842029;
`;
const FusionError = styled.div`
  background-color: #f8d7da;
  padding: 30px;
  color: #842029;

  p {
    margin: 0;
    padding: 0;
  }
`;

const MortgageeSearchFusion = ({
  value: mortgagee,
  disabled = false,
  onChange,
  onBlur,
  shouldFocus,
  size,
  fusionPosition // If there are multiple MortgageeSearch fusions in one form, this is how we know which one we are dealing with
}) => {
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const hasFocus = useContainsFocus({ ref: dropdownRef });
  const dispatch = useDispatch();

  const setMortgagee = nextMortgagee => {
    if (nextMortgagee) {
      setValue("");
      onChange(nextMortgagee);
    } else {
      onChange();
    }
  };

  const isLocked = !!mortgagee;

  const delayedFocus = useRef(shouldFocus);
  useEffect(() => {
    if (!isLocked && delayedFocus.current) {
      delayedFocus.current = false;
      inputRef.current.focus();
    }
  }, [isLocked]);

  const { isLoading, isError, data } = useQuery(
    ["mortgageeSearch", { query: value, limit: 200 }],
    {
      cacheTime: 0,
      staleTime: Infinity
    }
  );

  const onMortgageeSelect = id => {
    const selectedMortgagee = find(data?.mortgagees, ["Mortgagee0Index", id]);
    setMortgagee(selectedMortgagee);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const openAddMortgageeModal = () => {
    dispatch(
      externalResetChildForm({ id: QTC_FORM_ID, childId: "newMortgagee" })
    );
    dispatch(
      toggleMortgageeModal(true, {
        Position: fusionPosition,
        onlyAddMortgagee: true
      })
    );
  };

  const handleInputKeyDown = e => {
    const { key, metaKey } = e;
    if (isLocked && !disabled) {
      if (key === "Backspace") {
        e.preventDefault();
        setMortgagee(null);
      }
      return;
    }

    const highlightMax = 3;
    if (key === "PageDown" || (key === "ArrowDown" && metaKey)) {
      e.preventDefault();
      setHighlightIndex(highlightMax);
    } else if (key === "PageUp" || (key === "ArrowUp" && metaKey)) {
      e.preventDefault();
      setHighlightIndex(-1);
    } else if (key === "ArrowDown") {
      e.preventDefault();
      setHighlightIndex(
        highlightIndex >= highlightMax ? -1 : highlightIndex + 1
      );
    } else if (key === "ArrowUp") {
      e.preventDefault();
      setHighlightIndex(
        highlightIndex <= -1 ? highlightMax : highlightIndex - 1
      );
    } else if (key === "Escape") {
      document.body.focus();
    } else if (key === "Enter") {
      e.preventDefault();
      if (highlightIndex >= 0) {
        onMortgageeSelect(data?.mortgagees[highlightIndex].Mortgagee0Index);
      }
    }
  };

  // whether or not the search drop down should be open
  const isOpen = hasFocus && !isLocked;

  const debouncedChangeHandler = useMemo(() => debounce(setValue, 300), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <>
      <MortgageeSearchInput
        ref={dropdownRef}
        className={classnames(
          "MortgageeSearchInput",
          "dropdown",
          isOpen && "open"
        )}
      >
        <LoaderFormControl isLoading={isLoading}>
          <FormControl
            // set to "new-password" to stop browsers from using autocomplete.
            // see https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#the_autocomplete_attribute_and_login_fields
            autoComplete="new-password"
            disabled={disabled}
            readOnly={disabled || isLocked}
            bsSize={size === "default" ? undefined : size}
            placeholder="Search..."
            inputRef={r => {
              inputRef.current = r;
            }}
            onChange={({ target: { value: newValue } }) => {
              debouncedChangeHandler(newValue);
              if (newValue.length < SEARCH_THRESHOLD && highlightIndex !== 1) {
                setHighlightIndex(-1);
              }
            }}
            onBlur={onBlur}
            onKeyDown={handleInputKeyDown}
            title={isLocked ? value : undefined}
          />
        </LoaderFormControl>

        <DropdownMenu className="MortgageeSearchInput-dropdown">
          <DropdownHeader>
            {value !== "" && !!data?.mortgagees
              ? `${data.mortgagees.length} Result${
                  data.mortgagees.length !== 1 && "s"
                }`
              : `Start typing to begin searching...`}
          </DropdownHeader>
          {!isError &&
            !!data?.mortgagees?.length &&
            data.mortgagees.map(
              (
                {
                  Mortgagee0Index,
                  Mortgagee0Name = "",
                  Mortgagee0AddressLine1 = "",
                  Mortgagee0City = "",
                  Mortgagee0State = "",
                  Mortgagee0ZipCode = ""
                },
                index
              ) => (
                <DropdownSuggestion
                  active={highlightIndex === index}
                  eventKey={Mortgagee0Index}
                  key={Mortgagee0Index}
                  onSelect={onMortgageeSelect}
                  title={Mortgagee0Name}
                  className="MortgageeSearchInput-suggestion"
                >
                  <MortgageeName>{Mortgagee0Name}</MortgageeName>
                  <span>
                    {Mortgagee0AddressLine1} {Mortgagee0City}, {Mortgagee0State}{" "}
                    {Mortgagee0ZipCode}
                  </span>
                </DropdownSuggestion>
              )
            )}
          {isError && (
            <SearchError>
              <p>
                There was an error searching for mortgagees. You can try again,
                or use the link below to add a mortgagee.
              </p>
            </SearchError>
          )}
          <DropdownAction
            onSelect={openAddMortgageeModal}
            title="Add a mortgagee..."
          >
            Add a mortgagee...
          </DropdownAction>
        </DropdownMenu>
      </MortgageeSearchInput>
    </>
  );
};

MortgageeSearchFusion.propTypes = {
  value: PT.oneOfType([PT.string, PT.object]),
  disabled: PT.bool,
  onChange: PT.func.isRequired,
  shouldFocus: PT.bool,
  onBlur: PT.func,
  size: PT.oneOf(["sm", "small", "lg", "large", "default"]),
  fusionPosition: PT.number.isRequired
};

const StyledTextArea = styled(FormControl)`
  padding-right: 40px;

  .has-error && {
    margin-bottom: 5px;
  }
`;

const MortgageeDisplay = ({ value, onChange, onClear, size }) => {
  const inputRef = useRef(null);
  const display = value;

  return (
    <>
      <ClearFormControl
        onClear={() => {
          onChange();
          onClear();
        }}
      >
        <StyledTextArea
          componentClass="textarea"
          autoComplete="off"
          readOnly={true}
          bsSize={size === "default" ? undefined : size}
          inputRef={r => {
            inputRef.current = r;
          }}
          value={display}
          title={display}
        />
      </ClearFormControl>
    </>
  );
};

MortgageeDisplay.propTypes = {
  value: PT.oneOfType([PT.string, PT.object]),
  onChange: PT.func.isRequired,
  onClear: PT.func.isRequired,
  size: PT.oneOf(["sm", "small", "lg", "large", "default"])
};

export const MortgageeSearch = ({
  inputs,
  onChange,
  passthroughProps,
  groupLabel
}) => {
  const [shouldFocus, setShouldFocus] = useState(false);

  const isHidden = inputs.every(
    ({ type, visible = true }) => type === "hidden" || !visible
  );
  if (isHidden) {
    return null;
  }

  const mortgagee = inputs.reduce((acc, { fusionName, value }) => {
    if (fusionName) {
      acc[fusionName] = value;
    }
    return acc;
  }, {});

  const hasValue = chain(mortgagee)
    .pick(
      "company",
      "clause1",
      "loanNumber",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "zip"
    )
    .some()
    .value();

  const handleChange = nextMortgagee => {
    const nextFusionMortgagee = nextMortgagee
      ? mapObjectKeysWith(nextMortgagee, mortgageeApiKeyToFusionName)
      : {
          company: "",
          clause1: "",
          loanNumber: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: ""
        };
    onChange(nextFusionMortgagee);
  };

  const validation = inputs.reduce(
    (
      { required, errors },
      { isRequired, validationErrors = [], label, value }
    ) => ({
      required: isRequired ? [...required, label] : required,
      errors: value
        ? [
            ...errors,
            ...validationErrors.map(error => ({
              ...error,
              message: `${label}: ${error.message}`
            }))
          ]
        : errors
    }),
    { required: [], errors: [] }
  );

  /** Fusion Inputs Validation */
  const inputErrors = checkRequiredFusionInputs(REQUIRED_INPUTS, inputs);
  if (inputErrors.length > 0) {
    return (
      <FusionError>
        <p>
          Product Error: Mortgagee Search fusion group does not contain required
          inputs:
        </p>
        <ul>
          {inputErrors.map(missingFusionName => (
            <li key={missingFusionName}>{missingFusionName}</li>
          ))}
        </ul>
      </FusionError>
    );
  }

  return (
    <>
      <InputMeta
        className="QTCInput--mortgagee-search-fusion"
        ids={inputs.map(({ id }) => id).filter(i => i)}
        Control={
          hasValue && mortgagee ? MortgageeDisplay : MortgageeSearchFusion
        }
        size="large"
        label={find(inputs, { fusionName: "company" })?.label}
        groupLabel={groupLabel}
        touched={hasValue}
        isRequired={validation.required.length > 0}
        passthroughProps={passthroughProps}
        controlProps={{
          fusionPosition: parseInt(
            find(inputs, { fusionName: "position" })?.value
          ),
          onClear: () => setShouldFocus(true),
          shouldFocus
        }}
        onChange={handleChange}
        validationErrors={validation.errors}
        value={
          hasValue ? createMortgageeDisplayStringFromObject(mortgagee) : null
        }
      />
    </>
  );
};

MortgageeSearch.propTypes = {
  onChange: PT.func.isRequired,
  inputs: PT.array.isRequired,
  passthroughProps: PT.object,
  groupLabel: PT.string.isRequired
};

const joinWith = (seperator, ...args) =>
  args.filter(value => value).join(seperator);

const createMortgageeDisplayStringFromObject = ({
  company,
  addressLine1,
  addressLine2,
  city,
  state,
  zip
}) =>
  joinWith(
    ", ",
    company,
    addressLine1,
    addressLine2,
    city,
    joinWith(" ", state, zip)
  );

// takes an array of input names, and the inputs array
// returns a boolean of whether or not it meets the expected inputs
const checkRequiredFusionInputs = (expectedInputs, inputs) =>
  expectedInputs.filter(fusionName => !find(inputs, { fusionName }));
