import $ from "jquery";

import parse from "../utils/xml-parsers/identities";
import AP from "../legacy/global";

let cache = [];
let agencyLocationId = null;

const checkCache = alc => {
  return cache.length > 0 && agencyLocationId === alc;
};

const setCache = (alc, users) => {
  cache = users;
  agencyLocationId = alc;
};

const getCache = () => cache;

export default ({ alc, nocache = false }) => {
  const promise = new Promise((resolve, reject) => {
    if (nocache || !checkCache(alc)) {
      $.ajax({
        url: `${AP.config.services.ixdirectory.proxy}${
          AP.config.services.ixdirectory.baseUrl
        }identities/?AgentsOfLocation=${alc}&mode=extended&nocache=${new Date().getTime()}`,
        type: "GET",
        dataType: "xml",
        beforeSend: xhr => {
          const authString = AP.getAuthString();
          xhr.setRequestHeader("X-Crippled-Client", "yes");
          xhr.setRequestHeader("include-password", "true");
          xhr.setRequestHeader("Authorization", authString);
        }
      })
        .done(response => {
          const parsed = parse(response);
          setCache(alc, parsed);
          resolve(parsed);
        })
        .fail((xhr, status, error) => {
          reject(error);
        });
    } else {
      resolve(getCache());
    }
  });

  return promise;
};
