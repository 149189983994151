export const expireValue = (overedInSeconds = 30) => {
  const now = new Date(Date.now()).getTime();
  return now + 1000 * overedInSeconds; // return milliseconds
};

export const resetValue = (overideInDays = 20) => {
  const now = new Date(Date.now()).getTime();
  return now + 1000 * 60 * 60 * 24 * overideInDays; // return milliseconds
};

export const checkExpired = expires => {
  if (expires && Date.now() > expires) {
    return true;
  } else {
    return false;
  }
};
