import { createSelector } from "reselect";
import { includes } from "lodash";
import { hasFeature } from "./feature-flags";

const selectNow = () => Date.now();
const selectUsername = state => state.envInfo.username;
const filterPinned = (notices, closedIds, now) =>
  notices.filter(
    ({ id, pinned, highlight: { active, expiration } = {} }) =>
      pinned && active && expiration > now && !includes(closedIds, id)
  );
const selectCollection = state => state.notices.collection;
export const selectClosedNotices = state => state.notices.closedNotices;
export const selectClosedWhatsNew = state => state.notices.closedWhatsNew;
export const selectClosedNoticesByUser = createSelector(
  selectUsername,
  selectClosedNotices,
  (username, closedNotices) => closedNotices[username] || []
);
const selectClosedWhatsNewByUser = createSelector(
  selectUsername,
  selectClosedWhatsNew,
  (username, closedWhatsNew) => closedWhatsNew[username] || []
);
export const selectNotices = createSelector(selectCollection, collection =>
  collection.filter(({ whatsNewId }) => !whatsNewId)
);
export const selectActiveNotices = createSelector(
  selectNotices,
  selectNow,
  (notices, now) =>
    notices.filter(({ active, publish }) => active && publish <= now)
);
export const selectNewNotices = createSelector(
  selectActiveNotices,
  selectNow,
  (notices, now) => notices.filter(({ publish }) => publish > now - 604800000)
);
export const selectPinnedNotices = createSelector(
  selectActiveNotices,
  selectClosedNoticesByUser,
  selectNow,
  filterPinned
);
export const selectWhatsNew = createSelector(selectCollection, collection =>
  collection.filter(({ whatsNewId }) => whatsNewId)
);
export const selectActiveWhatsNew = createSelector(
  selectWhatsNew,
  s => s,
  selectNow,
  (notices, state, now) =>
    notices.filter(
      ({ active, publish, whatsNewId }) =>
        active &&
        (hasFeature(state, whatsNewId) ||
          hasFeature(state, "manage-whats-new")) &&
        publish <= now
    )
);
export const selectPinnedWhatsNew = createSelector(
  selectActiveWhatsNew,
  selectClosedWhatsNewByUser,
  selectNow,
  filterPinned
);
export const selectNoticesById = createSelector(
  selectCollection,
  (state, id) => id,
  (collection, id) => collection.find(notice => notice.id === parseInt(id, 10))
);
