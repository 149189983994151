import { HEADERS } from "policyViewDataConstants";
import addPolicyValues from "./add-policy-values";

const parseHeaderSections = data =>
  HEADERS.map(({ name, context }) => ({
    data: addPolicyValues(context, data),
    name
  }));

export default parseHeaderSections;
