import { useCallback, useEffect, useState } from "react";
import useFetch from "./use-fetch";
import deterministicString from "../utils/deterministic-string";

const usePost = ({
  cacheAndNetwork,
  cacheType,
  invalidatorTime,
  request,
  skipCall = true,
  using,
  willUpdate
}) => {
  const [state, setState] = useState({
    invalidatorTime,
    skipCall,
    using
  });

  const [fetchState] = useFetch({
    cacheAndNetwork,
    cacheType,
    invalidatorTime,
    request,
    skipCall,
    using,
    willUpdate,
    ...state
  });

  useEffect(() => {
    setState({
      invalidatorTime,
      skipCall,
      using
    });
  }, [invalidatorTime, request, skipCall, deterministicString(using)]);

  return [
    fetchState,
    useCallback(useVal => {
      setState({
        invalidatorTime: Date.now(),
        skipCall: false,
        using: useVal
      });
    }, []),
    state.using
  ];
};

usePost.STATUSES = useFetch.STATUSES;
usePost.CACHE_TYPES = useFetch.CACHE_TYPES;
usePost.STATE_RESET = useFetch.STATE_RESET;

export default usePost;
