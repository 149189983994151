import React from "react";
import PropTypes from "prop-types";

import useLocalStorage from "../../../hooks/use-local-storage";
import { track } from "../../../units/tracking";

import Thumbs from "./thumbs";
import Feedback from "./feedback";
import { checkExpired } from "./timing-utils";
import ExpireTimeOut from "./expire-timeout";
import "./user-feedback.scss";

const colors = {
  black: "#484848",
  white: "#fff",
  "sagesure-green": "#5a6c13"
};

const pickColor = color => colors[color] || colors.black;

const steps = ["vote", "voted", "feedback", "thanks", "done"];

const Vote = ({
  trackingKey,
  color = "black",
  thumbText,
  onThumbUp = () => {},
  onThumbDown = () => {},
  tellMoreMessage = "Thanks!",
  tellMoreLinkText = "Tell us more.",
  feedback,
  finalThanks = "Thank you for your feedback.",
  expireInSeconds = 30,
  resetInDays = 20
}) => {
  const [state, setState] = useLocalStorage(trackingKey, {
    step: steps[0]
  });

  if (checkExpired(state.expires) && state.step !== "thanks") {
    if (state.reset < Date.now()) {
      setState({ step: "vote" });
    } else {
      return null;
    }
  }

  // moves the step state forward or backward through the steps array
  const handleNextStep = (mixPanelProps = {}, changeToPreviousStep = false) => {
    const idx = steps.indexOf(state.step);
    let nextStep;
    if (changeToPreviousStep) {
      nextStep = idx === 0 ? steps.length - 1 : idx - 1;
    } else {
      nextStep = idx === steps.length - 1 ? 0 : idx + 1;
    }
    setState({ ...state, step: steps[nextStep], ...mixPanelProps });
  };

  const handleTellMore = () => {
    track(`Clicked Additional Feedback`, { trackingKey, vote: state.vote });
    handleNextStep();
  };

  switch (state.step) {
    case "vote":
      return (
        <Thumbs
          trackingKey={trackingKey}
          {...thumbText}
          color={pickColor(color)}
          onNextStep={handleNextStep}
          expireInSeconds={expireInSeconds}
          resetInDays={resetInDays}
          onThumbUp={onThumbUp}
          onThumbDown={onThumbDown}
        />
      );
    case "voted":
      return (
        <ExpireTimeOut expireInSeconds={expireInSeconds}>
          <div className="vote" styles={{ color }}>
            {tellMoreMessage} <a onClick={handleTellMore}>{tellMoreLinkText}</a>
          </div>
        </ExpireTimeOut>
      );

    case "feedback":
      return (
        <Feedback
          {...feedback}
          onNextStep={handleNextStep}
          vote={state.vote}
          trackingKey={trackingKey}
        />
      );
    case "thanks":
      return (
        <ExpireTimeOut
          expireInSeconds={expireInSeconds}
          onTimeout={handleNextStep}
        >
          <div className="vote" styles={{ color: pickColor(color) }}>
            {finalThanks}
          </div>
        </ExpireTimeOut>
      );
    default:
      return null;
  }
};

Vote.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["black", "white", "sagesure-green"]),
  thumbText: PropTypes.shape({
    question: PropTypes.string,
    showYesNoText: PropTypes.bool
  }),
  onThumbUp: PropTypes.func,
  onThumbDown: PropTypes.func,
  tellMoreMessage: PropTypes.string,
  tellMoreLinkText: PropTypes.string,
  feedback: PropTypes.shape({
    title: PropTypes.string,
    headingText: PropTypes.string,
    upText: PropTypes.string,
    downText: PropTypes.string,
    finalText: PropTypes.string,
    contactText: PropTypes.string
  }),
  finalThanks: PropTypes.string,
  expireInSeconds: PropTypes.number,
  resetInDays: PropTypes.number
};

export default Vote;
