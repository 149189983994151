import $ from "jquery";

export default (username, b64Val, programID, config) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.services.pop.proxy}${programID}/${
        config.services.pxserver.baseUrl
      }users/${username}?nocache=${new Date().getTime()}`,
      type: "GET",
      dataType: "xml",
      contentType: "xml",
      beforeSend: xhr => {
        xhr.setRequestHeader("X-Crippled-Client", "yes");
        xhr.setRequestHeader("include-password", "true");
        xhr.setRequestHeader("Authorization", `Basic ${b64Val}`);
        xhr.setRequestHeader("X-Authorization", `Basic ${b64Val}`);
        xhr.setRequestHeader("X-Method", "GET");
        xhr.setRequestHeader("X-Rest-Method", "GET");
      }
    })
      .done(data => {
        // Build pxServer jQuery object
        const pxServerUser = $(data);

        // role list from config, options will vary between SageSure, FNIC, etc
        const roleList = config.ipcm.values.loginWhitelist || [
          "CRU_AGENT_NY_HO",
          "PX_MANAGER"
        ];
        const rolesNode = pxServerUser.find("roles");
        const hasRole = roleList.some(
          role => rolesNode.find(`role[id="${role}"]`).length > 0
        );

        const rightsNode = pxServerUser.find("rights");
        const hasRight =
          rightsNode.find(
            `right[softwareapplication="agentportal"][organization="${programID}"][action="login"][access="grant"]`
          ).length > 0;

        // testing both in case there is an issue with the right being attached in the xml
        // or another role supporting login rights.  As long as one exists, we are good,
        // but if both are missing, the user does not have rights to login.
        if (hasRole || hasRight) {
          resolve(data);
          return;
        }
        reject("Missing permissions.");
      })
      .fail(() => {
        reject("Missing permissions.");
      });
  });
