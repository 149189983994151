import React, { useState, createContext, useContext, useRef } from "react";
import PT from "prop-types";
import styled from "styled-components";

import UserFeedback from "./toolkit/user-feedback";
import { Modal, Icon } from "@icg360/ui-toolkit";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 100%;
    max-width: 660px;
    height: 100%;
    margin: 0 auto;
    padding: 20px 0;
  }
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const StyledModalBody = styled(Modal.Body)`
  position: relative;
  flex: 1 1 auto;
  padding: 0;
  margin: 15px;
`;

const IframeHeader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 35px;
  display: flex;
  padding: 0 17px;
  border-bottom: 1px solid #000;
  background-color: #fff;
`;
const IframeTitle = styled.h3`
  margin: 0 auto;
  font-weight: 600;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  background: none;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: ${({ leaveSpaceForFeedback }) =>
    leaveSpaceForFeedback ? "calc(100% - 32px)" : "100%"};
  border: none;
  ${({ leaveSpaceForFeedback }) =>
    leaveSpaceForFeedback ? "border-bottom: 1px solid #000;" : ""};
`;

const ModalWithIframeContext = createContext({});

export const ModalWithIframeProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState();
  const [
    shouldShowUserFeedbackQuestion,
    setShouldShowUserFeedbackQuestion
  ] = useState(false);

  return (
    <ModalWithIframeContext.Provider
      value={{
        isOpen,
        setIsOpen,
        iframeUrl,
        setIframeUrl,
        title,
        setTitle,
        id,
        setId,
        shouldShowUserFeedbackQuestion,
        setShouldShowUserFeedbackQuestion
      }}
    >
      {children}
    </ModalWithIframeContext.Provider>
  );
};
ModalWithIframeProvider.propTypes = { children: PT.node.isRequired };

export const useModalWithIframe = () => {
  const {
    isOpen,
    setIsOpen,
    iframeUrl,
    setIframeUrl,
    title,
    setTitle,
    id,
    setId,
    shouldShowUserFeedbackQuestion,
    setShouldShowUserFeedbackQuestion
  } = useContext(ModalWithIframeContext);

  const openModal = ({ url, title, id, shouldShowUserFeedbackQuestion }) => {
    if (url) {
      !!title && setTitle(title);
      setIframeUrl(url);
      setIsOpen(true);
      if (shouldShowUserFeedbackQuestion) {
        if (id) {
          setId(id);
          setShouldShowUserFeedbackQuestion(shouldShowUserFeedbackQuestion);
        } else {
          console.error(
            "You tried to open ModalWithIframe with shouldShowUserFeedbackQuestion === true but without an Id. An Id is required for shouldShowUserFeedbackQuestion === true."
          );
        }
      }
    } else
      console.error(
        "You tried to open ModalWithIframe without a URL. Please provide a URL."
      );
  };
  const closeModal = () => {
    setTitle("");
    setIframeUrl("");
    setIsOpen(false);
  };

  return {
    isOpen,
    openModal,
    closeModal,
    iframeUrl,
    title,
    id,
    shouldShowUserFeedbackQuestion
  };
};

export const ModalWithIframe = () => {
  const {
    isOpen,
    closeModal,
    iframeUrl,
    title,
    id,
    shouldShowUserFeedbackQuestion
  } = useModalWithIframe();

  const iframeRef = useRef(null);
  const reLoadIframe = () =>
    (iframeRef.current.contentWindow.location = iframeUrl);

  return (
    <StyledModal
      ref={iframeRef}
      onHide={closeModal}
      show={isOpen}
      data-bdd="modal-with-iframe"
    >
      <StyledModalBody>
        <IframeHeader>
          <IconButton onClick={reLoadIframe}>
            <Icon name="arrow_back" />
          </IconButton>
          <IframeTitle>{title}</IframeTitle>
          <IconButton onClick={closeModal}>
            <Icon name="close" />
          </IconButton>
        </IframeHeader>
        {!!iframeUrl && (
          <Iframe
            ref={iframeRef}
            src={iframeUrl}
            name="modal-iframe"
            leaveSpaceForFeedback={shouldShowUserFeedbackQuestion}
          />
        )}
        {!!shouldShowUserFeedbackQuestion && (
          <UserFeedback trackingKey={`qtc_help-Icon_${id}`} />
        )}
      </StyledModalBody>
    </StyledModal>
  );
};
