import { includes } from "lodash";

import getPolicy from "../promises/get-policy";
import {
  getQuote,
  getAlternateQuotes,
  getProduct,
  getContextWithWrapper
} from "../promises/quotes";
import IAT_CARRIER_GROUP_IDS from "../static-data/iat-carrier-group-ids";
import {
  LOADED,
  LOADING,
  LOAD_ERROR,
  PRELOAD
} from "../static-data/loading-state-enum";
import * as singlePromiseCache from "../utils/single-promise-cache";
import switchProductFetch from "../promises/switch-product";
import eventHub from "../utils/event-hub";

import { pickEnvInfo, selectAll, selectAuthString } from "./selectors";
import {
  parseBilling,
  parseDocuments,
  parseHeaderSections,
  parseNotes,
  parsePolicyActivity
} from "./utils/policy-view";
import executeContext from "./utils/quote-view/execute-context";
import { flattenQuoteData } from "./utils/flatten-quote-data";
import { setQTCCompatibility } from "./quote-transaction-component";

const MAKE_REQUEST = "quote-view/MAKE_REQUEST";
const GET_RESPONSE = "quote-view/GET_RESPONSE";
const ERROR = "quote-view/ERROR";
const SEARCH_TEXT = "quote-view/SEARCH_TEXT";
const QUOTE_CHANGE = "quote-view/QUOTE_CHANGE";
const GET_ALTERNATES = "quote-view/GET_ALTERNATES";
const GET_PRODUCTS = "quote-view/GET_PRODUCTS";
const COPY_QUOTE_MODAL = "quote-view/SHOW_COPY_QUOTE_MODAL";
const POLICYHOLDER_INFO_CHANGE = "policy-view/POLICYHOLDER_INFO_CHANGE";
const MORTGAGEE_CHANGE = "policy-view/MORTGAGEE_CHANGE";

export const makeRequest = (quoteNumber, isPolicy) => ({
  type: MAKE_REQUEST,
  quoteNumber,
  isPolicy
});

export const getResponse = ({ quoteNumber, isPolicy, data }) => ({
  type: GET_RESPONSE,
  quoteNumber,
  isPolicy,
  data
});

const setError = error => ({
  type: ERROR,
  error
});

// this function makes a request to get quote data and merges it with another
// request that returns the context for the specific product
export const loadQuote = quoteNumber => async dispatch => {
  try {
    dispatch(makeRequest(quoteNumber, false));
    const product = await getProduct({ id: quoteNumber }).catch(e => {
      console.error(e);
      return {};
    });

    // Store QTC Compatibility
    dispatch(setQTCCompatibility(product.qtcCompatibility));
    // We can't show BOP in QV yet
    if (
      product.qtcCompatibility === "full" &&
      product.productType.toLowerCase() === "bop"
    ) {
      eventHub.trigger("redirect", {
        type: "replace",
        location: `/quote/${quoteNumber}/edit`
      });
      return;
    }
    const [quote, context] = await Promise.all([
      getQuote({ id: quoteNumber }),
      getContextWithWrapper({ id: quoteNumber })
    ]);
    if (quote.policyId) {
      eventHub.trigger("redirect", {
        type: "replace",
        location: `/policy/${quoteNumber}`
      });
      return;
    }
    dispatch(
      getResponse({
        quoteNumber,
        isPolicy: false,
        data: {
          ...quote,
          product,
          documents: quote?.carriers?.[0]?.documents || [],
          insuredName: quote.inputs.InsuredName,
          sections: executeContext(context, flattenQuoteData(quote))
        }
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(setError(error));
  }
};

export const loadPolicy = quoteNumber => async (dispatch, getState) => {
  const [{ programID, username: currentUsername }, auth] = selectAll(
    getState(),
    [pickEnvInfo, selectAuthString]
  );
  dispatch(makeRequest(quoteNumber, true));
  const getPolicyUsing = {
    id: quoteNumber,
    programID,
    auth
  };
  const getPolicyPromise = getPolicy(getPolicyUsing);
  singlePromiseCache.store({
    promise: getPolicyPromise,
    request: getPolicy,
    using: getPolicyUsing
  });

  return getPolicyPromise
    .then(policy => {
      return Promise.all([
        policy,
        getContextWithWrapper({ id: quoteNumber }),
        getProduct({ policyId: quoteNumber }).catch(error => {
          console.error(error);
          return {};
        })
      ]);
    })
    .then(([policy, context, product]) => {
      const { CarrierGroupID } = policy.policyData;
      const isIAT = includes(IAT_CARRIER_GROUP_IDS, CarrierGroupID);

      dispatch(
        getResponse({
          quoteNumber,
          isPolicy: true,
          data: {
            quoteId: quoteNumber,
            policyId: policy.identifiers.PolicyID,
            ...policy,
            product,
            insuredName: policy.policyData.InsuredName,
            productType: policy.policyData.PolicyType,
            carrier: policy.management.CarrierId,
            effectiveDate: policy.policyData.EffectiveDate,
            sections: executeContext(
              context,
              flattenQuoteData({
                effectiveDate: policy.policyData.EffectiveDate,
                inputs: policy.policyData
              })
            ),
            billing: parseBilling(policy),
            notes: parseNotes(policy.relatedItems, currentUsername),
            documents: parseDocuments(policy.documents),
            headerSections: parseHeaderSections(policy),
            policyActivity: parsePolicyActivity(
              policy.eventHistory,
              policy.revisionHistory
            ),
            claimsInfo: {
              phone: policy.policyData.CarrierClaimPhoneNumber,
              carrierName: policy.policyData.CarrierName,
              carrierGroupId: policy.policyData.CarrierGroupID,
              carrierId: policy.policyData.CarrierID,
              isIAT
            }
          }
        })
      );
    })
    .catch(error => {
      console.error(error);
      dispatch(setError(error));
    });
};

export const refreshPolicy = () => (dispatch, getState) => {
  const { quoteNumber } = getState().quoteView;
  dispatch(loadPolicy(quoteNumber));
};

export const loadAlternates = quoteNumber => dispatch => {
  dispatch(makeRequest(quoteNumber, false));
  return getAlternateQuotes({ id: quoteNumber })
    .then(alternateQuotes => {
      dispatch(getAlternates(alternateQuotes));
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error));
    });
};

export const switchProduct =
  (quoteId, targetProductId, shouldCreateNewQuote) => dispatch => {
    dispatch(makeRequest(quoteId));
    return switchProductFetch({
      quoteId,
      targetProductId,
      shouldCreateNewQuote
    })
      .then(result => {
        eventHub.trigger("edit-quote", result.quoteId);
        // dispatch(loadAlternates(quoteId));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(setError(error));
      });
  };

export const setSearchText = searchText => ({
  type: SEARCH_TEXT,
  searchText
});

export const showPolicyholderInfoChange = (show = true) => ({
  type: POLICYHOLDER_INFO_CHANGE,
  show
});

export const showMortgageeChange = (show = true) => ({
  type: MORTGAGEE_CHANGE,
  show
});

export const showQuoteChange = (show = true) => ({
  type: QUOTE_CHANGE,
  show
});

export const getAlternates = alternates => ({
  type: GET_ALTERNATES,
  alternates
});

export const getProducts = products => ({
  type: GET_PRODUCTS,
  products
});

export const setShowCopyQuoteModal = showCopyQuoteModal => ({
  type: COPY_QUOTE_MODAL,
  showCopyQuoteModal
});

export const quoteView = (
  state = {
    alternates: {},
    quoteNumber: null,
    isPolicy: false,
    loadingState: PRELOAD,
    data: null,
    error: undefined,
    searchText: "",
    searchResults: [],
    showPolicyholderInfoChangeModal: false,
    showMortgageeChangeModal: false,
    showQuoteChangeModal: false,
    showCopyQuoteModal: false,
    products: null
  },
  data
) => {
  switch (data.type) {
    case MAKE_REQUEST: {
      return {
        ...state,
        loadingState: LOADING,
        quoteNumber: data.quoteNumber,
        isPolicy: data.isPolicy,
        alternates: {},
        data:
          state.quoteNumber === data.quoteNumber &&
          state.isPolicy === data.isPolicy
            ? state.data
            : null,
        searchText: ""
      };
    }
    case GET_RESPONSE: {
      return state.quoteNumber !== data.quoteNumber ||
        state.isPolicy !== data.isPolicy
        ? state
        : {
            ...state,
            data: data.data,
            loadingState: LOADED,
            error: undefined
          };
    }
    case ERROR: {
      return {
        ...state,
        error: data.error,
        loadingState: LOAD_ERROR,
        data: null
      };
    }
    case SEARCH_TEXT: {
      return {
        ...state,
        searchText: data.searchText
      };
    }
    case POLICYHOLDER_INFO_CHANGE: {
      return {
        ...state,
        showPolicyholderInfoChangeModal: data.show
      };
    }
    case MORTGAGEE_CHANGE: {
      return {
        ...state,
        showMortgageeChangeModal: data.show
      };
    }

    case QUOTE_CHANGE: {
      return {
        ...state,
        showQuoteChangeModal: data.show
      };
    }

    case GET_ALTERNATES: {
      return {
        ...state,
        alternates: data.alternates,
        loadingState: LOADED
      };
    }

    case GET_PRODUCTS: {
      return {
        ...state,
        products: data.products,
        loadingState: LOADED
      };
    }

    case COPY_QUOTE_MODAL: {
      return {
        ...state,
        showCopyQuoteModal: data.showCopyQuoteModal
      };
    }
    default:
      return state;
  }
};
