import parse from "../utils/xml-parsers/policyevents";
import AP from "../legacy/global";

export default async ({ programID, alc, pageParam }) => {
  const authString = AP.getAuthString();
  const url = `${AP.config.services.pop.proxy}${programID}/${AP.config.services.pxcentral.baseUrl}policyevents?RepresentingAgencyLocation=${alc}&perPage=500&page=${pageParam}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: authString,
      "X-Authorization": authString,
      "X-Crippled-Client": "yes",
      "include-password": "true"
    }
  });
  const body = await response.text();
  const json = await parse(body);

  if (!response.ok) {
    throw response.statusText;
  } else {
    return json;
  }
};
