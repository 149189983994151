import { trim, unescape } from "lodash";
import { printDate } from "../../../utils/dates";

const EMAIL_PREFIX = "Email Successfully Sent:";
const EMAIL_SECTIONS = [
  { name: "to", prefix: "To:" },
  { name: "attachments", prefix: "Email Attachments:" },
  { name: "subject", prefix: "Subject:" },
  { name: "body", prefix: "Body:" }
];

const formatList = (items = "", sep = ", ") =>
  items.replace(/\s+/g, match => (match === " " ? " " : sep));

const formatAttachments = attachments =>
  `Email Attachments:<br/>${formatList(attachments, "<br/>")}`;

export const parseNoteEmail = content => {
  const emailStart = content.indexOf(EMAIL_PREFIX);
  if (emailStart === -1) {
    return {
      body: trim(content)
    };
  }
  const email = content.substr(emailStart + EMAIL_PREFIX.length);
  const sectionInfo = EMAIL_SECTIONS.map(section => ({
    ...section,
    index: email.indexOf(section.prefix)
  })).sort((a, b) => a.index - b.index);
  return sectionInfo.reduce(
    (acc, { name, prefix, index }, i) => ({
      ...acc,
      [name]: trim(
        email.substring(
          index + prefix.length,
          i < sectionInfo.length - 1 ? sectionInfo[i + 1].index : undefined
        )
      )
    }),
    {}
  );
};

const parseNotes = ({ Notes, Attachments }, currentUsername) =>
  Notes.filter(
    ({ Content, CreatedBy }) => Content && CreatedBy === currentUsername
  ).map(({ id, Content, attachmentIds, CreatedBy, CreatedTimeStamp }) => {
    const { to, subject, body, attachments } = parseNoteEmail(Content);
    const isBodyHtml = body.includes("&lt;/");
    const htmlBody = isBodyHtml ? unescape(body) : body.replace(/\n/g, "<br/>");
    const htmlAttachments = isBodyHtml
      ? `<p>${formatAttachments(attachments)}</p>`
      : `<br/><br />${formatAttachments(attachments)}`;
    return {
      id,
      createdBy: CreatedBy,
      timestamp: printDate(CreatedTimeStamp, "MM/DD/YYYY [at] hh:mma"),
      content: attachments ? `${htmlBody}${htmlAttachments}` : htmlBody,
      subject,
      to: formatList(to),
      attachments: attachmentIds
        ? attachmentIds.map(id =>
            Attachments.find(attachment => id === attachment.id)
          )
        : []
    };
  });

export default parseNotes;
