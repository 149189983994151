import PT from "prop-types";
import { compact, first } from "lodash";

export const ADDRESS_VALIDATION = {
  UNVALIDATED: "UNVALIDATED",
  PARTIALLY_CITY: "PARTIALLY_CITY",
  PARTIALLY_STATE: "PARTIALLY_STATE",
  PARTIALLY_STREET: "PARTIALLY_STREET",
  VALIDATED: "VALIDATED",
  CUSTOM_CONFIRMED: "CUSTOM_CONFIRMED"
};

export const ADDRESS_SHAPE = {
  validation: PT.oneOf(Object.values(ADDRESS_VALIDATION)),
  display: PT.string.isRequired,
  state: PT.string,
  streetNumber: PT.string,
  route: PT.string,
  subpremise: PT.string,
  city: PT.string,
  county: PT.string,
  postalCode: PT.string,
  postalCodeSuffix: PT.string,
  lat: PT.string,
  lng: PT.string,
  isEstablishment: PT.bool,
  placeName: PT.string
};

export const singleLineAddress = ({
  streetNumber = "",
  route = "",
  subpremise = "",
  city = "",
  state = "",
  postalCode = ""
}) =>
  compact([
    compact([streetNumber.trim(), route.trim(), subpremise]).join(" "),
    city,
    compact([state, postalCode]).join(" "),
    "USA"
  ]).join(", ");

const getValidationStatus = ({ geometry: { location_type }, types }) => {
  if (
    location_type === "ROOFTOP" ||
    types.includes("premise") ||
    types.includes("subpremise") ||
    types.includes("street_address") ||
    types.includes("establishment")
  ) {
    return ADDRESS_VALIDATION.VALIDATED;
  } else if (types.includes("route")) {
    return ADDRESS_VALIDATION.PARTIALLY_STREET;
  } else if (types.includes("locality")) {
    return ADDRESS_VALIDATION.PARTIALLY_CITY;
  } else if (types.includes("administrative_area_level_1")) {
    return ADDRESS_VALIDATION.PARTIALLY_STATE;
  } else {
    return ADDRESS_VALIDATION.UNVALIDATED;
  }
};

// Return a function that can find the geocode address component by name
// That function returns the long name and short name in an array
export const geocodeAddressComponents = components => {
  const lookupByName = components.reduce(
    (acc, { long_name, short_name, types }) =>
      types.reduce((acc2, type) => {
        if (!acc2[type]) {
          acc2[type] = [long_name, short_name];
        }
        return acc2;
      }, acc),
    {}
  );
  return name => lookupByName[name] || ["", ""];
};

const chooseCity = (display, ...possibleValues) => {
  const values = compact(possibleValues).filter(value =>
    isNaN(parseInt(value))
  );

  return (
    values.find(value => display.toLowerCase().includes(value.toLowerCase())) ||
    first(values)
  );
};

export const mapGeocodeToAddress = ({
  address_components: components,
  formatted_address: display,
  geometry,
  types = [],
  name,
  place_id
}) => {
  const getValue = geocodeAddressComponents(components);
  return {
    display,
    placeId: place_id,
    state: getValue("administrative_area_level_1")[1],
    streetNumber: getValue("street_number")[0],
    route: getValue("route")[1],
    subpremise: getValue("subpremise")[0],
    city: chooseCity(
      display,
      ...getValue("locality"),
      ...getValue("administrative_area_level_3"),
      ...getValue("sublocality"),
      ...getValue("neighborhood"),
      ...getValue("sublocality_level_1")
    ),
    county: getValue("administrative_area_level_2")[0],
    postalCode: getValue("postal_code")[0],
    postalCodeSuffix: getValue("postal_code_suffix")[0],
    locationType: geometry.location_type,
    lat: geometry.location.lat(),
    lng: geometry.location.lng(),
    placeName: types.includes("establishment") ? name : undefined,
    validation: getValidationStatus({ geometry, types })
  };
};
