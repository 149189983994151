import { rexReducer as rex } from "@icg360/rex";
import { combineReducers } from "redux";
import { paymentPlan } from "../store/payment-plan";
import { adminMode } from "./admin-mode";
import { contacts } from "./contacts";
import { envInfo } from "./env-info";
import { esign } from "./esign";
import { esignPreBind } from "./esign-prebind";
import { featureFlags } from "./feature-flags";
import { messages } from "./messages";
import { notices } from "./notices";
import { quoteGrid } from "./quote-grid";
import { quoteQuickStart } from "./quote-quick-start";
import { quoteView } from "./quote-view";
import { quoteTransactionComponent } from "./quote-transaction-component";
import { recentActivity } from "./recent-activity";
import { transactions } from "./transactions";
import { users } from "./users";
import { ipm } from "./ipm";

export default combineReducers({
  adminMode,
  contacts,
  envInfo,
  esign,
  esignPreBind,
  featureFlags,
  ipm,
  messages,
  notices,
  paymentPlan,
  quoteGrid,
  quoteQuickStart,
  quoteView,
  quoteTransactionComponent,
  recentActivity,
  rex,
  transactions,
  users
});
