import AP from "../legacy/global";

const getContacts = async () => {
  const authString = AP.getAuthString();
  const programID = ENV_INFO.programID,
    programConfigID = ENV_INFO.programConfigID;
  const url = `${AP.config.services.pop.proxy}${programID}/${AP.config.services.pxprogram2.baseUrl}programs/${programConfigID}/contacts`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: authString,
      "X-Authorization": authString,
      "include-password": "true"
    }
  });

  const parsed = await response.json();

  if (!response.ok) {
    throw response.statusText;
  } else {
    return parsed;
  }
};

export default getContacts;
