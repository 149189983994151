import $ from "jquery";

import AP from "../legacy/global";

const cache = {
  timestamp: null,
  data: null
};

export default data =>
  new Promise((resolve, reject) => {
    if (!cache.timestamp) {
      $.ajax({
        url: `${AP.config.services.ixvocab.baseUrl}${AP.config.services.ixvocab.apiUrl}contexts/OFCCPoliciesView/terms`,
        type: "GET",
        beforeSend: xhr => {
          xhr.setRequestHeader(
            "Authorization",
            `Basic ${AP.config.usePxClientAuthForIxServices.authHash}`
          );
        },
        contentType: "application/json",
        data,
        dataType: "json"
      })
        .done(response => {
          cache.timestamp = new Date();
          cache.data = response;
          resolve(cache.data);
        })
        .fail((xhr, status, error) => {
          reject(error);
        });
    } else {
      resolve(cache.data);
    }
  });

// For testing purposes
export const reset = () => {
  cache.timestamp = null;
  cache.data = null;
};
