import $ from "jquery";

import AP from "../legacy/global";
import htmlError from "../utils/xml-parsers/html-error";
import parsePolicy from "../utils/xml-parsers/policy-parser";

// TODO rewerite getPolicy using fetch (instead of jquery)
const getPolicy = ({ id, programID, auth }) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${AP.config.services.shared.url}${programID}/pxcentral/policies/${id}`,
      type: "GET",
      beforeSend: xhr => {
        xhr.setRequestHeader("X-Authorization", auth);
        xhr.setRequestHeader("Cache-Control", "no-cache");
      },
      accept: "application/xhtml+xml",
      contentType: "application/xhtml+xml",
      dataType: "text"
    })
      .done(xml => {
        try {
          resolve(parsePolicy(xml));
        } catch (ex) {
          reject(ex);
        }
      })
      .fail((xhr, status, error) => {
        reject(htmlError(xhr.responseText) || { error });
      });
  });

export default getPolicy;
