import React, { useState } from "react";
import PropTypes from "prop-types";
import { unescape, kebabCase } from "lodash";
import { useSelector } from "react-redux";
import { ControlLabel, FormGroup, HelpBlock, Icon } from "@icg360/ui-toolkit";
import { Element } from "react-scroll";

import { selectRulesets } from "../../../store/selectors/qtc-selectors";
import { hasFeature } from "../../../store/selectors";
import { useModalWithIframe } from "../../modal-with-iframe";
import SearchableText from "../../search-bar/searchable-text";
import SafeHtml from "../../toolkit/safe-html";
import { track } from "../../../units/tracking";
import { QTC_TRACK } from "../constants";
import { useQuoteEditForm } from "../utils/qtc-provider";

import { ACHForm } from "./ach-form";
import { CCForm } from "./cc-form";
import { CheckForm } from "./check-form";

export const LAYOUT_TYPES = {
  DEFAULT: "DEFAULT",
  FULL: "FULL"
};

export const InputMeta = ({
  appearance,
  className = "",
  controlProps = {},
  Control,
  details = [],
  end,
  groupLabel,
  help,
  id: optionalId,
  ids = null,
  indent = 0,
  isReadOnly,
  isRequired,
  label,
  layoutType = LAYOUT_TYPES.DEFAULT,
  validationErrors = [],
  mediatype,
  onBlur,
  onChange,
  passthroughProps,
  start,
  type,
  value
}) => {
  const { setActiveQuestionId } = useQuoteEditForm();
  const id = optionalId || kebabCase(label);

  const [isHelpExpanded, setIsHelpExpanded] = useState(false);
  const rulesets = useSelector(selectRulesets);
  const { openModal } = useModalWithIframe();

  const toggleHelpExpand = () => setIsHelpExpanded(!isHelpExpanded);

  const handleActivity = () => {
    if (passthroughProps.saveQuote) {
      passthroughProps.saveQuote.cancel();
    }
  };

  const handleChange = (...args) => {
    if (passthroughProps.saveQuote) {
      // Cancel the save since the `onChange` call take 1-2 seconds.
      passthroughProps.saveQuote.cancel();
      onChange(...args);
      passthroughProps.saveQuote();
    } else {
      onChange(...args);
    }
  };

  const isPaymentTypeCheck =
    id === "PaymentMethod" &&
    details.find(({ value }) => value === "200")?.label === "Check";

  let displayVal = value;
  if (type === "enum") {
    const enm = details.find(detail => detail.value === value);
    displayVal = enm ? enm.label : displayVal;
  }

  let validationState;
  if (value && validationErrors.length > 0) {
    validationState = "error";
  } else if (rulesets.length > 0) {
    const rulesetMessages = [];

    rulesets.forEach(({ msg, outcome, highlightControls }) => {
      if (
        highlightControls &&
        (ids
          ? ids.some(i => highlightControls.includes(i))
          : highlightControls.includes(id))
      ) {
        rulesetMessages.push(msg);
        if (outcome === "prevent") {
          validationState = "error";
          // prevent state supercedes inform state
        } else if (outcome === "inform" && validationState !== "error") {
          validationState = "warning";
        }
      }
    });
  }

  // Filling empty elements with non-breaking space maintains baseline alignment
  let helpfulTip = "\xa0";
  const idsWithIframes = {
    RoofGeometryType: {
      url: "https://rct7.msbexpress.net/RCT41Help/1/Content/Roof_Style.htm",
      linkOut:
        "https://rct7.msbexpress.net/RCT41Help/1/Default.htm#Roof_Style.htm"
    },
    HomeStyle: {
      url: "https://rct7.msbexpress.net/RCT41Help/1/Content/Home_Styles.htm",
      linkOut:
        "https://rct7.msbexpress.net/RCT41Help/1/Default.htm#home_styles.htm"
    }
  };
  const shouldShowIframe = useSelector(state =>
    hasFeature(state, "qtc-show-iframed-help-content")
  );
  const iframeInfo = shouldShowIframe && idsWithIframes[id]; // TODO: update to use fusion once product adds fusion
  if (iframeInfo) {
    helpfulTip = (
      <button
        data-bdd="help-icon"
        onClick={() => {
          openModal({
            url: iframeInfo.url,
            title: (
              <>
                Help content courtesy of{" "}
                <a
                  href={iframeInfo.linkOut}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RCT
                </a>
              </>
            ),
            id, // TODO: update to use fusion once product adds fusion
            shouldShowUserFeedbackQuestion: true
          });
          track(QTC_TRACK.QUOTE_FORM.HELP_ICON_CLICKED, {
            question: id, // TODO: update to use fusion primarily, then id if no fusion.
            showIframe: true
          });
        }}
        className="help-icon"
        tabIndex="-1"
      >
        <Icon name="help_outline" size="sm" />
      </button>
    );
  } else if (help) {
    helpfulTip = (
      <button
        data-bdd="help-icon"
        onClick={() => {
          toggleHelpExpand();
          track(QTC_TRACK.QUOTE_FORM.HELP_ICON_CLICKED, {
            question: id, // TODO: update to use fusion primarily, then id if no fusion.
            showIframe: false,
            isHelpExpanded
          });
        }}
        className="help-icon"
        tabIndex="-1"
      >
        <Icon name={isHelpExpanded ? "help" : "help_outline"} size="sm" />
      </button>
    );
  }

  return (
    <Element
      data-ids={` ${ids ? ids.join(" ") : id} `}
      id={`${id}-field`}
      name={id}
      className={"QTCInput " + className}
      data-indent={indent}
      onClick={() => setActiveQuestionId(id)}
    >
      <FormGroup controlId={id} validationState={validationState}>
        <div className="content">
          {isRequired && <span className="requiredAsterisk">*</span>}
          {layoutType !== LAYOUT_TYPES.FULL && (
            <ControlLabel
              className="inputLabel"
              data-bdd={`QTC ${label} Label`}
            >
              <SearchableText
                text={label}
                searchText="Location"
                fieldLabel={label}
                fieldValue={
                  type === "currency"
                    ? "$" + Number(displayVal).toLocaleString()
                    : displayVal
                }
                section={groupLabel}
              />
            </ControlLabel>
          )}
          <div className="controlContainer" data-bdd={`QTC ${label} Input`}>
            <Control
              appearance={appearance}
              details={details}
              disabled={isReadOnly}
              end={end}
              id={id}
              label={label}
              mediatype={mediatype}
              onActivity={handleActivity}
              onBlur={onBlur}
              onChange={handleChange}
              type={type}
              start={start}
              value={value}
              {...controlProps}
            />
          </div>
          <div className="supportIcon">{helpfulTip}</div>
        </div>
        {validationState === "error" && (
          <div className="error-area">
            <div className="inputLabel"></div>
            <div className="controlContainer">
              {validationErrors.map(error => (
                <HelpBlock key={error.message}>
                  {createErrorMessage(error, type)}
                </HelpBlock>
              ))}
            </div>
            <div className="supportIcon"></div>
          </div>
        )}
        {id === "PaymentMethod" &&
          value === "200" &&
          (isPaymentTypeCheck ? (
            <CheckForm />
          ) : (
            <div className="help-area">
              {ENV_INFO.organizationID === "fnic"
                ? "The insurance company"
                : "Sagesure"}{" "}
              will invoice your primary mortgagee.
            </div>
          ))}
        {id === "PaymentMethod" && value === "300" && <ACHForm />}
        {id === "PaymentMethod" && value === "400" && <CCForm />}
      </FormGroup>
      {help && isHelpExpanded && (
        <SafeHtml className="help-area" html={unescape(help)} />
      )}
    </Element>
  );
};
InputMeta.LAYOUT_TYPES = LAYOUT_TYPES;

InputMeta.propTypes = {
  appearance: PropTypes.string,
  className: PropTypes.string,
  Control: PropTypes.func,
  controlProps: PropTypes.object,
  details: PropTypes.array,
  end: PropTypes.string,
  groupLabel: PropTypes.string,
  help: PropTypes.string,
  id: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.string),
  indent: PropTypes.number,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  layoutType: PropTypes.oneOf(Object.values(LAYOUT_TYPES)),
  mediatype: PropTypes.string,
  note: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  passthroughProps: PropTypes.shape({
    saveQuote: PropTypes.func
  }),
  start: PropTypes.string,
  touched: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool,
  validationErrors: PropTypes.arrayOf(
    PropTypes.shape({ message: PropTypes.string })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export const InputMetaWrapper = (Control, overwriteProps = {}) => {
  const Renderer = props => (
    <InputMeta {...props} {...overwriteProps} Control={Control} />
  );
  Object.defineProperty(Renderer, "name", {
    value: `${Control.name}(InputMetaRenderer)`
  });
  return Renderer;
};

function createErrorMessage({ message, type, val, customMessage }, inputType) {
  if (customMessage) return customMessage;
  if (inputType === "currency") {
    if (type === "maxvalue") {
      return `Maximum amount is $${Number(val).toLocaleString()}`;
    }
    if (type === "minvalue") {
      return `Minimum amount is $${Number(val).toLocaleString()}`;
    }
  }
  if (type === "maxvalue") {
    return `Maximum value is ${val}`;
  }
  if (type === "minvalue") {
    return `Minimum value is ${val}`;
  }
  return message;
}
