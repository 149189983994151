import AP from "../legacy/global";

const TOGGLE = "admin-mode/TOGGLE";

export const toggleAdminMode = () => ({
  type: TOGGLE
});

export const adminMode = (state = false, { type }) => {
  if (type === TOGGLE) {
    const inverse = !state;
    AP.adminModeActive = inverse;
    return inverse;
  }
  return state;
};
