import moment from "moment";

export const getISOToday = () => new Date().toISOString().substr(0, 10);
export const getISOTimestamp = () => new Date().toISOString();

const tryParse = (date, format, isUTC) => {
  const m = isUTC ? moment.utc(date, format).local() : moment(date, format);
  return m.isValid() ? m : null;
};

const isISO = /^\d\d\d\d-\d\d-\d\d/;
const startsWithMonth = /^[^\d]*[a-z]{3}/i;
const includesMonth = /[a-z]{3}/i;
export const parseDate = date => {
  if (typeof date === "number" || typeof date === "object") {
    return moment(date);
  }
  if (isISO.test(date)) {
    // If the ISO Date starts at the beginning of the day (00:00:00)
    // this is a good indication that time doesn't matter
    // just display the date as is by stripping off the time.
    const isoDate = date.includes("00:00:00") ? date.substring(0, 10) : date;
    return moment(isoDate);
  }
  return [
    d =>
      startsWithMonth.test(d)
        ? tryParse(d, "MMMM, D YYYY HH:mm:ss", true)
        : null,
    d => (includesMonth.test(d) ? tryParse(d, "DD MMMM YYYY") : null),
    d => tryParse(d, "MM/DD/YYYY"),
    moment
  ].reduce((acc, parser) => acc || parser(date), null);
};
export const printDate = (date, format = "MM/DD/YYYY") =>
  parseDate(date).format(format);

/* returns if day is within the floor and ceiling date range
 * @param day {Moment}
 * @param floor {Moment}
 * @param ceiling {Moment}
 * @returns { boolean }
 */
export const dayIsSelectable = (day, floor, ceiling) => {
  const beforeFloor = day.startOf("day").isBefore(floor);
  const afterCeiling = day.startOf("day").isAfter(ceiling);

  return !(beforeFloor || afterCeiling);
};

/*
 * returns effectivedateerror string
 * @param {moment date string} startDate
 * @param {moment date string} endDate
 * @returns {string}
 */
export const effectiveDateError = (startDate, endDate) => {
  const start = tryParse(startDate);
  const end = tryParse(endDate);

  const ERROR_INVALID_DATE =
    "Please choose a valid effective date (MM/DD/YYYY).";
  const ERROR_DATE_RANGE = `Please select a date between today and ${printDate(
    endDate
  )}.`;

  const today = moment.utc();

  if (!start || !end) return ERROR_INVALID_DATE;

  if (!dayIsSelectable(start, today.startOf("day"), end))
    return ERROR_DATE_RANGE;
  return null;
};
