export default function setupGoogleMapAPI(key) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(reject, 5000);
    window.APmapsCallback = () => {
      clearTimeout(timer);
      resolve();
    };
    var script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&sensor=false&libraries=places&callback=APmapsCallback`;
    script.id = "googleMapsPlaces";
    document.head.appendChild(script);
  });
}
