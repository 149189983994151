import $ from "jquery";

import AP from "../legacy/global";
import { wrapRequest } from "../utils/single-promise-cache";
import executeContext from "../store/utils/quote-view/execute-context";
import { flattenQuoteData } from "../store/utils/flatten-quote-data";

const fetchQuotes = async (
  path,
  { headers, params, contentTypeHeader = "application/json", ...options } = {}
) => {
  const { url, baseUrl } = AP.config.services.quotesapi;
  const fullUrl = `${url}${baseUrl}${path}?${$.param({
    // Can't figure out why IE11 doesn't respect the Cache-Control header
    nocache: Date.now(),
    ...params
  })}`;

  const response = await fetch(fullUrl, {
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": contentTypeHeader,
      "Cache-Control": "no-cache"
    },
    credentials: "include",
    ...options
  });
  const { headers: resHeaders, statusText, ok } = response;
  const contentType = resHeaders.get("content-type");
  if (!contentType || !contentType.includes("application/json")) {
    throw new Error(statusText || "Network request failed.");
  }
  const result = await response.json();

  if (!ok) throw new Error(result.message || statusText);
  return result;
};

const quotes = async ({
  id,
  data,
  method = "GET",
  headers = {},
  contentTypeHeader
}) => {
  const endpoint = id ? `/quotes/${id}` : `/quotes`;
  const opts = {
    method
  };

  opts.headers = headers;

  if (contentTypeHeader) {
    opts.contentTypeHeader = contentTypeHeader;
  }

  if (method === "POST" || method === "PUT" || method === "PATCH") {
    if (contentTypeHeader === "application/x-www-form-urlencoded") {
      opts.body = Object.keys(data)
        .map(key => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        })
        .join("&");
    } else {
      opts.body = JSON.stringify(data);
    }
  }
  return fetchQuotes(endpoint, opts);
};

export const getQuote = quotes;
export const postQuote = (using = {}) => quotes({ method: "POST", ...using });
export const putQuote = (using = {}) => quotes({ method: "PUT", ...using });
export const patchQuote = (using = {}) => quotes({ method: "PATCH", ...using });

export const getPriceTable = ({ id, params = {} }) =>
  fetchQuotes(`/quotes/${id}/price-list`, { params });
export const patchPriceTable = (using = {}) =>
  quotes({
    method: "PATCH",
    contentTypeHeader: "application/x-www-form-urlencoded",
    ...using
  });
export const patchEffectiveDate = (using = {}) =>
  quotes({
    method: "PATCH",
    ...using
  });

export const getAlternateQuotes = ({ id }) =>
  fetchQuotes(`/quotes/${id}/alternative-quotes`);
export const getClaims = ({ id }) => fetchQuotes(`/claims/${id}`);
export const getForms = ({ productId }) =>
  fetchQuotes(`/products/${productId}/forms`);
export const getFastPremiumQuestions = ({ state }) =>
  fetchQuotes(`/fastpremium/interviewQuestions/${state}`);
export const getFastPremiumEstimates = payload =>
  fetchQuotes(`/fastpremium`, {
    method: "POST",
    body: JSON.stringify(payload)
  });

export const getProducts = data => fetchQuotes(`/products`, { params: data });
export const getProduct = ({ id, ...data }) =>
  getProducts(
    id
      ? {
          ...data,
          quoteId: id
        }
      : data
  ).then(([product = {}]) => product);

export const getContextWithWrapper = wrapRequest(({ id }) =>
  fetchQuotes(`/quotes/${id}/layout`)
);
// this is identical to getContextWithWrapper (which was getContext before) but does not use single-promise-cache that was needed
// before we started using react query
export const getContext = ({ id }) => fetchQuotes(`/quotes/${id}/layout`);

export const getIdentifiers = ({ id }) =>
  fetchQuotes(`/quotes/${id}/identifiers`);
export const getSearchResults = data =>
  fetchQuotes(`/quotes`, { params: { resultsetType: "compact", ...data } });
export const getSuggestResults = data =>
  fetchQuotes(`/quotes`, {
    params: {
      suggest: true,
      ...data
    }
  });

export const getInspectionStatus = ({ id }) =>
  fetchQuotes(`/quotes/${id}/inspection-status`);

export const getResources = () => fetchQuotes("/resources");

export const getProducerDefaults = () => fetchQuotes(`/producer-defaults`);
export const postProducerDefaults = ({ data }) =>
  fetchQuotes(`/producer-defaults`, {
    method: "POST",
    body: JSON.stringify(data)
  });

export const postFnolToClaims = data =>
  fetchQuotes(`/claims/channel`, {
    method: "POST",
    body: JSON.stringify(data)
  });

// PPQ-5687 calling this to fix prebind forms from pxClient
// TODO: remove this once flash is gone, but will require
// launchPreBindModal in legacy/quotes.js to be refactored
export const quoting = ({ id }) =>
  fetch(
    `${AP.config.services.quotingapi.url}${
      AP.config.services.quotingapi.baseUrl
    }/quotes/${id}?nocache=${Date.now()}`,
    {
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache"
      }
    }
  ).then(res => {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res.json();
  });

export const loadQuote = async quoteNumber => {
  const [quote, context, product] = await Promise.all([
    getQuote({ id: quoteNumber }),
    getContextWithWrapper({ id: quoteNumber }),
    getProduct({ id: quoteNumber }).catch(error => {
      console.error(error);
      return {};
    })
  ]);

  return {
    ...quote,
    product,
    documents: quote?.carriers?.[0]?.documents || [],
    insuredName: quote.inputs.InsuredName,
    sections: executeContext(context, flattenQuoteData(quote))
  };
};
