import $ from "jquery";

export default (username, b64Val, config) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.services.ixdirectory.proxy}${config.services.ixdirectory
        .baseUrl}identities/${username}?nocache=${new Date().getTime()}`,
      type: "GET",
      dataType: "text",
      beforeSend: xhr => {
        xhr.setRequestHeader("X-Crippled-Client", "yes");
        xhr.setRequestHeader("X-Method", "GET");
        xhr.setRequestHeader("X-Rest-Method", "GET");
        xhr.setRequestHeader("include-password", "true");
        xhr.setRequestHeader("Authorization", `Basic ${b64Val}`);
      }
    })
      .done((xml, status) => {
        if (status === "success" && xml.indexOf("<Identity") === 0) {
          resolve(xml);
          return;
        }
        reject("Invalid Login");
      })
      .fail(() => {
        reject("Invalid Login");
      });
  });
