import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./root-reducer";
import { toStorage } from "../utils/store-persist";
import { selectClosedNotices, selectClosedWhatsNew } from "./selectors/notices";
import { selectInsuranceType } from "./selectors/quote-quick-start";
import { CLOSED_NOTICES_KEY, CLOSED_WHATSNEW_KEY } from "./notices";
import { INSURANCE_TYPE_KEY } from "./quote-quick-start";

// This store supports the redux devtools extension in development.
// This isn't required to use rex, but it's a great tool for developing
// with redux.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const updateStorage = toStorage({
  [CLOSED_NOTICES_KEY]: selectClosedNotices,
  [CLOSED_WHATSNEW_KEY]: selectClosedWhatsNew,
  [INSURANCE_TYPE_KEY]: selectInsuranceType
});

store.subscribe(() => updateStorage(store.getState()));

if (module.hot) {
  module.hot.accept("./root-reducer", () =>
    store.replaceReducer(require("./root-reducer").default)
  );
}

export default store;
