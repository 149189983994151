import getUsers from "../promises/get-users";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickEnvInfo, pickUsers } from "./selectors";

const LOADING_STATE = "users/LOADING_STATE";
const COLLECTION = "users/COLLECTION";
const ERROR = "users/ERROR";

const setLoadingState = loadingState => ({
  type: LOADING_STATE,
  loadingState
});

const setCollection = collection => ({
  type: COLLECTION,
  collection
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

export const loadUsers = () => (dispatch, getState) => {
  const state = getState();
  const { alc } = pickEnvInfo(state);
  const { loadingState } = pickUsers(state);
  if (loadingState !== LOADING) {
    dispatch(setLoadingState(LOADING));
    return getUsers({ alc })
      .then(collection => dispatch(setCollection(collection)))
      .catch(errorMessage => dispatch(setError(errorMessage)));
  }
  return Promise.resolve();
};

export const loadUsersOnce = () => (dispatch, getState) => {
  const { loadingState } = pickUsers(getState());
  if (loadingState === PRELOAD) {
    return dispatch(loadUsers());
  }
  return Promise.resolve();
};

export const users = (
  state = {
    loadingState: PRELOAD,
    collection: [],
    errorMessage: null
  },
  data
) => {
  switch (data.type) {
    case LOADING_STATE: {
      return {
        ...state,
        loadingState: data.loadingState
      };
    }
    case COLLECTION: {
      return {
        ...state,
        collection: data.collection,
        loadingState: LOADED,
        errorMessage: null
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        collection: []
      };
    }
    default:
      return state;
  }
};
