import { externalModelUpdate } from "@icg360/rex";
import { isEqual, find, pick } from "lodash";

import { singleLineAddress } from "../../units/address-input";
import { printDate } from "../../utils/dates";

import {
  COMMERCIAL_FUSIONS,
  PL_FUSIONS,
  PREVENT,
  BLOCK,
  INFORM,
  STOP,
  QTC_FORM_ID
} from "./constants";

export const ACH_FIELDS = {
  accountType: "required",
  nameOfBank: null,
  routingNumber: "required",
  accountNumber: "required",
  payorFirstName: "required",
  payorLastName: "required",
  billingAddressLine1: "required",
  billingAddressLine2: null,
  billingAddressCity: "required",
  billingAddressState: "required",
  billingAddressZipCode: "required"
};

export const CC_FIELDS = {
  payorFirstName: "required",
  payorLastName: "required",
  accountNumber: "required",
  creditCardType: "required",
  billingAddressLine1: "required",
  billingAddressZipCode: "required",
  creditCardExpirationMonth: "required",
  creditCardExpirationYear: "required"
};

export const flattenInputs = (inputs, isCommercial = false) =>
  inputs.reduce((acc, input) => {
    const supportedFusions = isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS;
    if (input.fusion && supportedFusions.includes(input.fusion)) {
      return [
        ...acc,
        { ...input, inputs: flattenInputs(input.inputs, isCommercial) }
      ];
    }
    if (input.type === "group") {
      return [...acc, ...flattenInputs(input.inputs, isCommercial)];
    }
    if (input.type === "repeat") {
      return input.inputs.reduce(
        (repeatAcc, repeatInputs) => [
          ...repeatAcc,
          ...flattenInputs(repeatInputs, isCommercial)
        ],
        acc
      );
    }
    return [...acc, input];
  }, []);

export const flattenInputsIgnoreFusions = inputs =>
  inputs.reduce((acc, input) => {
    if (input.type === "repeat") {
      return input.inputs.reduce(
        (repeatAcc, repeatInputs) => [
          ...repeatAcc,
          ...flattenInputs(repeatInputs)
        ],
        acc
      );
    }
    if (input.inputs) {
      return [...acc, ...flattenInputs(input.inputs)];
    }
    return [...acc, input];
  }, []);

export const fusionInputsToObject = inputs =>
  inputs.reduce(
    (acc, { fusionName, ...input }) =>
      fusionName ? { ...acc, [fusionName]: input } : acc,
    {}
  );

export const reduceLocationMeta = meta =>
  meta.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});

export const formatAddress = ({
  streetNumber = null,
  streetName = "",
  city = "",
  state = "",
  zipcode = ""
}) =>
  streetNumber && city && state
    ? singleLineAddress({
        streetNumber,
        route: streetName,
        city,
        state,
        postalCode: zipcode
      })
    : null;

export const groupAddressSections = groups => {
  return groups.reduce((acc, { inputs, label }) => {
    const address = inputs.find(({ fusion }) => fusion === "address");
    return address ? [...acc, { address, label }] : acc;
  }, []);
};

export const mapFusionInputsToObject = inputs =>
  inputs.reduce(
    (acc, { fusionName, value }) =>
      fusionName ? { ...acc, [fusionName]: value } : acc,
    {}
  );

export const getAddressFusionDetails = (groups, isCommercial = false) => {
  const fusions = isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS;
  if (!fusions.includes("address") || !fusions.includes("location")) {
    return {};
  }
  return groups.reduce((acc, { inputs, label }) => {
    const location = inputs.find(({ fusion }) => fusion === "location");
    const address = inputs.find(({ fusion }) => fusion === "address");
    if (location && address) {
      const locationData = mapFusionInputsToObject(location.inputs);
      const addressData = mapFusionInputsToObject(address.inputs);
      acc[label] = {
        locationNumber: locationData.locationNumber,
        premisesNumber: locationData.premisesNumber,
        buildingNumber: locationData.buildingNumber,
        address: formatAddress(addressData)
      };
    }
    return acc;
  }, {});
};

// Giving a list of addresses in order, derive the "Premise" and "Building" numbers
// Premise increments every time a new address occurs
// Building increments every time an address re-occurs
// Example return:
// [{ premisesNumber: 1, buildingNumber: 1 }, { premisesNumber: 2, buildingNumber: 1 }]
const calculatePremisesBuildingNumbers = addresses => {
  const { sections } = addresses.reduce(
    (acc, address) => {
      if (!address) {
        acc.sections.push({
          premisesNumber: "",
          buildingNumber: ""
        });
        return acc;
      }
      if (acc.locations[address]) {
        acc.locations[address].buildingCount += 1;
      } else {
        acc.locationCount += 1;
        acc.locations[address] = {
          locationVal: acc.locationCount,
          buildingCount: 1
        };
      }
      acc.sections.push({
        premisesNumber: acc.locations[address].locationVal.toString(),
        buildingNumber: acc.locations[address].buildingCount.toString()
      });
      return acc;
    },
    { locations: {}, locationCount: 0, sections: [] }
  );
  return sections;
};

// From:
// [{ premisesNumber: 1, buildingNumber: 1 }, { premisesNumber: 2, buildingNumber: 1 }]
// To:
// {
//   Location1PremisesNumber: 1,
//   Location1BuildingNumber: 1,
//   Location2BuildingNumber: 2,
//   Location2BuildingNumber: 1,
// }
const premisesBuildingNumbersToTermNames = premisesBuildingNumbers =>
  premisesBuildingNumbers.reduce(
    (acc, { premisesNumber, buildingNumber }, index) => ({
      ...acc,
      [`Location${index + 1}PremisesNumber`]: premisesNumber,
      [`Location${index + 1}BuildingNumber`]: buildingNumber
    }),
    {}
  );

// For multi-location products, calculate the new premises and building numbers
export const changePremisesBuildingNumbers = ({
  groupLabel,
  nextAddress,
  addressFusionDetails,
  dispatch
}) => {
  const currentAddressDetails =
    addressFusionDetails && addressFusionDetails[groupLabel];
  if (!currentAddressDetails) {
    return;
  }
  const sortedAddressFusionsDetails = Object.values(addressFusionDetails).sort(
    (a, b) => a.locationNumber - b.locationNumber
  );
  const currentNumbers = sortedAddressFusionsDetails.map(obj =>
    pick(obj, ["premisesNumber", "buildingNumber"])
  );
  const nextNumbers = calculatePremisesBuildingNumbers(
    sortedAddressFusionsDetails.map(({ address, locationNumber }) =>
      // swap out the new address not yet in `addressFusionDetails`
      locationNumber === currentAddressDetails.locationNumber
        ? nextAddress
        : address
    )
  );
  if (!isEqual(currentNumbers, nextNumbers)) {
    dispatch(
      externalModelUpdate({
        data: premisesBuildingNumbersToTermNames(nextNumbers),
        formId: QTC_FORM_ID
      })
    );
  }
};

export const changeAllPremisesBuildingNumbers = ({
  addressFusionDetails,
  dispatch
}) => {
  const sortedListOfAddresses = Object.values(addressFusionDetails)
    .sort((a, b) => a.locationNumber - b.locationNumber)
    .map(({ address }) => address);
  const nextNumbers = calculatePremisesBuildingNumbers(sortedListOfAddresses);
  dispatch(
    externalModelUpdate({
      data: premisesBuildingNumbersToTermNames(nextNumbers),
      formId: QTC_FORM_ID
    })
  );
};

export function gatherMessages(eligibility) {
  let output = [];
  // if there are prevent messages, only show those (and not the others)
  if (eligibility?.prevent?.result) {
    eligibility.prevent.details.forEach(msg => {
      output.push({ ...msg, type: PREVENT });
    });
  }
  if (!eligibility?.prevent?.result && eligibility?.block?.result) {
    eligibility.block.details.forEach(msg => {
      output.push({ ...msg, type: BLOCK });
    });
  }
  if (!eligibility?.prevent?.result && eligibility?.inform?.result) {
    eligibility.inform.details.forEach(msg => {
      output.push({ ...msg, type: INFORM });
    });
  }
  if (!eligibility?.prevent?.result && eligibility?.stop?.result) {
    eligibility.stop.details.forEach(msg => {
      output.push({ ...msg, type: STOP });
    });
  }
  return output;
}

export const getRateTotal = results =>
  results?.FormatTotalPremium?.result || "—";

export const countRequiredEmptyPaymentFields = (
  groups,
  achFormData,
  ccFormData
) => {
  let count = 0;
  let formData = null;
  let fieldData = null;

  const paymentMethod = find(groups, { id: "PaymentMethod" })?.value;
  switch (paymentMethod) {
    case "300":
      formData = achFormData;
      fieldData = ACH_FIELDS;
      break;
    case "400":
      formData = ccFormData;
      fieldData = CC_FIELDS;
      break;
    default:
      formData = null;
      fieldData = null;
      break;
  }

  if (formData) {
    // Increment count for each required field in fieldData
    // that does not have a value in formData
    for (let key in fieldData) {
      if (fieldData[key] && formData[key] === null) {
        count += 1;
      }
    }
  }

  return count;
};

export const getLatestFormattedUWTimestamp = ({ referral }) => {
  const latestTaskTimestamp = referral?.notes?.reduce((acc, note) => {
    if (!acc || note.createdTimestamp > acc) {
      acc = note.createdTimestamp;
    }
    return acc;
  }, "");
  return latestTaskTimestamp
    ? `${printDate(latestTaskTimestamp)}  ${printDate(
        latestTaskTimestamp,
        "h:mm a"
      )}`
    : "N/A";
};

export const camelCase = str => {
  str = replaceAccents(str);
  str = removeNonWord(str)
    .replace(/-/g, " ") //convert all hyphens to spaces
    .replace(/\s[a-z]/g, upperCase) //convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") //remove spaces
    .replace(/^[A-Z]/g, lowerCase); //convert first char to lowercase
  return str;
};

export const replaceAccents = str => {
  // verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return str;
};

export const removeNonWord = str => {
  return str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, "");
};

function lowerCase(str) {
  return str.toLowerCase();
}

function upperCase(str) {
  return str.toUpperCase();
}
