import "whyDidYouRender";
import "startMSWWorker";
import $ from "jquery";
import "portalCSS";
import AP from "./legacy/global";
import portalInit from "./portal-init";
import store from "./store";
import * as cookies from "./utils/cookies";
import { logout } from "./utils/creds";

const b64 = cookies.read("apb64");
const authString = cookies.read("auth-session-uri");
const envInfo = {
  ...ENV_INFO,
  auth: cookies.read("aptoken"),
  b64,
  authString,
  //if this is set to something besides NULL, it means this is an SAML login
  agentToken: cookies.read("apagenttoken"),
  //if this is set to something besides NULL, it means this is an Agent Support View login
  agencyLocationID: cookies.read("agencylocationid")
};

const initialLoader = document.getElementById("initialLoader");

const updateStatus = text => {
  initialLoader.innerText = text;
  document.body.setAttribute("data-state", "portal");
};

const reporter = report => {
  console.warn(report);
};

portalInit(store, envInfo, updateStatus, reporter)
  .then(app => {
    if (app) {
      app.default();
    }
  })
  .catch(e => {
    console.error(e);
    $(document).on("click", ".js-logout", logout);
    $("#portalbody").html(
      `<div style="width: 700px; margin: 125px auto; text-align: center">
        <div className="PortalMain">
          <h3 style="margin-bottom: 10px">
            There was a problem loading the Agent Portal config file. Please
            logout and try again. If the problem persists, contact support.
          </h3>
          ${
            AP.config
              ? '<button class="btn btn-primary js-logout">Log Out</button>'
              : ""
          }
        </div>
      </div>`
    );
  });
