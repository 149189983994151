import React from "react";
import PropTypes from "prop-types";

const Compare = ({ className }) => (
  <svg
    className={`icon-compare${className ? ` ${className}` : ""}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <defs>
      <path id="a" d="M0 0h24v24H0V0z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path
      clipPath="url(#b)"
      d="M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z"
    />
  </svg>
);

Compare.propTypes = {
  className: PropTypes.string
};

export default Compare;
