import React from "react";

const Search = () => (
  <svg
    className="icon-search"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1600 1600"
    preserveAspectRatio="xMidYMid"
  >
    <path d="M1107.7 676.9c0-118.6-42.1-220-126.4-304.3-84.3-84.3-185.8-126.4-304.4-126.4s-220 42.1-304.3 126.4-126.4 185.7-126.4 304.3 42.1 220 126.4 304.3 185.7 126.4 304.3 126.4 220-42.1 304.3-126.4c84.3-84.2 126.5-185.7 126.5-304.3zm492.3 800c0 33.3-12.2 62.2-36.5 86.5s-53.2 36.5-86.5 36.5c-34.6 0-63.5-12.2-86.5-36.5l-329.8-328.8C946 1314.1 818.1 1353.8 677 1353.8c-91.7 0-179.3-17.8-263-53.4-83.7-35.6-155.8-83.7-216.3-144.2S88.9 1023.6 53.4 939.9 0 768.6 0 676.9s17.8-179.3 53.4-263S137 258.2 197.6 197.6 330.3 88.9 413.9 53.4 585.3 0 676.9 0s179.3 17.8 263 53.4 155.8 83.7 216.3 144.2 108.7 132.7 144.2 216.3c35.6 83.7 53.4 171.3 53.4 263 0 141-39.7 268.9-119.2 383.7l329.8 329.8c23.7 23.7 35.6 52.5 35.6 86.5z" />
  </svg>
);

export default Search;
