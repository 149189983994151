/* global google */
import React, { useEffect, useRef, useState } from "react";
import PT from "prop-types";

MapCoordinates.propTypes = {
  initial: PT.shape({
    lat: PT.number,
    lng: PT.number
  }),
  initialZoom: PT.number,
  onChange: PT.func.isRequired,
  showMarker: PT.bool
};

MapCoordinates.defaultProps = {
  initial: { lat: 38.224, lng: -96.612891 },
  initialZoom: 4
};

export default function MapCoordinates({
  initial,
  initialZoom,
  onChange,
  showMarker,
  ...props
}) {
  const mapEl = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const changeMade = useRef(false);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(
    () => {
      if (map.current) {
        map.current.setCenter(initial);
      }
      if (marker.current) {
        marker.current.setPosition(initial);
      }
      changeMade.current = false;
    },
    [initial]
  );

  useEffect(
    () => {
      if (map.current) {
        map.current.setZoom(initialZoom);
      }
      changeMade.current = false;
    },
    [initialZoom]
  );

  useEffect(
    () => {
      if (map.current && marker.current) {
        marker.current.setMap(showMarker ? map.current : null);
      }
      setIsFirstRender(false);
    },
    [showMarker]
  );

  useEffect(() => {
    map.current = new google.maps.Map(mapEl.current, {
      center: initial,
      zoom: initialZoom
    });

    marker.current = new google.maps.Marker({
      position: initial,
      map: showMarker || isFirstRender ? map.current : null
    });

    // update marker position when center is changed, but don't trigger
    // a change until the map has gone idle (see handler below)
    const centerChangedListener = google.maps.event.addListener(
      map.current,
      "center_changed",
      () => {
        if (!marker.current) return;
        const center = map.current.getCenter();
        if (!center) return;
        marker.current.setPosition({ lat: center.lat(), lng: center.lng() });
        changeMade.current = true;
      }
    );

    const idleListener = google.maps.event.addListener(
      map.current,
      "idle",
      () => {
        const center = map.current.getCenter();
        if (!center) return;
        const pos = { lat: center.lat(), lng: center.lng() };
        marker.current.setPosition(pos);
        if (changeMade.current) {
          onChange(pos);
        }
      }
    );

    return () => {
      google.maps.event.removeListener(centerChangedListener);
      google.maps.event.removeListener(idleListener);
    };
  }, []);

  return <div ref={mapEl} style={{ height: "360px" }} {...props} />;
}
