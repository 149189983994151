import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@icg360/ui-toolkit";

import { track } from "../../../units/tracking";

import { expireValue, resetValue } from "./timing-utils";

const Thumbs = ({
  trackingKey,
  question = "Was this feature helpful?",
  showYesNoText = true,
  color,
  onNextStep,
  expireInSeconds,
  resetInDays,
  onThumbUp = () => {},
  onThumbDown = () => {}
}) => {
  const handleVote = vote => {
    const args = {
      trackingKey,
      vote,
      expires: expireValue(expireInSeconds),
      reset: resetValue(resetInDays)
    };
    track(`Give Feedback`, args);
    // Callbacks
    onNextStep(args);
    if (vote === "up") onThumbUp();
    if (vote === "down") onThumbDown();
  };

  return (
    <div className="vote thumbs">
      <div style={{ color: color }}>{question}</div>
      <a onClick={() => handleVote("up")}>
        <Icon name="thumb_up" size="xs" />
      </a>
      {showYesNoText && <div style={{ color: color }}>Yes</div>}
      <a onClick={() => handleVote("down")}>
        <Icon name="thumb_down" size="xs" />
      </a>
      {showYesNoText && <div style={{ color: color }}>No</div>}
    </div>
  );
};

Thumbs.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  question: PropTypes.string,
  showYesNoText: PropTypes.bool,
  color: PropTypes.string,
  onNextStep: PropTypes.func.isRequired,
  expireInSeconds: PropTypes.number,
  resetInDays: PropTypes.number,
  onThumbUp: PropTypes.func,
  onThumbDown: PropTypes.func
};

export default Thumbs;
