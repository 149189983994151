export * from "./policy-view";

export const MORTGAGEE_INFORMATION = [
  {
    label: "Primary Mortgage",
    key: "MortgageeNumber1"
  },
  {
    label: "Address",
    key: {
      $1: "Mortgagee1AddressLine1",
      $2: "Mortgagee1AddressCity",
      $3: "Mortgagee1AddressState",
      $4: "Mortgagee1AddressZip"
    },
    template: "$1 $2, $3 $4"
  },
  {
    label: "Loan Number",
    key: "LoanNumber1"
  }
];

export const HEADERS = [];

export const SECTIONS = {
  A: [],
  B: [
    {
      id: "documents",
      title: "Documents"
    },
    {
      id: "notes",
      title: "Notes"
    }
  ]
};
