import { init, listen } from "../units/launch-darkly";
import { register, setUserProperties } from "../units/tracking";

import { pickEnvInfo } from "./selectors";

const SET = "feature-flags/SET";

// control flags on https://app.launchdarkly.com/
export const flagDefaults = {
  easypay: false,
  "email-document-modal": false,
  "ipm-rescind-pending-cancellation": false,
  "ipm-resolve-inspection-conditions": false,
  "ipm-cancel-insured": false,
  "ipm-change-customer": false,
  "ipm-additional-interest": false,
  "live-chat": false,
  "nfs-flood-quote": false,
  "payment-plan": false,
  "quote-quick-start-personal": true,
  "quote-quick-start-commercial": false,
  mixpanel: true,
  "manage-whats-new": false,
  "ix-auth": false,
  "agency-manager": true,
  "tools-page-access": false,
  "tools-flash-enabler": false,
  "tools-quote-bridge": false,
  "make-payment-override-mindue": false,
  "make-payment-cancelled-policy": false,
  "feature-compare-quote": false,
  "recent-activity": false,
  cobrowsing: false,
  "show-prevent-products-in-compare-quote": false,
  "new-underwriting": true,
  resources: "classic",
  "resources-edit": true,
  "property-hazard": true,
  "price-table": false,
  "inspection-section": false,
  "inspection-banner": false
};

// Ability to override feature flags for testing purposes
const FLAG_OVERRIDE_URL_PARAM = "flag_overrides";

const getFlagOverrides = () => {
  const params = new URLSearchParams(document.location.search);
  if (params.has(FLAG_OVERRIDE_URL_PARAM)) {
    let overrides;
    try {
      overrides = JSON.parse(params.get(FLAG_OVERRIDE_URL_PARAM));
    } catch {
      // eslint-disable-next-line no-console
      console.warn(
        `Unable to apply flag_overrides from url params. Please check the formatting of your overrides: ${params.get(
          FLAG_OVERRIDE_URL_PARAM
        )}`
      );
      overrides = {};
    }
    return overrides;
  } else return {};
};

export const setFeatureFlags = (flags = {}) => {
  const flagOverrides = getFlagOverrides();

  return {
    type: SET,
    flags: { ...flags, ...flagOverrides }
  };
};

const updateTrackingInfo = (dispatch, getState) => {
  const { featureFlags: flags } = getState();

  const featureFlagsAsArrayOfStrings = Object.entries(flags).map(
    ([name, activeVariant]) => `${name}/${JSON.stringify(activeVariant)}`
  );
  setUserProperties({
    featureFlags: featureFlagsAsArrayOfStrings
  });
  register({
    featureFlags: featureFlagsAsArrayOfStrings
  });
};

export const initFeatureFlags = launchDarklyKey => (dispatch, getState) => {
  const {
    email,
    fullname,
    roles,
    alc,
    affiliation,
    channel,
    username,
    isAdmin,
    isBackoffice,
    loginType,
    organizationID,
    org: { pupStatus }
  } = pickEnvInfo(getState());

  const updateFlags = flags => {
    dispatch(setFeatureFlags(flags));
    dispatch(updateTrackingInfo);
  };

  return init({
    key: launchDarklyKey,
    user: {
      key: toLower(username),
      email: toLower(email),
      name: toLower(fullname),
      custom: {
        roles: toUpper(roles),
        alc: toUpper(alc),
        affiliation: toUpper(affiliation),
        channel: toUpper(channel),
        isAdmin,
        isBackoffice,
        loginType,
        organizationID: toLower(organizationID),
        pupStatus
      }
    }
  })
    .then(updateFlags)
    .then(() => {
      listen(updateFlags);
    });
};

export const featureFlags = (state = flagDefaults, { type, flags }) =>
  type === SET
    ? {
        ...state,
        ...flags
      }
    : state;

function toLower(stringOrArray) {
  if (Array.isArray(stringOrArray)) {
    return stringOrArray.map(item => toLower(item));
  }
  if (typeof stringOrArray === "string") {
    return stringOrArray.toLowerCase();
  }
  return stringOrArray;
}

function toUpper(stringOrArray) {
  if (Array.isArray(stringOrArray)) {
    return stringOrArray.map(item => toUpper(item));
  }
  if (typeof stringOrArray === "string") {
    return stringOrArray.toUpperCase();
  }
  return stringOrArray;
}
