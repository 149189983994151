export const QTC_FORM_ID = "edit-quote";
export const START_LOADING = "qtc/START_LOADING";
export const REFRESH_QUOTE = "qtc/REFRESH_QUOTE";
export const SUPPORT_ERROR = "qtc/SUPPORT_ERROR";
export const LOAD_ERROR = "qtc/LOAD_ERROR";
export const LOAD_SUCCESS = "qtc/LOAD_SUCCESS";
export const SEND_SAVE = "qtc/SEND_SAVE";
export const SAVE_SUCCESS = "qtc/SAVE_SUCCESS";
export const SAVE_ERROR = "qtc/SAVE_ERROR";
export const PAYMENT_ERROR = "qtc/PAYMENT_ERROR";
export const PAYMENT_SUCCESS = "qtc/PAYMENT_SUCCESS";
export const CHOOSE_PRODUCT = "qtc/CHOOSE_PRODUCT";
export const SET_MODAL_STATE = "qtc/SET_MODAL_STATE";
export const PUSH_CHANGE_HISTORY = "qtc/PUSH_CHANGE_HISTORY";
export const CLEAR_CHANGE_HISTORY = "qtc/CLEAR_CHANGE_HISTORY";
export const SET_QTC_COMPATIBILITY = "qtc/SET_QTC_COMPATIBILITY";
export const SET_EFFECTIVE_DATE_VALIDATION =
  "qtc/SET_EFFECTIVE_DATE_VALIDATION";
export const UPDATE_ACH_FORM = "qtc/UPDATE_ACH_FORM";
export const UPDATE_CC_FORM = "qtc/UPDATE_CC_FORM";
export const UPDATE_CHECK_FORM = "qtc/UPDATE_CHECK_FORM";
export const TOGGLE_SEARCH_MORTGAGEE_MODAL =
  "qtc/TOGGLE_SEARCH_MORTGAGEE_MODAL";

export const STEP_QUOTE = "quote";
export const STEP_SELECT = "select";
export const STEP_CHECKOUT = "checkout";

export const COMMERCIAL_FUSIONS = [
  "address",
  "mortgageeSearch",
  "location",
  "location-count"
];
export const PL_FUSIONS = ["address", "mortgageeSearch"];
export const SAVE_DEBOUNCE_INITIAL = 5000;
export const SAVE_DEBOUNCE_RATE = 2000;

export const SELECT_ALL = "SELECT_ALL";
export const SELECT_LIABILITY = "SELECT_LIABILITY";
export const SELECT_PROPERTY = "SELECT_PROPERTY";

// Eligibility Message Types
export const BLOCK = "BLOCK";
export const PREVENT = "PREVENT";
export const INFORM = "INFORM";
export const STOP = "STOP";

export const FETCH = {
  STATUS: {
    INITIAL: "INITIAL",
    BUSY: "BUSY",
    ERROR: "ERROR",
    DONE: "DONE"
  }
};

export const PERSISTED_TERMS = [
  "InsuranceScoreValid",
  "InsuranceScoreRetrieved",
  "InsuranceScoreStatusRetrieved",
  "InsuranceScoreReasonCode1",
  "InsuranceScoreReasonCode2",
  "InsuranceScoreReasonCode3",
  "InsuranceScoreReasonCode4"
];
export const ELIGIBILITY_SUBTYPE = "quotingeligibility";
export const LOSS_SUBTYPE = "prebindreview";
export const SET_QUOTE_CURRENT_PRODUCT = "qtc/currentProduct/set";
export const SET_RCE_MODAL_DATA = "qtc/replacementCostModal/data/set";
export const QTC_TRACK = {
  QUOTE_FORM: {
    QUOTE_EDIT_VIEW: "Quote Form - Quote Edit Viewed",
    QUOTE_EDIT_PRODUCT_QUOTED: "Quote Form - Product Quoted",
    RUN_LOSS_HISTORY: "Quote Form - Run Loss History Clicked",
    FLOOD_PRO_CLICKED: "Quote Form - Flood Pro Button Clicked",
    REPLACEMENT_COST_CLICKED: "Quote Form - Replacement Cost Clicked",
    RCE_MODAL_DISPLAYED: "Quote Form - RCE Modal Displayed",
    SECTION_COMPLETED: "Quote Form - Section Completed",
    HELP_ICON_CLICKED: "Quote Form - Help Icon Clicked",
    EFFECTIVE_DATE_CHANGED: "Quote Form - Effective Date Changed",
    FORM_NAV_BUTTON_CLICKED: "Quote Form - Form Navigation Button Clicked"
  },
  QUOTE_REFERRALS: {
    REFER_MODAL_DISPLAYED: "Quote Referrals - Refer Modal Displayed",
    REFER_PRODUCT_CLICKED: "Quote Referrals - Refer Product Clicked",
    REFER_PRODUCT_DISPLAYED: "Quote Referrals - Refer Product Displayed",
    MESSAGE_SUBMITTED: "Quote Referrals - Message Submitted",
    REFER_WITHDRAW_CLICKED: "Quote Referrals - Referral Withdraw Button Clicked"
  },
  QUOTE_SELECT: {
    PRODUCT_CARD_DISPLAYED: "Quote Select - Product Card Displayed",
    SELECT_SCREEN_VIEWED: "Quote Select - Select Screen Viewed",
    DEDUCTIBLE_TABLE_DISPLAYED: "Quote Select - Deductibles Table Displayed",
    VIEW_DEDUCTIBLE_OPTIONS_CLICKED:
      "Quote Select - View Deductible Options Clicked",
    DEDUCTIBLE_OPTIONS_DISPLAYED: "Quote Select - Deductible Options Displayed",
    NEW_DEDUCTIBLE_OPTION_SELECTED:
      "Quote Select - New Deductible Option Selected"
  },
  QUOTE_SIDEBAR: {
    COMPARE_CLICKED: "Quote Sidebar - Compare View Clicked",
    COMPARE_DISPLAYED: "Quote Sidebar - Compare View Displayed",
    PRODUCT_CARD_CLICKED: "Quote Sidebar - Product Card Clicked",
    MULTI_CARRIER_CARD_CLICKED: "Quote Sidebar - Multi-carrier Card Clicked",
    MESSAGE_LINK_CLICKED: "Quote Sidebar - Message Link Clicked",
    SIDEBAR_COLLAPSED: "Quote Sidebar - Sidebar Collapsed",
    SIDEBAR_EXPANDED: "Quote Sidebar - Sidebar Expanded"
  },
  QUOTE_CHECKOUT: {
    VIEWED: "Quote Checkout - Quote Checkout Viewed",
    BIND_CLICKED: "Quote Checkout - Bind Clicked",
    BIND_ERROR: "Quote Checkout - Bind Error Displayed",
    POLICY_BOUND: "Policy Bound - Bind succeeded",
    POST_BIND_MODAL_VIEWED: "Post Bind - Post Bind Modal Displayed"
  },
  QUOTE_NAVIGATION: {
    FIELD_SEARCHED: "Quote Navigation - Field Searched",
    STEP_CLICKED: "Quote Navigation - Next Step Clicked",
    NAVIGATION_CLICKED: "Quote Navigation - Navigation Clicked",
    SEARCHED_FIELD_CLICKED: "Quote Navigation - Searched Field Clicked",
    SECTION_CLICKED: "Quote Navigation - Section Clicked"
  },
  POST_BIND: {
    FLOOD_QUOTE_CLICKED: "Post Bind - Flood Quote Clicked"
  },
  QUOTE_COMPARE: {
    PAGE_VIEWED: "Compare: Page Viewed",
    SWITCH_PRODUCT: "Compare: Switch Products",
    NEW_QUOTE: "Compare: New Quote",
    EDIT_CURRENT_PRODUCT: "Compare: Edit Current Product",
    SELECT_PRODUCT: "Compare: Select"
  },
  LOSS_HISTORY: {
    REFER_LOSS_BUTTON_CLICKED: "Quote Edit - Refer Loss Button Clicked",
    REFER_LOSS_LINK_CLICKED: "Quote Edit - Refer Loss Link Clicked",
    REFER_LOSS_LINK_RENDERED: "Quote Edit - Refer Loss Link Displayed"
  }
};

export const LOSS_HISTORY = {
  RESET: "qtc/losshistory/reset",
  TYPES: {
    PROVIDED: "provided",
    RETRIEVED: "retrieved",
    APPLIED: "applied"
  }
};
