import { trimEnd } from "lodash";

// Turn any value type into a string consistently
const toString = anyValue => {
  if (typeof anyValue === "string") {
    return anyValue.replace(/[~,*{}[\]]/g, m => `~${m}`);
  }
  if (typeof anyValue === "undefined") {
    return `*u`;
  }
  if (anyValue === null) {
    return `*n`;
  }
  if (Array.isArray(anyValue)) {
    return `[${anyValue.map(toString).join()}]`;
  }
  if (typeof anyValue === "object") {
    return `{${Object.entries(anyValue)
      .sort(([aKey], [bKey]) => bKey.localeCompare(aKey))
      .reduce((acc, entry) => [...acc, ...entry], [])
      .map(toString)
      .join()}}`;
  }
  return `*${String(anyValue)}`;
};

export const numberFormat = (number, dec, dsep, tsep) => {
  if (isNaN(number) || number == null) return "";

  number = number.toFixed(~~dec);
  tsep = typeof tsep == "string" ? tsep : ",";

  var parts = number.split("."),
    fnums = parts[0],
    decimals = parts[1] ? (dsep || ".") + parts[1] : "";

  return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, "$1" + tsep) + decimals;
};

export const toSentence = (array, separator, lastSeparator, serial) => {
  separator = separator || ", ";
  lastSeparator = lastSeparator || " and ";
  var a = array.slice(),
    lastMember = a.pop();

  if (array.length > 2 && serial)
    lastSeparator = trimEnd(separator) + lastSeparator;

  return a.length ? a.join(separator) + lastSeparator + lastMember : lastMember;
};

export default toString;
