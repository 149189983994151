import { useEffect, useState } from "react";

export default ({ ref }) => {
  let timer;
  const [containsFocus, setContainsFocus] = useState(
    ref.current ? ref.current.contains(document.activeElement) : false
  );
  const handleFocusIn = e => {
    const contained = ref.current?.contains(e.target);
    if (contained) {
      clearTimeout(timer);
    }
    setContainsFocus(ref.current ? contained : false);
  };
  const handleFocusOut = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setContainsFocus(false);
    }, 100);
  };
  useEffect(() => {
    document.body.tabIndex = "-1";
    document.addEventListener("focusin", handleFocusIn, true);
    document.addEventListener("focusout", handleFocusOut, true);
    return () => {
      clearTimeout(timer);
      document.removeEventListener("focusin", handleFocusIn, true);
      document.removeEventListener("focusout", handleFocusOut, true);
    };
  }, []);
  return containsFocus;
};
