export default signers => {
  if (signers === "test") {
    return [
      {
        name: "joe dirt",
        email: "joe.dirt@icg360.com"
      },
      {
        name: "sally dirt",
        email: "sally.dirt@icg360.com"
      }
    ];
  } else if (signers) {
    return JSON.parse(signers.replace(/'/g, '"'));
  } else {
    return [];
  }
};
