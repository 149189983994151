import $ from "jquery";

export default response =>
  $(response)
    .find("Identity")
    .toArray()
    .map(identity => {
      const $identity = $(identity);
      return {
        name: $identity.find("Name").text(),
        email: $identity.find("Email").text(),
        login: $identity.attr("id")
      };
    });
