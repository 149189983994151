import eventHub from "../utils/event-hub";
import React from "react";
import postEsign from "../promises/post-esign-envelope";
import signerParser from "../utils/signer-parser";

const SHOW_MODAL = "esign/SHOW_MODAL";
const INIT_MODAL = "esign/INIT_MODAL";

export const showModal = show => ({
  type: SHOW_MODAL,
  show: show
});

export const initModal = data => ({
  type: INIT_MODAL,
  ...data
});

export const continueClick = () => (dispatch, getState) => {
  const { policy, info } = getState().esign;

  dispatch(showModal(false));

  // the affiliates component currently only accepts data via the eventHub
  // this needs to change once backbone is removed from the eSignModal code
  var policyConfirmationData = {
    policyId: policy.id,
    quoteId: policy.quoteId,
    policyData: info,
    modalData: {
      nfsEnabled: policy.NFSEnabled,
      message: {
        type: "success",
        title: "Thank you for partnering with SageSure!",
        description: (
          <div>
            <p>
              The Documents are on their way, and SageSure will order the
              inspection to be completed close to the effective date.
            </p>
            <p>
              Questions? Need to change something?{" "}
              <strong>Contact Policy Services at (877)304-4785.</strong>
            </p>
          </div>
        )
      }
    }
  };

  eventHub.trigger("policy-confirmation", policyConfirmationData);
};

export const fireAutosend = () => (dispatch, getState) => {
  const {
    documents,
    calculation,
    policy,
    insured,
    producer,
    carrierNameShort,
    message,
    info
  } = getState().esign;

  const recipients = [],
    docs = documents.nameList.split(","),
    docIds = documents.idList.split(",");

  let payload = {
    policyId: policy.id,
    quoteId: policy.quoteId,
    recipients: [],
    subject: `${carrierNameShort} Enrollment Documents for ${policy.id}`,
    message: message.body,
    fromAddress: message.fromAddress,
    fromName: message.fromName,
    documentIds: docIds,
    documents: docs,
    documentName: documents.pdfName,
    requestType: "autosend"
  };

  if (calculation.OpPostBindESignEmailSigners) {
    payload.signers = signerParser(
      calculation.OpPostBindESignEmailSigners
    ).signers;
    payload.recipients = signerParser(
      calculation.OpPostBindESignEmailReceivers
    ).receivers;
  } else {
    // TODO: remove this code block once all products are moved
    // to the signer array
    // PPQ-4725
    const coapplicantName = `${calculation.OpInsured2FirstName} ${calculation.OpInsured2LastName}`;

    payload.insuredEmail = calculation.OpESignatureEmailAddress;
    payload.insuredName =
      calculation.OpInsuredByCorporation === "100"
        ? calculation.OpInsuredName
        : insured.name;
    payload.producerEmail = producer.email;
    payload.producerName = producer.name;

    // Adding co-applicant logic PPQ-3045
    if (
      calculation.OpNamedInsuredIndicator >= 1 &&
      calculation.OpESignatureRecipient == 100
    ) {
      payload.coapplicantEmail = calculation.OpInsured2EmailAddress || "";
      payload.coapplicantName = coapplicantName;
    }

    // coapplicant requried flag PPQ-3213
    if (calculation.OpESignatureCoApplicantEmailRequired) {
      if (calculation.OpESignatureCoApplicantEmailRequired === "100") {
        payload.coapplicantRequired = true;
      }
      if (calculation.OpESignatureCoApplicantEmailRequired === "200") {
        payload.coapplicantRequired = false;
      }
    }

    // add insured to recipients for NJ WIC PPQ-4598
    if (calculation.OpCarrierID === "WIC" && info.PropertyState === "NJ") {
      if (calculation.OpInsuredEmailAddress) {
        recipients.push(calculation.OpInsuredEmailAddress);
      }
    }

    if (calculation.OpAdditionalEPolicyEmailAddress1) {
      recipients.push(calculation.OpAdditionalEPolicyEmailAddress1);
    }
    if (calculation.OpAdditionalEPolicyEmailAddress2) {
      recipients.push(calculation.OpAdditionalEPolicyEmailAddress2);
    }
    if (calculation.OpAdditionalEPolicyEmailAddress3) {
      recipients.push(calculation.OpAdditionalEPolicyEmailAddress3);
    }
    payload.recipients = recipients;
  }

  if (calculation.OpESignatureSender) {
    payload.eSignatureSender = calculation.OpESignatureSender;
  }

  return postEsign(payload).catch(console.log);
};

export const esign = (
  state = {
    show: false,
    policy: {},
    insured: {},
    documents: {},
    calculation: {},
    producer: {},
    carrierNameShort: {},
    message: {}
  },
  data
) => {
  switch (data.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        show: data.show
      };
    }
    case INIT_MODAL: {
      return {
        ...state,
        ...data
      };
    }
    default:
      return state;
  }
};
