import AP from "../legacy/global";
import $ from "jquery";
import { track } from "../units/tracking";

export default function switchProduct({
  quoteId = "",
  targetProductId = "",
  shouldCreateNewQuote = true
}) {
  const endpoint = `${AP.config.services.quotesapi.url}${
    AP.config.services.quotesapi.baseUrl
  }/quotes${shouldCreateNewQuote ? "" : `/${quoteId}`}`;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  };

  const body = $.param({
    targetProductId: targetProductId,
    sourceQuoteId: quoteId
  });

  return fetch(endpoint, {
    method: shouldCreateNewQuote ? "POST" : "PATCH",
    headers: headers,
    credentials: "include",
    body
  })
    .then(res => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then(res => {
      const { quoteId, carrier, fastPremiumDefaults, productType } = res;
      track("Quote - Quote Copied", {
        quoteId,
        carrier,
        productType,
        isFastPremium: !!fastPremiumDefaults?.length
      });
      return res;
    });
}
