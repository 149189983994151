import getRecentActivity from "../promises/get-recent-activity";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickEnvInfo, pickRecentActivity } from "./selectors";

const LOADING_STATE = "recent-activity/LOADING_STATE";
const COLLECTION = "recent-activity/COLLECTION";
const ERROR = "recent-activity/ERROR";
const OWN_ACTIVITY = "recent-activity/OWN_ACTIVITY";

const setLoadingState = loadingState => ({
  type: LOADING_STATE,
  loadingState
});

const setCollection = collection => ({
  type: COLLECTION,
  collection
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

const setOwnActivity = ownActivity => ({
  type: OWN_ACTIVITY,
  ownActivity
});

let requestCount = 0;
const thunkRecentActivity = (dispatch, getState) => {
  const state = getState();
  const { programID, alc, username } = pickEnvInfo(state);
  const { ownActivity } = pickRecentActivity(state);
  requestCount += 1;
  const thisRequest = requestCount;
  return getRecentActivity({
    programID,
    alc,
    username,
    ownActivity
  })
    .then(
      response =>
        thisRequest === requestCount && dispatch(setCollection(response))
    )
    .catch(errorMessage => {
      if (typeof errorMessage === "object") {
        console.warn(errorMessage);
      }
      return (
        thisRequest === requestCount &&
        dispatch(setError(typeof errorMessage === "string" ? errorMessage : ""))
      );
    });
};

export const submitOwnActivity = ownActivity => (dispatch, getState) => {
  dispatch(setOwnActivity(ownActivity));
  return thunkRecentActivity(dispatch, getState);
};

export const loadRecentActivity = () => (dispatch, getState) => {
  dispatch(setLoadingState(LOADING));
  return thunkRecentActivity(dispatch, getState);
};

export const recentActivity = (
  state = {
    loadingState: PRELOAD,
    collection: [],
    errorMessage: null,
    ownActivity: false
  },
  data
) => {
  switch (data.type) {
    case LOADING_STATE: {
      return {
        ...state,
        loadingState: data.loadingState
      };
    }
    case COLLECTION: {
      return {
        ...state,
        collection: data.collection,
        loadingState: LOADED,
        errorMessage: null
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        collection: []
      };
    }
    case OWN_ACTIVITY: {
      return {
        ...state,
        ownActivity: data.ownActivity,
        loadingState: LOADING
      };
    }
    default:
      return state;
  }
};
