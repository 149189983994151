import { includes } from "lodash";

import getNotices from "../promises/get-notices";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickEnvInfo, pickNotices } from "./selectors";
import { fromStorage } from "../utils/store-persist";

export const CLOSED_NOTICES_KEY = "closed-notices";
export const CLOSED_WHATSNEW_KEY = "closed-whatsnew";

const LOADING_STATE = "notices/LOADING_STATE";
const COLLECTION = "notices/COLLECTION";
const ERROR = "notices/ERROR";
const PAGE = "notices/PAGE";
const DISMISS_NOTICE = "notice/DISMISS_NOTICE";
const DISMISS_WHATSNEW = "notice/DISMISS_WHATSNEW";

const setLoadingState = loadingState => ({
  type: LOADING_STATE,
  loadingState
});

const setCollection = collection => ({
  type: COLLECTION,
  collection
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

export const setPage = page => {
  if (typeof page !== "number") {
    throw new Error("page must be a number");
  }
  return {
    type: PAGE,
    page
  };
};

export const dismissNotice = id => (dispatch, getState) => {
  const { username } = pickEnvInfo(getState());
  dispatch({
    type: DISMISS_NOTICE,
    id,
    scope: username
  });
};

export const dismissWhatsNew = id => (dispatch, getState) => {
  const { username } = pickEnvInfo(getState());
  dispatch({
    type: DISMISS_WHATSNEW,
    id,
    scope: username
  });
};

export const loadNotices = () => (dispatch, getState) => {
  const state = getState();
  const { loadingState } = pickNotices(state);
  const { isAdmin, programID, programConfigID } = pickEnvInfo(state);

  if (loadingState !== LOADING) {
    dispatch(setLoadingState(LOADING));
    return getNotices({ inactive: isAdmin, programID, programConfigID })
      .then(collection => dispatch(setCollection(collection)))
      .catch(errorMessage => {
        if (typeof errorMessage === "object") {
          console.warn(errorMessage);
        }
        dispatch(
          setError(typeof errorMessage === "string" ? errorMessage : "")
        );
      });
  }
  return Promise.resolve();
};

export const loadNoticesOnce = () => (dispatch, getState) => {
  const { loadingState } = pickNotices(getState());
  if (loadingState === PRELOAD) {
    return dispatch(loadNotices());
  }
  return Promise.resolve();
};

export const notices = (
  state = {
    loadingState: PRELOAD,
    collection: [],
    errorMessage: null,
    page: 1,
    closedNotices: fromStorage(CLOSED_NOTICES_KEY, {}),
    closedWhatsNew: fromStorage(CLOSED_WHATSNEW_KEY, {})
  },
  data
) => {
  switch (data.type) {
    case LOADING_STATE: {
      return {
        ...state,
        loadingState: data.loadingState
      };
    }
    case COLLECTION: {
      return {
        ...state,
        collection: [...data.collection].sort((a, b) => b.publish - a.publish),
        loadingState: LOADED,
        errorMessage: null,
        page: 1
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        collection: [],
        page: 1
      };
    }
    case PAGE: {
      return {
        ...state,
        page: data.page
      };
    }
    case DISMISS_NOTICE: {
      const idList = state.closedNotices[data.scope] || [];
      return includes(idList, data.id)
        ? state
        : {
            ...state,
            closedNotices: {
              ...state.closedNotices,
              [data.scope]: [...idList, data.id]
            }
          };
    }
    case DISMISS_WHATSNEW: {
      const idList = state.closedWhatsNew[data.scope] || [];
      return includes(idList, data.id)
        ? state
        : {
            ...state,
            closedWhatsNew: {
              ...state.closedWhatsNew,
              [data.scope]: [...idList, data.id]
            }
          };
    }
    default:
      return state;
  }
};
