import {
  Select,
  FormControl,
  ControlLabel,
  FormGroup,
  Col,
  Row,
  HelpBlock
} from "@icg360/ui-toolkit";
import React, { useState } from "react";
import { RadioButtons } from "./radio-buttons";
import { useDispatch, useSelector } from "react-redux";
import { updateAchFormData } from "../../../store/quote-transaction-component";
import { selectAchFormData } from "../../../store/selectors/qtc-selectors";
import ALL_STATES from "../../../static-data/state-list";
import {
  isZip,
  isAccountValid,
  isRoutingValid
} from "../../../utils/validation";

const bankAccDetails = [
  { label: "Checking", value: "100" },
  { label: "Savings", value: "200" }
];

function AchForm() {
  const achFormData = useSelector(selectAchFormData);
  const dispatch = useDispatch();

  const updateAchForm = input => dispatch(updateAchFormData(input));

  const {
    accountType,
    bankName,
    payorFirstName,
    payorLastName,
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressState,
    routingNumber,
    accountNumber,
    billingAddressZipCode
  } = achFormData;
  const selectedAccType = getSelectedOptions(accountType, bankAccDetails);

  const [validationFields, setValidationFields] = useState({
    routingNumberState: routingNumber || null,
    accountNumberState: accountNumber || null,
    zipCodeState: billingAddressZipCode || null
  });

  return (
    <div className="payment-form-container">
      <FormGroup>
        <div className="content">
          <ControlLabel className="inputLabel">
            {"Bank Account Type "}
            {!accountType && <span className="text-danger">*</span>}
          </ControlLabel>
          <RadioButtons
            id={"bank"}
            details={bankAccDetails}
            selected={selectedAccType}
            value={selectedAccType || ""}
            onChange={e => {
              updateAchForm({ id: "accountType", value: e.target.value });
            }}
          />
        </div>
      </FormGroup>
      <FormGroup controlId="bankName">
        <ControlLabel className="inputLabel">{"Name of Bank"}</ControlLabel>
        <FormControl
          type="text"
          value={bankName || ""}
          onChange={e =>
            updateAchForm({ id: "bankName", value: e.target.value })
          }
        />
      </FormGroup>
      <Row>
        <Col lg={6}>
          <ControlLabel className="inputLabel">
            {"Bank (ABA) Routing Number "}
            {(!validationFields.routingNumberState ||
              !isRoutingValid(validationFields.routingNumberState)) && (
              <span className="text-danger">*</span>
            )}
          </ControlLabel>
          <FormGroup
            validationState={
              validationFields.routingNumberState
                ? isRoutingValid(validationFields.routingNumberState)
                  ? null
                  : "error"
                : null
            }
          >
            <FormControl
              type="text"
              value={validationFields.routingNumberState || ""}
              onChange={e => {
                const { value } = e.target;

                setValidationFields(prevState => ({
                  ...prevState,
                  routingNumberState: value
                }));
                updateAchForm({
                  id: "routingNumber",
                  value: isRoutingValid(value) ? value : null
                });
              }}
            />
            {validationFields.routingNumberState &&
              !isRoutingValid(validationFields.routingNumberState) && (
                <HelpBlock>Please enter a 9-digit number</HelpBlock>
              )}
          </FormGroup>
        </Col>
        <Col lg={6}>
          <ControlLabel className="inputLabel">
            {"Account Number "}
            {(!validationFields.accountNumberState ||
              !isAccountValid(validationFields.accountNumberState)) && (
              <span className="text-danger">*</span>
            )}
          </ControlLabel>
          <FormGroup
            validationState={
              validationFields.accountNumberState
                ? isAccountValid(validationFields.accountNumberState)
                  ? null
                  : "error"
                : null
            }
          >
            <FormControl
              type="text"
              value={validationFields.accountNumberState || ""}
              onChange={e => {
                const { value } = e.target;

                setValidationFields(prevState => ({
                  ...prevState,
                  accountNumberState: value
                }));
                updateAchForm({
                  id: "accountNumber",
                  value: isAccountValid(value) ? value : null
                });
              }}
            />
            {validationFields.accountNumberState &&
              !isAccountValid(validationFields.accountNumberState) && (
                <HelpBlock>Please enter numbers only</HelpBlock>
              )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <ControlLabel>
              {"First Name on Account "}
              {!payorFirstName && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormControl
              type="text"
              value={payorFirstName || ""}
              onChange={e =>
                updateAchForm({
                  id: "payorFirstName",
                  value: e.target.value
                })
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <ControlLabel>
              {"Last Name on Account "}
              {!payorLastName && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormControl
              type="text"
              value={payorLastName || ""}
              onChange={e =>
                updateAchForm({
                  id: "payorLastName",
                  value: e.target.value
                })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <ControlLabel>
              {"Street Address Line 1 "}
              {!billingAddressLine1 && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormControl
              type="text"
              value={billingAddressLine1 || ""}
              onChange={e =>
                updateAchForm({
                  id: "billingAddressLine1",
                  value: e.target.value
                })
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <ControlLabel>{"Street Address Line 2"}</ControlLabel>
            <FormControl
              type="text"
              value={billingAddressLine2 || ""}
              onChange={e =>
                updateAchForm({
                  id: "billingAddressLine2",
                  value: e.target.value
                })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <ControlLabel>
              {"City "}
              {!billingAddressCity && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormControl
              type="text"
              value={billingAddressCity || ""}
              onChange={e =>
                updateAchForm({
                  id: "billingAddressCity",
                  value: e.target.value
                })
              }
            />
          </FormGroup>
        </Col>
        <Col lg={2}>
          <FormGroup className="state-container">
            <ControlLabel>
              {"State "}
              {!billingAddressState && <span className="text-danger">*</span>}
            </ControlLabel>
            <Select
              placeholder=""
              menuPlacement="auto"
              options={ALL_STATES.map(({ abbreviation }) => ({
                label: abbreviation,
                value: abbreviation
              }))}
              value={
                { label: billingAddressState, value: billingAddressState } || ""
              }
              onChange={e => {
                updateAchForm({
                  id: "billingAddressState",
                  value: e.value
                });
              }}
              className="ap-select"
            />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <ControlLabel className="inputLabel">
            {"Zip Code "}
            {(!validationFields.zipCodeState ||
              !isZip(validationFields.zipCodeState)) && (
              <span className="text-danger">*</span>
            )}
          </ControlLabel>
          <FormGroup
            validationState={
              validationFields.zipCodeState
                ? isZip(validationFields.zipCodeState)
                  ? null
                  : "error"
                : null
            }
          >
            <FormControl
              type="text"
              value={validationFields.zipCodeState || ""}
              onChange={e => {
                const { value } = e.target;

                setValidationFields(prevState => ({
                  ...prevState,
                  zipCodeState: value
                }));
                updateAchForm({
                  id: "billingAddressZipCode",
                  value: isZip(value) ? value : null
                });
              }}
            />
            {validationFields.zipCodeState &&
              !isZip(validationFields.zipCodeState) && (
                <HelpBlock>Please enter a 5-digit number</HelpBlock>
              )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

function getSelectedOptions(modelValue, options) {
  return options.find(({ value }) => value === modelValue);
}

export const ACHForm = AchForm;
