import { padEnd } from "lodash";
const formatSignedCurrencyVal = val => {
  const split = val.split(".");
  const padded = [split[0], padEnd(split[1], 2, "0")].join(".");
  const containsDollarSign = padded.indexOf("$") !== -1;
  return padded.indexOf("-") !== -1
    ? `- ${containsDollarSign ? "" : "$"}${padded.replace("-", "")}`
    : `+ ${containsDollarSign ? "" : "$"}${padded}`;
};

export default formatSignedCurrencyVal;
