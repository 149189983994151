import htmlparser from "htmlparser2";

export default htmlResponse => {
  try {
    let state = "";
    const result = {};
    new htmlparser.Parser(
      {
        onopentag: (name, attrs) => {
          state = attrs.class || "";
        },
        ontext: text => {
          if (!state) return;
          // collapse whitespace
          result[state] = text.replace(/\s+/g, " ").trim();
        },
        onclosetag: () => {
          state = "";
        }
      },
      { xmlMode: true, decodeEntities: true }
    ).end(htmlResponse);
    return result;
  } catch (e) {
    return null;
  }
};
