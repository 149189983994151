const LOAD = "env-info/LOAD";

export const loadEnvInfo = set => ({
  type: LOAD,
  set
});

export const envInfo = (state = {}, data) => {
  switch (data.type) {
    case LOAD: {
      return {
        ...state,
        ...data.set
      };
    }
    default:
      return state;
  }
};
