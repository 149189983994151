import moment from "moment";
import { identity, reduce, some } from "lodash";
import formatCurrency from "../format-currency";
import AP from "../../../legacy/global";

const isISO = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T/;

export const mapType = {
  currency: formatCurrency,
  currencyPrecise: value => formatCurrency(value, true),
  date: value =>
    isISO.test(value) ? moment.utc(value).format("MM/DD/YYYY") : value,
  payplan: value => {
    const paymentPlans = AP.vocab.find(
      plans => plans.name === "PaymentPlanType"
    );
    const planLabel = paymentPlans
      ? paymentPlans.enumerations.enumerations.find(
          plan => plan.value.toLowerCase() === value.toLowerCase()
        ).label
      : "";
    return planLabel;
  },
  identity
};

const tryRunning = (fn, ...args) => {
  try {
    return fn(...args);
  } catch (ex) {
    return;
  }
};

const mapDataToDataArray = (
  {
    label,
    key,
    type,
    location = "policyData",
    template = "",
    valueCodesToLabels,
    defaultValue,
    fallback
  },
  data
) => {
  const section = data[location] || {};
  const generatedLabel =
    typeof label === "function" ? tryRunning(label, section) : label;
  if (typeof key === "object") {
    return [
      key,
      generatedLabel,
      some(key, value => section[value])
        ? reduce(
            key,
            (acc, value, pattern) => acc.replace(pattern, section[value] || ""),
            template
          )
        : ""
    ];
  }
  const value =
    typeof key === "function" ? tryRunning(key, section) : section[key];
  if (!value && fallback) {
    return mapDataToDataArray({ label, ...fallback }, data);
  } else {
    const valueLabel =
      valueCodesToLabels && valueCodesToLabels[String(value).toUpperCase()];
    return [key, generatedLabel, valueLabel || value || defaultValue, type];
  }
};

const addPolicyValues = (mappingArray, data) =>
  mappingArray
    .map(item => mapDataToDataArray(item, data))
    .map(([key, label, value, type = "identity"]) => ({
      key,
      label,
      value: value ? mapType[type](value) : "---"
    }));

export default addPolicyValues;
