import storage from "./storage";

export const setObject = (name, object) => {
  storage.setItem(name, window.btoa(JSON.stringify(object)));
};

export const getObject = name => {
  try {
    const raw = storage.getItem(name);
    return raw ? JSON.parse(window.atob(raw)) : {};
  } catch (ex) {
    return {};
  }
};
