import {
  createModelProvider,
  externalModelUpdate,
  externalResetFields,
  loadNewForm,
  triggerEvent,
  externalRunBinds,
  updateConfig
} from "@icg360/rex";
import { produce } from "immer";
import { batch } from "react-redux";
import { pick } from "lodash";

import {
  CHOOSE_PRODUCT,
  CLEAR_CHANGE_HISTORY,
  COMMERCIAL_FUSIONS,
  FETCH,
  REFRESH_QUOTE,
  LOAD_ERROR,
  LOAD_SUCCESS,
  LOSS_HISTORY,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
  PERSISTED_TERMS,
  PL_FUSIONS,
  PUSH_CHANGE_HISTORY,
  QTC_FORM_ID,
  SAVE_ERROR,
  SAVE_SUCCESS,
  SELECT_ALL,
  SELECT_LIABILITY,
  SELECT_PROPERTY,
  SEND_SAVE,
  SET_EFFECTIVE_DATE_VALIDATION,
  SET_MODAL_STATE,
  SET_QTC_COMPATIBILITY,
  SET_QUOTE_CURRENT_PRODUCT,
  SET_RCE_MODAL_DATA,
  START_LOADING,
  STEP_SELECT,
  STEP_QUOTE,
  SUPPORT_ERROR,
  TOGGLE_SEARCH_MORTGAGEE_MODAL,
  UPDATE_ACH_FORM,
  UPDATE_CC_FORM,
  UPDATE_CHECK_FORM
} from "../components/quote-transaction-component/constants";
import allFusions from "../components/quote-transaction-component/fusions";
import { flattenInputsIgnoreFusions } from "../components/quote-transaction-component/utils";
import shapeDataForLaunchSignDocumentModal from "../components/quote-transaction-component/utils/shape-data-for-launch-sign-document-modal";
import AP from "../legacy/global";
import {
  getForms,
  getProduct,
  getQuote,
  patchQuote,
  putQuote
} from "../promises/quotes";

import { pickEnvInfo, pickQTC } from "./selectors";
import {
  memoDeriveForm,
  selectFormList,
  selectProductSelection,
  selectQTCModals,
  selectQTCQuote,
  selectIsCommercial,
  selectQuoteInputs
} from "./selectors/qtc-selectors";

const startLoading = (quoteNumber, step) => ({
  type: START_LOADING,
  quoteNumber,
  step
});
export const refreshQuote = () => ({
  type: REFRESH_QUOTE
});
export const loadSuccess = ({ data, effectiveDate }) => ({
  type: LOAD_SUCCESS,
  data,
  effectiveDate
});
const supportError = () => ({ type: SUPPORT_ERROR });
const errorLoading = message => ({
  type: LOAD_ERROR,
  message
});

export const saveSuccess = (data, { lossHistory, bind }) => ({
  type: SAVE_SUCCESS,
  data,
  lossHistory,
  bind
});

const productChange =
  ({ id, type, byUser, quoteNumber }) =>
  dispatch => {
    let CoverageSelectionIndicator;
    switch (type) {
      case SELECT_LIABILITY:
        CoverageSelectionIndicator = "200";
        break;
      case SELECT_PROPERTY:
        CoverageSelectionIndicator = "150";
        break;
      default:
        CoverageSelectionIndicator = "100";
        break;
    }
    dispatch({
      type: CHOOSE_PRODUCT,
      payload: { id, type, byUser, quoteNumber }
    });
    if (id != null && type != null) {
      dispatch(
        externalModelUpdate({
          data: { CoverageSelectionIndicator },
          formId: QTC_FORM_ID
        })
      );
    }
  };

export const chooseProduct = options => dispatch => {
  batch(() => {
    dispatch(productChange(options));
    if (options.id != null && options.type != null) {
      dispatch(saveQuote());
    }
  });
};

export const setQTCCompatibility = payload => ({
  type: SET_QTC_COMPATIBILITY,
  payload
});

export const clearProductSelect = () => (dispatch, getState) => {
  const selection = selectProductSelection(getState());
  const forms = selectFormList(getState());
  if (!forms.includes("checkout") || !selection.id || !selection.type) {
    return;
  }
  dispatch({
    type: CHOOSE_PRODUCT,
    payload: { id: null, type: null, byUser: false, quoteNumber: null }
  });
  dispatch(
    externalModelUpdate({
      data: {
        CoverageSelectionIndicator: "100",
        PaymentPlanType: "",
        PaymentMethod: ""
      },
      formId: QTC_FORM_ID
    })
  );
  dispatch(
    externalResetFields({
      useForm: { id: QTC_FORM_ID, childId: "checkout" },
      fields: ["PaymentPlanType", "PaymentMethod"]
    })
  );
};

export const toggleQTCModal =
  ({ modal }) =>
  (dispatch, getState) => {
    const isOpen = !selectQTCModals(getState())[modal];
    dispatch({
      type: SET_MODAL_STATE,
      modal,
      isOpen
    });
  };

export const pushChangeHistory = ({ title, list }) => ({
  type: PUSH_CHANGE_HISTORY,
  change: { title, list }
});

export const clearChangeHistoryAction = {
  type: CLEAR_CHANGE_HISTORY
};

export const setProductQuote = productId => ({
  type: SET_QUOTE_CURRENT_PRODUCT,
  payload: productId
});

export const loadForms =
  (quoteNumber, currentStep, isBackGroundRefresh) =>
  async (dispatch, getState) => {
    if (isBackGroundRefresh) dispatch(refreshQuote());
    else dispatch(startLoading(quoteNumber, currentStep));
    try {
      // 1. get product details and check if supported]
      const { id, qtcCompatibility, effectiveDate, effectiveDateCeiling } =
        await getProduct({
          id: quoteNumber
        });
      dispatch(setProductQuote(id));
      dispatch(setQTCCompatibility(qtcCompatibility));

      if (!qtcCompatibility || qtcCompatibility === "none") {
        return dispatch(supportError());
      }

      // 2. parallel: get forms and quote data
      const [forms, quote] = await Promise.all([
        getForms({ productId: id }),
        getQuote({ id: quoteNumber })
      ]);

      if (forms.error) {
        console.error(forms.error);
        throw new Error(forms.message);
      }

      const { alc, username, fullname, licenseNumber } = pickEnvInfo(
        getState()
      );

      batch(() => {
        // 3. add config for dispatchers
        dispatch(
          updateConfig({
            // TODO: only run client-side rulesets (let the server do the rest)
            // ixlogic: `${AP.config.services.shared.url}${programID}/${
            //   AP.config.services.ixlogic.baseUrl
            // }`,
            services: {
              ixprofiler: {
                universal: `${AP.config.services.ixProfiler.baseUrl}/templates`
              }
            }
          })
        );
        // 4. load form into rex
        if (!isBackGroundRefresh) {
          dispatch(
            loadNewForm({
              forms: forms,
              id: QTC_FORM_ID,
              initialData: {
                LicenseNumber: licenseNumber,
                Name: fullname,
                ...quote.inputs,
                ...quote.flags
              },
              elementInjections: {
                PolicyRequest: {
                  locationid: alc,
                  username,
                  useragent: "QTC"
                },
                EffectiveDateRange: quote.effectiveDate,
                CreatedDate: quote.createDatetime
              }
            })
          );
        }

        // 5. save quote details
        dispatch(
          loadSuccess({
            data: quote,
            effectiveDate: {
              range: {
                floor: effectiveDate,
                ceiling: effectiveDateCeiling
              }
            }
          })
        );
      });
    } catch (err) {
      console.error(err);
      return dispatch(errorLoading(err.message));
    }
  };

export const userAddMortgagee = () => (dispatch, getState) => {
  const state = getState();
  const activeIndex = pickQTC(state).searchMortgagee.initialData.Position;
  const getModel = createModelProvider(state, {
    type: "JSON",
    id: QTC_FORM_ID,
    childId: "newMortgagee"
  });
  const { values } = getModel("mortgagee");
  const mortgageeInputs = {
    [`MortgageeNumber${activeIndex}`]: values["company"],
    [`Mortgagee${activeIndex}AddressCity`]: values["city"],
    [`Mortgagee${activeIndex}AddressLine1`]: values["address-line-one"],
    [`Mortgagee${activeIndex}AddressLine2`]: values["address-line-two"],
    [`Mortgagee${activeIndex}AddressState`]: values["state"],
    [`Mortgagee${activeIndex}AddressZip`]: values["zip"],
    [`Mortgagee${activeIndex}Clause1`]: values["clause1"],
    [`LoanNumber${activeIndex}`]: values["loan-number"],
    [`Mortgagee${activeIndex}AddedByUser`]: "100"
  };
  batch(() => {
    dispatch(
      externalModelUpdate({ data: mortgageeInputs, formId: QTC_FORM_ID })
    );
    dispatch(triggerEvent({ eventName: "mortgagee-update" }));
    dispatch(externalRunBinds());
    dispatch(saveQuote());
    dispatch(toggleMortgageeModal(false));
  });
};

export const saveMortgagee =
  ({
    Mortgagee0Name,
    Mortgagee0City,
    Mortgagee0AddressLine1,
    Mortgagee0AddressLine2,
    Mortgagee0State,
    Mortgagee0ZipCode
  }) =>
  (dispatch, getState) => {
    const activeIndex = pickQTC(getState()).searchMortgagee.initialData
      .Position;
    const mortgageeInputs = {
      [`MortgageeNumber${activeIndex}`]: Mortgagee0Name,
      [`Mortgagee${activeIndex}AddressCity`]: Mortgagee0City,
      [`Mortgagee${activeIndex}AddressLine1`]: Mortgagee0AddressLine1,
      [`Mortgagee${activeIndex}AddressLine2`]: Mortgagee0AddressLine2,
      [`Mortgagee${activeIndex}AddressState`]: Mortgagee0State,
      [`Mortgagee${activeIndex}AddressZip`]: Mortgagee0ZipCode,
      [`Mortgagee${activeIndex}AddedByUser`]: "200"
    };

    batch(() => {
      dispatch(
        externalModelUpdate({ data: mortgageeInputs, formId: QTC_FORM_ID })
      );
      dispatch(triggerEvent({ eventName: "mortgagee-update" }));
      dispatch(externalRunBinds());
      dispatch(saveQuote());
      dispatch(toggleMortgageeModal(false));
    });
  };

const mapRetrievedLosses = retrieved =>
  retrieved.reduce(
    (acc, loss) => ({
      ...acc,
      [`LossAmount${loss.id}Retrieved`]: loss.amount,
      [`LossType${loss.id}Retrieved`]: loss.type,
      [`LossDate${loss.id}Retrieved`]: loss.date,
      [`LossCatIndicator${loss.id}Retrieved`]: loss.catIndicator,
      [`LossDescription${loss.id}Retrieved`]: loss.description,
      [`LossPolicyNumber${loss.id}`]: loss.policyNumber,
      [`LossLocation${loss.id}`]: loss.location,
      [`LossCaseNumber${loss.id}`]: loss.caseNumber,
      [`LossCarrier${loss.id}`]: loss.carrier,
      [`LossClaimStatus${loss.id}`]: loss.claimStatus,
      [`LossMatchType${loss.id}`]: loss.matchType,
      [`LossAPlusCode${loss.id}`]: loss.aPlusCode
    }),
    {}
  );

let lastSave = 0;
export const saveQuote =
  ({
    saveOnlyIfChanges = false,
    currentStep,
    effectiveDate,
    expirationDate,
    runLossHistory
  } = {}) =>
  async (dispatch, getState) => {
    const thisSave = Date.now();
    lastSave = thisSave;
    const state = getState();
    const { loading, quote } = pickQTC(state);

    // Don't save if there is no quote. Quote is set to null when switching
    if (!quote) {
      console.error(
        "Tried to save the quote but the quote is no longer loaded."
      );
      return;
    }

    // In this case, we tried to save the quote without changing the step
    // but the step that was loaded is not in the quote yet.
    // This can happen if you change an input but the debounce for the save happens after you click next
    if (!currentStep && loading.step !== quote.inputs.CurrentStep) {
      // eslint-disable-next-line no-console
      console.warn(
        "Prevented a quote save because changing the step should be responsible for saving the quote and changing the input."
      );
    }

    const getModel = createModelProvider(state, {
      type: "JSON",
      onlyTerms: true,
      id: QTC_FORM_ID,
      index: 0
    });
    const { changed, values } = getModel("default");

    if (saveOnlyIfChanges && !changed.length) {
      // No saveQuote because there were no changes made.
      return;
    }

    // Iterate through the entire step's form to see if there are errors
    // and if all the ratingImpact fields are complete
    const step = currentStep || loading.step;
    const isCommercial = selectIsCommercial(state);
    const fusions = pick(
      allFusions,
      ...(isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS)
    );
    const derivedForm =
      step !== STEP_SELECT ? memoDeriveForm[step](state, { fusions }) : [];
    const hasErrors = flattenInputsIgnoreFusions(derivedForm).some(
      ({ validationErrors = [] }) => validationErrors.length > 0
    );
    if (hasErrors) {
      // eslint-disable-next-line no-console
      console.warn("Cancelled the save because some form fields were invalid");
      return;
    }

    dispatch({
      type: SEND_SAVE,
      step: currentStep,
      lossHistory: runLossHistory
    });

    const body = {
      ...pick(quote, [
        "carrier",
        "createUserId",
        "effectiveDate",
        "productType",
        "flags"
      ]),
      inputs: values
    };

    // If effective date and/or expiration is set, update in the request body
    if (effectiveDate) {
      body.effectiveDate = effectiveDate;
      body.inputs.EffectiveDateRange = effectiveDate;
    }
    if (expirationDate) {
      body.inputs.ExpirationDate = expirationDate;
    }

    // Set the CurrentStep input, this is necessary on the server but not in Rex
    body.inputs.CurrentStep = currentStep || quote.inputs.CurrentStep;

    try {
      const data = await putQuote({
        id: quote.quoteId,
        data: body,
        headers: {
          Eligibility: !!runLossHistory,
          LossAnalysis: !!runLossHistory
        }
      });
      batch(() => {
        const passToModel = {
          ...data.flags,
          ...pick(data.inputs, PERSISTED_TERMS)
        };
        dispatch(saveSuccess(data, { lossHistory: runLossHistory }));
        if (runLossHistory) {
          dispatch(
            externalModelUpdate({
              data: {
                ...passToModel,
                ...mapRetrievedLosses(data.losses.retrieved)
              },
              formId: QTC_FORM_ID
            })
          );
        }
        // If some flags or terms (listed in PERSISTED_TERMS) came back from the
        // server different from what we have let's update Rex. For example the
        // "ReviewLock" when losses are triggered
        else if (
          Object.entries(passToModel).some(
            ([key, value]) =>
              quote.flags[key] !== value && quote.inputs[key] !== value
          )
        ) {
          dispatch(
            externalModelUpdate({
              data: passToModel,
              formId: QTC_FORM_ID
            })
          );
        }
      });
    } catch (err) {
      if (thisSave === lastSave) {
        dispatch({
          type: SAVE_ERROR,
          message: err.message || "Failed to save",
          lossHistory: runLossHistory
        });
      }
    }
  };

export const setRCEModalData = payload => dispatch =>
  dispatch({ type: SET_RCE_MODAL_DATA, payload });

export const setEffectiveDateValidation = payload => dispatch =>
  dispatch({ type: SET_EFFECTIVE_DATE_VALIDATION, payload });

export const updateQuote = updatedQuote => async dispatch => {
  dispatch(
    externalModelUpdate({
      data: updatedQuote,
      formId: QTC_FORM_ID
    })
  );
  dispatch(saveQuote());
};

export const processPayment = () => async (dispatch, getState) => {
  const thisSave = Date.now();
  lastSave = thisSave;
  const state = getState();
  const isCommercial = selectIsCommercial(state);
  const { quote, ccFormData, achFormData, checkFormData } = pickQTC(state);
  const { PaymentPlanType } = selectQuoteInputs(state);

  const getModel = createModelProvider(state, {
    type: "JSON",
    onlyTerms: true,
    id: QTC_FORM_ID,
    index: 0
  });
  const { values } = getModel("default");

  const { PaymentMethod } = values;

  let request = null;

  // Credit Card
  if (PaymentMethod === "400") {
    request = pick(
      ccFormData,
      "payorFirstName",
      "payorLastName",
      "billingAddressLine1",
      "billingAddressZipCode",
      "method",
      "accountNumber",
      "creditCardType",
      "creditCardExpirationMonth",
      "creditCardExpirationYear"
    );
  }

  // ACH
  if (PaymentMethod === "300") {
    request = pick(
      achFormData,
      "accountType",
      "bankName",
      "billingAddressLine1",
      "billingAddressCity",
      "billingAddressState",
      "billingAddressZipCode",
      "method",
      "payorFirstName",
      "payorLastName",
      "accountNumber",
      "routingNumber"
    );

    request.easyPayEnrollmentAcknowledgement =
      values.EasyPayEnrollmentRequested;
    request.easyPayDraftDay = values.EasyPayDraftDay;
    request.insuredEmailAddressBilling = values.InsuredEmailAddressBilling;
  }

  // Check
  if (PaymentMethod === "200" && PaymentPlanType !== "invoice") {
    request = pick(checkFormData, "accountHolder", "checkNumber");
  }

  dispatch({ type: SEND_SAVE, bind: true });

  const body = {
    inputs: values
  };

  if (request) {
    body.payment = { request };
  }

  try {
    const data = await patchQuote({
      id: quote.quoteId,
      data: body,
      headers: {
        Submission: true
      }
    });

    // Error handling
    const { stages } = data.carriers[0];

    if (
      stages.submission.errors &&
      stages.submission.errors.length > 0 &&
      stages.submission.errors[0].message
    ) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          error: "payment_error",
          title: "Your payment has failed",
          message: stages.submission.errors[0].message,
          errorCode: stages.submission.errors[0].type
        }
      });
    } else if (!stages.submission.complete) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          error: "payment_error",
          title: "Something went wrong",
          message: "We weren't able to bind your quote. Please try again.",
          errorCode: "An unknown error occurred."
        }
      });
    } else {
      dispatch(saveSuccess(data, { bind: true }));
      if (!isCommercial) {
        AP.launchSignDocumentModal(shapeDataForLaunchSignDocumentModal(data));
      } else {
        dispatch({
          type: PAYMENT_SUCCESS
        });
      }
    }
  } catch (err) {
    console.error(err);
    if (thisSave === lastSave) {
      dispatch({ type: SAVE_ERROR, message: err.message || "Failed to save" });
    }
  }
};

export const updateCcFormData = input => ({
  type: UPDATE_CC_FORM,
  input
});
export const updateAchFormData = input => ({
  type: UPDATE_ACH_FORM,
  input
});
export const updateCheckFormData = input => ({
  type: UPDATE_CHECK_FORM,
  input
});

// Search Mortgagee Actions
export const toggleMortgageeModal = (open, initialData = {}) => ({
  type: TOGGLE_SEARCH_MORTGAGEE_MODAL,
  payload: { open, initialData }
});

export const effectiveDateOutOfRange = () => async (dispatch, getState) => {
  const { quoteId } = selectQTCQuote(getState());
  dispatch(toggleQTCModal({ modal: "reloadQuote" }));
  await dispatch(loadForms(quoteId, STEP_QUOTE));
  dispatch(
    saveQuote({
      currentStep: STEP_QUOTE
    })
  );
};

export const loadStep =
  (quoteNumber, step, forceLoad = false) =>
  async (dispatch, getState) => {
    const { loading, productSelection } = pickQTC(getState());

    const firstLoad = loading.quoteNumber !== quoteNumber;
    const newStep = loading.step !== step;
    if (firstLoad || forceLoad) {
      await dispatch(loadForms(quoteNumber, step));
    }
    const { quote } = pickQTC(getState());
    batch(() => {
      if (newStep) {
        // back to quote step
        if (loading.step && step === STEP_QUOTE) {
          dispatch({
            type: LOSS_HISTORY.RESET
          });
          dispatch(clearProductSelect());
        }
        // loading select step
        // default the product selection for single carriers
        if (
          step === STEP_SELECT &&
          quote.carriers &&
          (!productSelection.byUser ||
            quoteNumber !== productSelection.quoteNumber)
        ) {
          dispatch(
            productChange({
              byUser: false,
              id: quote.carriers[0].id,
              type: SELECT_ALL,
              quoteNumber
            })
          );
        }
      }
      if (newStep || firstLoad) {
        dispatch(
          saveQuote(
            step
              ? {
                  currentStep: step,
                  saveOnlyIfChanges:
                    firstLoad && step === quote.inputs.CurrentStep
                }
              : undefined
          )
        );
      }
    });
  };

const initState = {
  quote: null,
  loading: {
    state: null,
    quoteNumber: "",
    message: null,
    step: null
  },
  saving: {
    sending: false,
    binding: false,
    lastSaved: null,
    error: null
  },
  payment: {
    title: null,
    message: null,
    error: null,
    errorType: null,
    bound: false
  },
  productSelection: {
    id: null,
    byUser: false,
    quoteNumber: null
  },
  modals: {
    copyQuote: false,
    reloadQuote: false,
    replacementCost: false,
    paymentError: false,
    paymentSuccess: false
  },
  replacementCost: {
    error: null,
    replacementCostData: {}
  },
  effectiveDate: {
    error: null,
    range: {
      floor: null,
      ceiling: null
    }
  },
  changeHistory: {
    showModal: false,
    changes: []
  },
  currentProduct: {
    id: null
  },
  currentTask: {
    tasks: {}
  },
  lossHistoryReport: {
    fetch: FETCH.STATUS.INITIAL,
    losses: {}
  },
  searchMortgagee: {
    modalOpen: false,
    initialData: {}
  },
  achFormData: {
    accountType: null,
    bankName: null,
    routingNumber: null,
    accountNumber: null,
    payorFirstName: null,
    payorLastName: null,
    billingAddressLine1: null,
    billingAddressLine2: null,
    billingAddressCity: null,
    billingAddressState: null,
    billingAddressZipCode: null,
    method: "ach"
  },
  ccFormData: {
    payorFirstName: null,
    payorLastName: null,
    accountNumber: null,
    creditCardType: null,
    billingAddressLine1: null,
    billingAddressZipCode: null,
    creditCardExpirationMonth: null,
    creditCardExpirationYear: null,
    method: "creditcard"
  },
  checkFormData: {
    accountHolder: null,
    checkNumber: null,
    method: "check"
  }
};

export const quoteTransactionComponent = produce((draft, action) => {
  switch (action.type) {
    case START_LOADING:
      // In Immer, if you don't touch the draft you can return a new state object.
      // This is to reset our state when starting a new quote.
      // https://immerjs.github.io/immer/docs/return
      return {
        ...initState,
        loading: {
          state: START_LOADING,
          quoteNumber: action.quoteNumber,
          step: action.step,
          message: null
        }
      };
    case REFRESH_QUOTE:
      draft.loading = {
        ...draft.loading,
        isRefreshing: true
      };
      return;
    case LOAD_ERROR:
      draft.loading = {
        ...draft.loading,
        state: LOAD_ERROR,
        message: action.message,
        isRefreshing: false
      };
      return;
    case SUPPORT_ERROR:
      draft.loading.state = SUPPORT_ERROR;
      draft.loading.message = null;
      return;
    case LOAD_SUCCESS:
      draft.loading.state = LOAD_SUCCESS;
      draft.loading.message = null;
      draft.loading.isRefreshing = false;
      draft.quote = action.data;
      draft.effectiveDate.range = {
        ...draft.effectiveDate.range,
        ...action.effectiveDate.range
      };
      return;
    case SET_QTC_COMPATIBILITY:
      draft.qtcCompatibility = action.payload;
      return;
    case SEND_SAVE:
      draft.saving.sending = true;
      draft.saving.error = null;
      if (action.step) {
        draft.loading.step = action.step;
      }
      if (action.bind) {
        draft.saving.binding = true;
      }
      if (action.lossHistory) {
        draft.lossHistoryReport.fetch = FETCH.STATUS.BUSY;
      }
      return;
    case SAVE_SUCCESS:
      if (action.data) {
        draft.quote = action.data;
      }
      if (action.inputs) {
        draft.quote.inputs = { ...draft.quote.inputs, ...action.inputs };
      }
      if (action.rateResults) {
        // If we have updated rateResults (from step 2: Select) for the selected Carrier (index 0)
        if (draft.quote.carriers[0]) {
          draft.quote.carriers[0].rateResults = action.rateResults;
        }
      }
      draft.saving.sending = false;
      draft.saving.lastSaved = Date.now();
      draft.saving.error = null;
      if (action.lossHistory) {
        draft.lossHistoryReport.fetch = FETCH.STATUS.DONE;
      }
      if (action.bind) {
        draft.saving.binding = false;
        draft.payment.bound = true;
      }
      return;
    case SAVE_ERROR:
      draft.saving.sending = false;
      draft.saving.error = action.message;
      if (action.lossHistory) {
        draft.lossHistoryReport.fetch = FETCH.STATUS.ERROR;
      }
      return;
    case PAYMENT_ERROR:
      draft.saving.sending = false;
      draft.payment.error = action.payload.error;
      draft.payment.title = action.payload.title;
      draft.payment.message = action.payload.message;
      draft.payment.errorCode = action.payload.errorCode;
      draft.modals.paymentError = true;
      draft.payment.bound = false;
      draft.saving.binding = false;
      return;
    case PAYMENT_SUCCESS:
      draft.modals.paymentSuccess = true;
      return;
    case CHOOSE_PRODUCT:
      draft.productSelection = action.payload;
      return;
    case SET_MODAL_STATE:
      draft.modals[action.modal] = action.isOpen;
      return;
    case SET_RCE_MODAL_DATA:
      draft.replacementCost = action.payload;
      return;
    case PUSH_CHANGE_HISTORY:
      draft.changeHistory.showModal = true;
      draft.changeHistory.changes = [
        ...draft.changeHistory.changes,
        action.change
      ];
      return;
    case CLEAR_CHANGE_HISTORY:
      draft.changeHistory.showModal = false;
      draft.changeHistory.changes = [];
      return;
    case SET_EFFECTIVE_DATE_VALIDATION:
      draft.effectiveDate.error = action.payload;
      return;
    case LOSS_HISTORY.RESET:
      draft.lossHistoryReport = {
        fetch: FETCH.STATUS.INITIAL
      };
      return;
    case SET_QUOTE_CURRENT_PRODUCT:
      draft.currentProduct = action.payload;
      return;
    case UPDATE_ACH_FORM:
      draft.achFormData[action.input?.id] = action.input?.value;
      return;
    case UPDATE_CC_FORM:
      draft.ccFormData[action.input?.id] = action.input?.value;
      return;
    case UPDATE_CHECK_FORM:
      draft.checkFormData[action.input?.id] = action.input?.value;
      return;
    case TOGGLE_SEARCH_MORTGAGEE_MODAL:
      draft.searchMortgagee.initialData = action.payload.initialData;
      draft.searchMortgagee.modalOpen = action.payload.open;
      return;
  }
}, initState);
