import {
  ACCOUNT_ACTIVITY_VALUES,
  CURRENT_STATEMENT_SUMMARY,
  MORTGAGEE_INFORMATION,
  PAYMENT_ACTIVITY_VALUES,
  PAYMENT_INFORMATION
} from "policyViewDataConstants";
import { printDate } from "../../../utils/dates";
import addPolicyValues from "./add-policy-values";
import formatSignedCurrencyVal from "./format-signed-currency-val";

const createAccountingDescription = (
  { type, PaymentMethod, Payment1Method, Payment1AccountNumber },
  display
) => {
  const typeDescription = ACCOUNT_ACTIVITY_VALUES[type];
  return (type === "PAYMENT" || type === "REVERSE_PAYMENT") &&
    (PaymentMethod || Payment1Method)
    ? [
        typeDescription,
        " - ",
        PAYMENT_ACTIVITY_VALUES[PaymentMethod || Payment1Method],
        Payment1AccountNumber && " *",
        Payment1AccountNumber
      ]
        .filter(i => i)
        .join("")
    : display;
};

const parseBilling = data => ({
  currentStatementSummary: {
    data: addPolicyValues(CURRENT_STATEMENT_SUMMARY, data),
    name: "Current Statement Summary"
  },
  paymentInformation: {
    data: addPolicyValues(PAYMENT_INFORMATION, data),
    name: "Payment Information"
  },
  accountActivity: {
    data: data.accounting.Ledger
      ? data.accounting.Ledger.LineItems.map(item => ({
          appliedDate: printDate(item.appliedDate),
          description: createAccountingDescription(
            item,
            ACCOUNT_ACTIVITY_VALUES[item.type] || "---"
          ),
          amount: formatSignedCurrencyVal(item.value),
          postedDate: printDate(item.timestamp),
          reference: item.Reference || "---"
        }))
      : [],

    name: "Account Activity"
  },
  installments: {
    data: data.accounting.PaymentPlan
      ? data.accounting.PaymentPlan.Installments.map(item => ({
          ...item,
          dueDate: printDate(item.dueDate),
          invoiceDate: printDate(item.invoiceDate)
        }))
      : []
  },
  easyPay: {
    data:
      data.accounting.EasyPay && data.accounting.EasyPay.easyPayAccount
        ? {
            lastFourDigits:
              data.accounting.EasyPay.easyPayAccount.lastFourDigits,
            paymentMethod: data.accounting.EasyPay.easyPayAccount.paymentMethod
          }
        : {},
    name: "Saved EasyPay Account"
  },
  mortgageeInformation: {
    data: addPolicyValues(MORTGAGEE_INFORMATION, data),
    name: "Mortgagee Information"
  }
});

export default parseBilling;
