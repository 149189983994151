import $ from "jquery";
import AP from "../legacy/global";

export default (insightPolicyId, note) =>
  new Promise((resolve, reject) => {
    const data = `<PolicyChangeSet schemaVersion="2.1" username="${
      AP.u
    }" description="Adding a note">
                            <Note>
                            <Content><![CDATA[${note}]]></Content>
                        </Note>
                        </PolicyChangeSet>`;

    $.ajax({
      type: "POST",
      url: `${AP.config.services.shared.url}${AP.programID}/${
        AP.config.services.pxcentral.baseUrl
      }policies/${insightPolicyId}`,
      data,
      headers: {
        Authorization: `Basic ${AP.config.application.mxAuth.authHash}`,
        "X-Authorization": `Basic ${AP.config.application.mxAuth.authHash}`,
        "Content-Type":
          "application/xml; charset=UTF-8; schema=policychangeset.2.1",
        Accept: "application/xml"
      },
      success: resolve,
      error: reject
    });
  });
