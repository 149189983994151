import moment from "moment";
import { includes, map, find } from "lodash";
import * as properties from "../promises/properties-old";
import { postQuote, getProducts } from "../promises/quotes";
import { fromStorage } from "../utils/store-persist";
import { pickEnvInfo, pickQuoteQuickStart } from "./selectors";

export const getDefaultCoverageStartDate = () =>
  moment
    .utc()
    .add(ENV_INFO.qqsCoverageStartDaysFromTodayDefault, "d")
    .startOf("day")
    .valueOf();

export const INSURANCE_TYPE_KEY = "insurance-type";

export const generateDefaults = () => ({
  address: null,
  coverageStartDate: null,
  messages: [],
  insuranceType: fromStorage(INSURANCE_TYPE_KEY, "100"),
  occupancyType: ENV_INFO.qqsOccupancyOptions[0].value,
  products: [],
  productsLoading: false,
  commercial: {},
  selectedProductId: "",
  structureType: ENV_INFO.qqsStructureOptions[0].value
});

const RESET = "qqs/RESET";
const SET_ADDRESS = "qqs/SET_ADDRESS";
const PROPERTY_DETAILS = "qqs/PROPERTY_DETAILS";
const PRODUCTS_LOADING = "qqs/PRODUCTS_LOADING";
const PRODUCTS_SUCCESS = "qqs/PRODUCTS_SUCCESS";
const PRODUCTS_ERROR = "qqs/PRODUCTS_ERROR";
const COMMERCIAL = "qqs/COMMERCIAL";
const PRODUCT_SELECTION = "qqs/PRODUCT_SELECTION";
const CREATING_QUOTE = "qqs/CREATING_QUOTE";
const CREATE_QUOTE_SUCCESS = "qqs/CREATE_QUOTE_SUCCESS";
const CREATE_QUOTE_ERROR = "qqs/CREATE_QUOTE_ERROR";
const VALIDATION_ERROR = "qqs/VALIDATION_ERROR";

let findProductsRequestCount = 0;
const findProductsRequestNumber = () => {
  findProductsRequestCount += 1;
  return findProductsRequestCount;
};

export const reset = () => {
  properties.clearProperty();
  findProductsRequestNumber();
  return {
    type: RESET,
    payload: {
      ...generateDefaults(),
      creatingQuote: false
    }
  };
};

const setAddress = address => ({
  type: SET_ADDRESS,
  payload: { address }
});

const propertyDetails = details => ({
  type: PROPERTY_DETAILS,
  payload: details
});

const setProductsLoading = productsLoading => ({
  type: PRODUCTS_LOADING,
  payload: { productsLoading }
});

const productsSuccess = (products, selectedProductId) => ({
  type: PRODUCTS_SUCCESS,
  payload: { products, selectedProductId }
});

const productsError = errorMsg => ({
  type: PRODUCTS_ERROR,
  payload: {
    message: {
      type: "warning",
      title: "Products failed to load",
      error: errorMsg
    }
  }
});

const changeCommercial = commercial => ({
  type: COMMERCIAL,
  payload: commercial
});
export const updateCommercial = updates => (dispatch, getState) => {
  dispatch(changeCommercial(updates));
  const state = getState();
  const {
    commercial,
    coverageStartDate,
    validationError
  } = pickQuoteQuickStart(state);
  if (validationError) {
    runCommercialValidations(
      commercial,
      coverageStartDate,
      validationError,
      dispatch
    );
  }
};

const productSelection = selectedProductId => ({
  type: PRODUCT_SELECTION,
  payload: { selectedProductId }
});

const setCreatingQuote = creatingQuote => ({
  type: CREATING_QUOTE,
  payload: { creatingQuote }
});

const createQuoteSuccess = () => ({
  type: CREATE_QUOTE_SUCCESS
});

const createQuoteError = errorMsg => ({
  type: CREATE_QUOTE_ERROR,
  payload: {
    message: {
      type: "danger",
      title: "The quote failed to be created.",
      error: errorMsg
    }
  }
});

const setValidationError = error => ({
  type: VALIDATION_ERROR,
  payload: {
    error
  }
});

const findProductsCurrentNumber = num => num === findProductsRequestCount;
const findProducts = (dispatch, getState) => {
  const {
    insuranceType,
    address,
    structureType,
    occupancyType,
    coverageStartDate
  } = pickQuoteQuickStart(getState());
  const request = {
    propertyState: address ? address.state : "",
    structureType: insuranceType === "200" ? "999" : structureType,
    propertyOccupancy: occupancyType,
    effectiveDatePolicyTerm: coverageStartDate
  };
  dispatch(setProductsLoading(true));
  const currentRequestNum = findProductsRequestNumber();
  return getProducts(request)
    .then(nextProducts => {
      if (!findProductsCurrentNumber(currentRequestNum)) {
        // A newer request was made.
        return;
      }
      const { products: prevProducts, selectedProductId } = pickQuoteQuickStart(
        getState()
      );
      const nextProductIds = map(nextProducts, "id");
      // if the next recommended product is the same as the previous
      // and the next products contain the current selected product
      // keep the current selected product
      const nextRecommendedId = nextProducts.length ? nextProducts[0].id : "";
      const prevRecommendedId = prevProducts.length ? prevProducts[0].id : "";
      const nextSelectedProductId =
        nextRecommendedId === prevRecommendedId &&
        includes(nextProductIds, selectedProductId)
          ? selectedProductId
          : nextRecommendedId;
      dispatch(productsSuccess(nextProducts, nextSelectedProductId));
      properties.addPropertyWithProductId(address, nextSelectedProductId);
    })
    .catch(error => {
      if (!findProductsCurrentNumber(currentRequestNum)) {
        // Do not show an error message since a new reqest was made.
        return;
      }
      if (error instanceof Error) {
        console.error(error);
      }
      dispatch(productsError(error.message));
    });
};

export const updateAddress = address => (dispatch, getState) => {
  const state = getState();
  const { coverageStartDate } = pickQuoteQuickStart(state);
  properties.clearProperty();
  dispatch(setAddress(address));
  return address && coverageStartDate
    ? findProducts(dispatch, getState)
    : Promise.resolve();
};

export const updatePropertyDetails = details => (dispatch, getState) => {
  dispatch(
    propertyDetails(
      coverageStartDate
        ? details
        : {
            ...details,
            products: [],
            selectedProductId: ""
          }
    )
  );
  const state = getState();
  const { address, coverageStartDate } = pickQuoteQuickStart(state);
  return coverageStartDate && address
    ? findProducts(dispatch, getState)
    : Promise.resolve();
};

export const updateProductSelection = selectedProductId => (
  dispatch,
  getState
) => {
  const { address } = pickQuoteQuickStart(getState());
  properties.addPropertyWithProductId(address, selectedProductId);
  dispatch(productSelection(selectedProductId));
};

export const startQuote = () => (dispatch, getState) => {
  const state = getState();
  const { alc } = pickEnvInfo(state);
  const {
    address,
    insuranceType,
    coverageStartDate,
    structureType,
    occupancyType,
    products,
    selectedProductId,
    commercial,
    validationError
  } = pickQuoteQuickStart(state);
  const product = find(products, p => p.id === selectedProductId);
  if (!address || !product) {
    return Promise.resolve();
  }

  if (insuranceType === "200") {
    if (
      runCommercialValidations(
        commercial,
        coverageStartDate,
        validationError,
        dispatch
      )
    ) {
      return Promise.resolve();
    }
  }

  dispatch(setCreatingQuote(true));

  return properties
    .getPropertyByProductId(selectedProductId)
    .then(({ url } = {}) => (url ? properties.get(url) : undefined))
    .then(({ id, address: vAddr, details = {} } = {}) =>
      vAddr
        ? {
            address: vAddr,
            inputs: {
              PropertyId: id,
              PropertyStreetNumber: vAddr.propertyStreetNumber,
              PropertyStreetName: vAddr.propertyStreetName,
              PropertyAddressLine2: vAddr.propertyAddressLine2,
              PropertyCity: vAddr.propertyCity,
              PropertyState: vAddr.propertyState,
              PropertyZipCode: vAddr.propertyZipCode,
              PropertyZipCodePlusFour: vAddr.propertyZipCodePlusFour,
              Latitude: vAddr.latitude,
              Longitude: vAddr.longitude,
              MailingEqualPropertyAddress: "100",
              InsuredMailingAddressCity: vAddr.propertyCity,
              InsuredMailingAddressCountry: "USA",
              InsuredMailingAddressLine1: `${
                vAddr.propertyStreetNumber || ""
              } ${vAddr.propertyStreetName || ""}`.trim(),
              InsuredMailingAddressLine2: vAddr.propertyAddressLine2,
              InsuredMailingAddressState: vAddr.propertyState,
              InsuredMailingAddressZip: vAddr.propertyZipCode,
              ...details
            }
          }
        : {
            address: {
              propertyStreetNumber: address.streetNumber || "",
              propertyStreetName: address.route || "",
              propertyAddressLine2: address.subpremise || "",
              propertyCity: address.city || "",
              propertyState: address.state || "",
              propertyZipCode: address.postalCode || "",
              propertyZipCodePlusFour: address.postalCodeSuffix || "",
              latitude: address.lat || "",
              longitude: address.lng || ""
            },
            inputs: {
              AddressValidated: "200",
              PropertyStreetNumber: address.streetNumber || "",
              PropertyStreetName: address.route || "",
              PropertyAddressLine2: address.subpremise || "",
              PropertyCity: address.city || "",
              PropertyState: address.state || "",
              PropertyZipCode: address.postalCode || "",
              PropertyZipCodePlusFour: address.postalCodeSuffix || "",
              Latitude: address.lat,
              Longitude: address.lng
            }
          }
    )
    .then(({ address, inputs }) => {
      const {
        incumbentCarrier = "",
        incumbentPremium = "",
        incumbentYears = ""
      } = commercial;
      const incumbentNA = incumbentCarrier.toUpperCase() === "N/A";
      const quote = {
        carrier: product.carrierId,
        productType: product.productType,
        effectiveDate: moment.utc(coverageStartDate).format("YYYY-MM-DD"),
        referenceId: product.id,
        inputs: {
          InsuranceType: insuranceType,
          PropertyOccupancy: occupancyType,
          StructureType: structureType,
          AgencyLocationCode: alc,
          QuoteOriginationSystem: "Agent Portal",
          QuoteOriginationSystemVendor: "Insight",
          CompetitivePremiumCarrier: incumbentNA ? "N/A" : incumbentCarrier,
          CompetitivePremium: incumbentNA ? 0 : incumbentPremium,
          CompetitivePremiumYearsWithCarrier: incumbentNA ? 0 : incumbentYears,
          ...inputs
        }
      };

      // There is an exception for StructureType = "500",
      // it is actually a "manufactured home" ConstructionType
      if (structureType === "500") {
        quote.inputs.StructureType = "100";
        quote.inputs.ConstructionType = "500";
      }

      if (insuranceType === "200") {
        delete quote.inputs.PropertyOccupancy;
        delete quote.inputs.StructureType;
        delete quote.inputs.ConstructionType;
        addCommercialValues(
          commercial,
          address,
          coverageStartDate,
          address,
          quote.inputs
        );
      }
      return postQuote({ data: quote });
    })
    .then(results => {
      dispatch(createQuoteSuccess());
      return results;
    })
    .catch(error => {
      if (error instanceof Error) {
        console.error(error);
      }
      dispatch(createQuoteError(error.message));
    });
};

export const quoteQuickStart = (
  state = {
    ...generateDefaults(),
    creatingQuote: false
  },
  { type, payload }
) => {
  switch (type) {
    case PRODUCTS_SUCCESS: {
      return { ...state, ...payload, productsLoading: false };
    }
    case PRODUCTS_ERROR: {
      return {
        ...state,
        products: [],
        selectedProductId: "",
        productsLoading: false,
        messages: [...state.messages, payload.message]
      };
    }
    case CREATE_QUOTE_ERROR: {
      return {
        ...state,
        creatingQuote: false,
        messages: [...state.messages, payload.message]
      };
    }
    case CREATE_QUOTE_SUCCESS: {
      return state;
    }
    case COMMERCIAL: {
      return {
        ...state,
        commercial: {
          ...state.commercial,
          ...payload
        }
      };
    }
    case VALIDATION_ERROR: {
      return {
        ...state,
        validationError: payload.error
      };
    }
    case RESET:
    case SET_ADDRESS:
    case PROPERTY_DETAILS:
    case PRODUCTS_LOADING:
    case PRODUCT_SELECTION:
    case CREATING_QUOTE: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

function runCommercialValidations(
  { expirationDate },
  coverageStartDate,
  validationError,
  dispatch
) {
  if (
    expirationDate &&
    (expirationDate < coverageStartDate ||
      expirationDate > moment(coverageStartDate).add(1, "y").valueOf())
  ) {
    dispatch(
      setValidationError(
        "Expiration date must be within one year of the coverage start date."
      )
    );
    return true;
  }
  if (validationError) {
    dispatch(setValidationError(null));
  }
  return false;
}

function addCommercialValues(
  { expirationDate, businessName },
  primaryAddress,
  coverageStartDate,
  address,
  inputs
) {
  inputs.ExpirationDate = expirationDate
    ? moment.utc(expirationDate).format("YYYY-MM-DD")
    : moment.utc(coverageStartDate).add(1, "y").format("YYYY-MM-DD");
  inputs.PropertyAddressLocation1 = primaryAddress.display;
  inputs.PropertyStreetNumberLocation1 = address.propertyStreetNumber;
  inputs.PropertyStreetNameLocation1 = address.propertyStreetName;
  inputs.PropertyAddressLine2Location1 = address.propertyAddressLine2;
  inputs.PropertyCityLocation1 = address.propertyCity;
  inputs.PropertyStateLocation1 = address.propertyState;
  inputs.PropertyZipCodeLocation1 = address.propertyZipCode;
  inputs.PropertyZipCodePlusFourLocation1 = address.propertyZipCodePlusFour;
  inputs.InsuredName = businessName;
  inputs.InsuredByCorporation = "100";
  inputs.Location1PremisesNumber = "1";
  inputs.Location1BuildingNumber = "1";
}
