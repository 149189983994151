import $ from "jquery";
import parseOrg from "../utils/xml-parsers/organizations";

export default ({ alc, agentToken, config }) =>
  new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.services.ixdirectory.proxy}${config.services.ixdirectory.baseUrl}organizations/${alc}`,
      type: "GET",
      dataType: "text",
      beforeSend: xhr => {
        agentToken
          ? xhr.setRequestHeader("Authorization", `AgentToken ${agentToken}`)
          : xhr.setRequestHeader(
              "Authorization",
              `Basic ${config.application.mxAuth.authHash}`
            );
      }
    })
      .done((xml, status) => {
        if (status === "success") {
          resolve(parseOrg(xml));
          return;
        }
        reject();
      })
      .fail(() => {
        reject();
      });
  });
