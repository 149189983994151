import htmlparser from "htmlparser2";
import PolicyHandler from "./policy-handler";

const extractNotes = xml => {
  let count = 0;
  const notesContent = {};
  const returnXML = xml.replace(
    /<RelatedItems>([^]+?)<\/RelatedItems>/,
    relatedMatch =>
      relatedMatch.replace(/<Notes>([^]+?)<\/Notes>/, notesMatch =>
        notesMatch.replace(/<Content>([^<]*)<\/Content>/g, (m, content) => {
          count += 1;
          notesContent[count] = content;
          return `<ContentId>${count}</ContentId>`;
        })
      )
  );
  return [returnXML, notesContent];
};

const reinjectNotes = (parsed, notesContent) => {
  return parsed.relatedItems && parsed.relatedItems.Notes
    ? {
        ...parsed,
        relatedItems: {
          ...parsed.relatedItems,
          Notes: parsed.relatedItems.Notes.map(({ ContentId, ...note }) => ({
            ...note,
            Content: notesContent[ContentId]
          }))
        }
      }
    : parsed;
};

export default (xml, { strict = false } = {}) => {
  try {
    // Take notes out before parsing because we need to
    // maintain newline characters
    const [policyXML, notesContent] = extractNotes(xml);
    const handler = new PolicyHandler(strict);
    new htmlparser.Parser(handler, {
      xmlMode: true,
      decodeEntities: false,
      normalizeWhitespace: false
    }).end(policyXML);
    return {
      ...reinjectNotes(handler.result, notesContent),
      rawPolicyXML: xml
    };
  } catch (e) {
    console.error(e);
    if (e) {
      throw e;
    } else {
      throw new Error("That doesn't appear to be a valid XForms document.");
    }
  }
};
