import moment from "moment";

const isFormatted = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;
const formatDate = date =>
  isFormatted.test(date) ? date : moment(date).format("MM/DD/YYYY");

const parsePolicyActivity = (eventHistory, revisionHistory) =>
  [
    ...eventHistory
      .filter(event => event.type !== "sendToPrintVendor")
      .map(record => ({
        ...record,
        historyType: "Transaction"
      })),
    ...revisionHistory
      .filter(revision => revision.type === "MortgageeChanges")
      .map(record => ({
        ...record,
        historyType: "Request"
      }))
  ]
    .sort(
      (a, b) => moment(b.timeStamp).valueOf() - moment(a.timeStamp).valueOf()
    )
    .map(({ historyType, type, EffectiveDate, timeStamp }) => ({
      timeStamp,
      type: historyType,
      description: type || "---",
      effectiveDate: EffectiveDate ? formatDate(EffectiveDate) : "---",
      postedDate: moment(timeStamp).format("MM/DD/YYYY [at] hh:mma")
    }));

export default parsePolicyActivity;
