import React from "react";
import PropTypes from "prop-types";

// this component renders and conditionally highlights all or part of a string
export default class HighlightableText extends React.Component {
  static propTypes = {
    textToMatch: PropTypes.string,
    fullText: PropTypes.string,
    highlightClass: PropTypes.string.isRequired
  };

  render() {
    const { textToMatch, fullText, highlightClass } = this.props;
    if (fullText && textToMatch) {
      const matchIdx = fullText
        .toLowerCase()
        .indexOf(textToMatch.toLowerCase());
      const beforeText = fullText.slice(0, matchIdx);
      const highlightedText = fullText.slice(
        matchIdx,
        matchIdx + textToMatch.length
      );
      const afterText = fullText.slice(
        matchIdx + textToMatch.length,
        fullText.length
      );
      return matchIdx >= 0 ? (
        <span>
          {beforeText && <span>{beforeText}</span>}
          <span className={highlightClass}>{highlightedText}</span>
          {afterText && <span>{afterText}</span>}
        </span>
      ) : (
        <span>
          <span>{fullText}</span>
        </span>
      );
    } else {
      return (
        <span>
          <span>{fullText}</span>
        </span>
      );
    }
  }
}
