import {
  FormControl,
  ControlLabel,
  FormGroup,
  Col,
  Row,
  HelpBlock
} from "@icg360/ui-toolkit";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCheckFormData } from "../../../store/quote-transaction-component";
import { selectCheckFormData } from "../../../store/selectors/qtc-selectors";
import { isCheckNumberValid } from "../../../utils/validation";

export const CheckForm = () => {
  const dispatch = useDispatch();
  const updateCheckForm = input => dispatch(updateCheckFormData(input));

  const checkFormData = useSelector(selectCheckFormData);
  const { accountHolder, checkNumber } = checkFormData;

  const [checkNumberState, setCheckNumberState] = useState(checkNumber);

  return (
    <div className="payment-form-container">
      <Row>
        <Col lg={6}>
          <ControlLabel className="inputLabel" htmlFor="accountHolder">
            Account Holder
          </ControlLabel>
          <FormGroup>
            <FormControl
              id="accountHolder"
              type="text"
              value={accountHolder || ""}
              onChange={e => {
                const { value } = e.target;
                updateCheckForm({
                  id: "accountHolder",
                  value: value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <ControlLabel className="inputLabel" htmlFor="checkNumber">
            Check Number
          </ControlLabel>
          <FormGroup
            validationState={
              checkNumberState && !isCheckNumberValid(checkNumberState)
                ? "error"
                : null
            }
          >
            <FormControl
              id="checkNumber"
              type="text"
              value={checkNumberState || ""}
              onChange={e => {
                const { value } = e.target;

                setCheckNumberState(value);
                updateCheckForm({
                  id: "checkNumber",
                  value: isCheckNumberValid(value) ? value : null
                });
              }}
            />
            {checkNumberState && !isCheckNumberValid(checkNumberState) && (
              <HelpBlock>Please enter numbers only</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
