import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@icg360/ui-toolkit";
import "./clear-form-control.scss";

const ClearFormControl = ({
  children,
  className = "",
  dataBdd,
  onClear,
  size = "large",
  inputRef,
  shouldHideButton
}) => {
  const handleClear = event => {
    onClear(event);
    inputRef?.current?.focus();
  };

  return (
    <div className={`clear-form-container ${size} ${className}`}>
      {children}
      {!shouldHideButton && (
        <button
          data-bdd={dataBdd}
          className="clear-form-control"
          onClick={handleClear}
          type="button"
        >
          <Icon name="clear" size="sm" />
        </button>
      )}
    </div>
  );
};

ClearFormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataBdd: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["medium", "large"]),
  inputRef: PropTypes.shape({
    current: PropTypes.shape({ focus: PropTypes.func })
  }),
  shouldHideButton: PropTypes.bool
};

export default ClearFormControl;
