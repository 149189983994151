import React, { useReducer, useState } from "react";
import {
  Modal,
  ButtonToolbar,
  Button,
  FormGroup,
  ControlLabel,
  FormControl
} from "@icg360/ui-toolkit";
import PT from "prop-types";
import AddressInput from "./address-input";
import { pick } from "lodash";
import MapCoordinates from "./map-coordinates";
import { findCoordinates } from "../../promises/google-places";
import useFetch from "../../hooks/use-fetch";
import {
  ADDRESS_VALIDATION,
  singleLineAddress
} from "../../units/address-input";

const VALIDATION_STATUS_TO_ZOOM = {
  [ADDRESS_VALIDATION.VALIDATED]: 18,
  [ADDRESS_VALIDATION.CUSTOM_CONFIRMED]: 18,
  [ADDRESS_VALIDATION.PARTIALLY_CITY]: 15,
  [ADDRESS_VALIDATION.PARTIALLY_STATE]: 12,
  [ADDRESS_VALIDATION.PARTIALLY_STREET]: 16,
  [ADDRESS_VALIDATION.UNVALIDATED]: 4
};

LocateProperty.propTypes = {
  initialAddress: PT.shape({
    validation: PT.oneOf(Object.values(ADDRESS_VALIDATION)),
    lat: PT.number,
    lng: PT.number
  }),
  onDone: PT.func.isRequired
};

LocateProperty.defaultProps = {
  initialAddress: null
};

const locationReducer = (state, action) => ({
  ...state,
  ...action
});

function LocateProperty({ initialAddress, onDone }) {
  // This is the location we are creating to return onDone
  const [location, setLocation] = useReducer(locationReducer, {});
  // The user can start by searching an approximate location
  const [approxQuery, setApproxQuery] = useState("");
  const [approxLocation, setApproxLocation] = useState(null);
  // If the user submits without all the information let's show errors
  const [submitAttempted, setSubmitAttempted] = useState(false);
  // If the location has a lat or lng then the user updated the map
  const mapChanged = !!(location.lat && location.lng);

  // When the map changes, let's fill the location with city, state, postalCode
  useFetch({
    request: findCoordinates,
    using: pick(location, ["lat", "lng"]),
    skipCall: !mapChanged,
    willUpdate: state => {
      if (state.dataMatchesRequest && state.data) {
        setLocation(pick(state.data, "city", "state", "postalCode"));
      }
    }
  });

  // Now we have a map location, let's show the form
  const hasMapLocation =
    mapChanged && location.city && location.state && location.postalCode;

  const onInputChange = ({ target: { name, value } }) => {
    setLocation({ [name]: value });
  };

  // Form is complete, allow the user to submit
  const hasFullAddress =
    hasMapLocation && location.streetNumber && location.route;

  return (
    <form
      className="LocateProperty"
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!hasFullAddress) {
          setSubmitAttempted(true);
        } else {
          // prepend "Unit" if subpremise is only one word
          const newSubpremise =
            location.subpremise && !/\S+\s+\S+/.test(location.subpremise)
              ? `Unit ${location.subpremise}`
              : location.subpremise;
          onDone({
            display: singleLineAddress({
              ...location,
              subpremise: newSubpremise
            }),
            ...location,
            subpremise: newSubpremise,
            locationType: "pushpin",
            validation: ADDRESS_VALIDATION.CUSTOM_CONFIRMED
          });
        }
      }}
    >
      <div className="LocateProperty-body">
        <div className="LocateProperty-mapSection">
          <div className="LocateProperty-headerWrapper">
            <h2>1. Set Property Location on Map</h2>
            <AddressInput
              value={approxQuery}
              onChange={setApproxQuery}
              className="LocateProperty-addressInput"
              onAddress={address => {
                setLocation({ lat: "", lng: "" });
                setApproxQuery(
                  [address.city, address.state].filter(i => i).join(", ")
                );
                setApproxLocation(address);
                document.body.focus();
              }}
              inputProps={{
                placeholder: "Enter a city or zipcode to start"
              }}
            />
          </div>
          <div className="LocateProperty-mapWrapper">
            <MapCoordinates
              initialZoom={
                approxLocation || initialAddress
                  ? VALIDATION_STATUS_TO_ZOOM[
                      approxLocation
                        ? approxLocation.validation
                        : initialAddress.validation
                    ]
                  : undefined
              }
              onChange={setLocation}
              className="LocateProperty-map"
              initial={approxLocation || initialAddress || undefined}
              showMarker={mapChanged}
            />
          </div>
        </div>
        <div className="LocateProperty-details">
          <h2>2. Enter Property Details</h2>
          <div className="LocateProperty-form">
            <div style={{ visibility: hasMapLocation ? "visible" : "hidden" }}>
              <FormGroup
                controlId="LocateProperty-streetNumber"
                validationState={
                  submitAttempted && !location.streetNumber ? "error" : null
                }
              >
                <ControlLabel>Street Number</ControlLabel>
                <FormControl
                  value={location.streetNumber || ""}
                  name="streetNumber"
                  onChange={onInputChange}
                />
              </FormGroup>
              <FormGroup
                controlId="LocateProperty-route"
                validationState={
                  submitAttempted && !location.route ? "error" : null
                }
              >
                <ControlLabel>Street Name</ControlLabel>
                <FormControl
                  value={location.route || ""}
                  name="route"
                  onChange={onInputChange}
                />
              </FormGroup>
              <FormGroup controlId="LocateProperty-subpremise">
                <ControlLabel>
                  Unit <small>(optional)</small>
                </ControlLabel>
                <FormControl
                  value={location.subpremise || ""}
                  name="subpremise"
                  onChange={onInputChange}
                />
              </FormGroup>
              <FormGroup controlId="LocateProperty-cityStateZip">
                <ControlLabel>City, State, ZIP</ControlLabel>
                <FormControl
                  value={[location.city, location.state, location.postalCode]
                    .filter(i => i)
                    .join(", ")}
                  disabled
                />
              </FormGroup>
              <FormGroup controlId="LocateProperty-locationCoordinates">
                <ControlLabel>Location Coordinates</ControlLabel>
                <FormControl
                  value={[location.lat, location.lng]
                    .map(l => Number.parseFloat(l).toFixed(6))
                    .join(", ")}
                  disabled
                />
              </FormGroup>
            </div>
            {!hasMapLocation && (
              <div className="LocateProperty-addressFieldsPlaceholder">
                <p>
                  Begin interacting with the map to find your precise property
                  location
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="LocateProperty-footer">
        <ButtonToolbar className="pull-right">
          <Button
            onClick={() => {
              onDone(null);
            }}
            bsStyle="link"
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!hasMapLocation} bsStyle="primary">
            Confirm Property Location
          </Button>
        </ButtonToolbar>
      </div>
    </form>
  );
}

LocatePropertyModal.propTypes = {
  ...LocateProperty.propTypes,
  show: PT.bool
};

LocatePropertyModal.defaultProps = {
  ...LocateProperty.defaultProps,
  show: false
};

export default function LocatePropertyModal({ show, initialAddress, onDone }) {
  return (
    <Modal
      className="LocatePropertyModal"
      show={show}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Locate Property Location</Modal.Title>
      </Modal.Header>
      <div className="LocatePropertyModal-body">
        {show && (
          <LocateProperty initialAddress={initialAddress} onDone={onDone} />
        )}
      </div>
    </Modal>
  );
}
