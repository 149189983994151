import { Preloader } from "@icg360/ui-toolkit";
import React, { Fragment, useState, useRef } from "react";
import PT from "prop-types";
import useFetch from "../../hooks/use-fetch";
import { findAddress } from "../../promises/google-places";

const errorMessages = {
  ZERO_RESULTS: "There were no results.",
  OVER_QUERY_LIMIT: "Too many queries.",
  REQUEST_DENIED: "Query denied.",
  UNKNOWN_ERROR: "Could not send the query at this time.",
  ERROR: "Could not send the query."
};

const request = using =>
  findAddress(using).then(results => ({ results, usedAddress: using.address }));

AddressSearch.propTypes = {
  query: PT.string.isRequired,
  onAddress: PT.func.isRequired,
  render: PT.func,
  enableSearch: PT.bool
};

AddressSearch.defaultProps = {
  render: null,
  enableSearch: false
};

export default function AddressSearch({ query, onAddress, render }) {
  const [searchedQuery, setSearch] = useState("");
  const container = useRef(null);

  const [
    {
      data: { results = [], usedAddress } = {},
      dataMatchesRequest,
      error,
      status
    }
  ] = useFetch({
    request,
    using: {
      address: searchedQuery
    },
    skipCall: !searchedQuery
  });

  const filteredResults = results.filter(({ state }) => state);

  return (
    <div className="AddressSearch" tabIndex="-1" ref={container}>
      {(() => {
        if (status === useFetch.STATUSES.BUSY) {
          return (
            <p>
              <Preloader size="17" />{" "}
            </p>
          );
        }
        if (usedAddress !== query) {
          return (
            <p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setSearch(query);
                  // This element is going to be removed and the dropdown will lose focus
                  container.current.focus();
                }}
              >
                Search &ldquo;{query}&rdquo;
              </a>
            </p>
          );
        }
        if (!filteredResults.length || status === useFetch.STATUSES.ERROR) {
          return (
            <Fragment>
              <p>
                <strong>Address not found.</strong>
              </p>
              {status === useFetch.STATUSES.ERROR && errorMessages[error] && (
                <p>{errorMessages[error]}</p>
              )}
            </Fragment>
          );
        }
        return (
          <Fragment>
            <p>
              <strong>Did you mean:</strong>
            </p>
            <ul>
              {filteredResults.map(result => (
                <li key={result.placeId}>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      onAddress(result);
                    }}
                  >
                    {result.display}
                  </a>
                </li>
              ))}
            </ul>
          </Fragment>
        );
      })()}
      {render &&
        render(
          usedAddress === query
            ? { data: results, dataMatchesRequest, error, status }
            : {}
        )}
    </div>
  );
}
