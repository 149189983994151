import $ from "jquery";

import AP from "../legacy/global";

export default ({ programID, alc, username, ownActivity }) =>
  new Promise((resolve, reject) => {
    if (ownActivity && !username) {
      reject("Username is not defined.");
      return;
    }
    if (!ownActivity && !alc) {
      reject("Agency Location is not defined for this user.");
      return;
    }
    $.ajax({
      url: `${AP.config.services.pop.proxy}${programID}/${AP.config.services
        .pxcentral.baseUrl}recentactivity`,
      type: "GET",
      beforeSend: xhr => {
        const authString = AP.getAuthString();
        xhr.setRequestHeader("X-Crippled-Client", "yes");
        xhr.setRequestHeader("include-password", "true");
        xhr.setRequestHeader("Authorization", authString);
        xhr.setRequestHeader("X-Authorization", authString);
      },
      data: {
        perPage: 15,
        representingAgent: ownActivity ? username : undefined,
        representingAgencyLocation: !ownActivity ? alc : undefined
      },
      dataType: "json"
    })
      .done(response => {
        resolve(response.ActivityEvents.ActivityEvent);
      })
      .fail((xhr, status, error) => {
        reject(error);
      });
  });
