import { Address } from "./address";
import { MortgageeSearch } from "./mortgagee-search";
import { Location, LocationCount } from "./location";

export default {
  address: Address,
  location: Location,
  mortgageeSearch: MortgageeSearch,
  "location-count": LocationCount,
  "location-count/nav": () => null // Uses the 'location-count' fusion key but this component is specific to the sidebar navigation.
};
