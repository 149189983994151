import React from "react";

const Arrow = () => (
  <svg
    className="icon-arrow"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.1 9.3"
  >
    <path d="M7.1 9.3c-.3 0-.6-.1-.8-.4L.2 1.6C-.2 1.2-.1.5.3.2c.4-.4 1.1-.3 1.4.1L7 6.7 12.3.3c.4-.4 1-.5 1.4-.1.4.4.5 1 .1 1.4l-6 7.3c-.2.3-.5.4-.7.4z" />
  </svg>
);

export default Arrow;
