import { trackOnce } from "../../units/launch-darkly";

export const hasFeature = (state, featureName) => {
  const flagValue = state.featureFlags[featureName];
  if (typeof flagValue !== "undefined") {
    trackOnce(featureName);
  }
  return typeof flagValue === "string" ? flagValue : !!flagValue;
};

export const getVariant = (state, featureName) => {
  return state.featureFlags[featureName];
};
