import { fromPairs } from "lodash";

export const flattenQuoteData = quoteData => {
  const { effectiveDate, inputs, carriers = [] } = quoteData;
  const rateResults = carriers?.[0]?.rateResults;
  if (!rateResults) {
    return { EffectiveDatePolicyTerm: effectiveDate, ...inputs };
  }
  const resultArray = Object.entries(rateResults).map(([name, { result }]) => [
    name,
    result
  ]);
  return rateResults
    ? {
        EffectiveDatePolicyTerm: effectiveDate,
        ...inputs,
        ...fromPairs(resultArray),
        ...fromPairs(
          resultArray.map(([name, result]) => [name.replace(/^Op/, ""), result])
        )
      }
    : { EffectiveDatePolicyTerm: effectiveDate, ...inputs };
};
