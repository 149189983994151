import moment from "moment";
import { chain, sortBy } from "lodash";

const PRIMARY_DOC_TYPES = [
  {
    key: "letter",
    label: "Enrollment Package Letter"
  },
  {
    key: "enrollmentinvoice",
    label: "Enrollment Invoice"
  },
  {
    key: "declarationofcoverage",
    label: "Declaration of Coverage"
  }
];

const PRIMARY_DEFAULT = PRIMARY_DOC_TYPES.reduce(
  (acc, { key, label }) => ({ ...acc, [key]: { label } }),
  {}
);
export default documents =>
  chain(documents)
    .groupBy("subtype")
    .mapValues(set => {
      const [mostCurrent, ...versionHistory] = sortBy(
        set,
        ({ lastUpdated }) => moment(lastUpdated).valueOf() * -1
      );
      return {
        mostCurrent,
        versionHistory
      };
    })
    .reduce(
      ({ primary, other }, group, subtype) => {
        const { key, label } =
          PRIMARY_DOC_TYPES.find(type =>
            subtype.toLowerCase().includes(type.key)
          ) || {};
        return key
          ? { primary: { ...primary, [key]: { ...group, label } }, other }
          : { primary, other: { ...other, [subtype]: group } };
      },
      { primary: PRIMARY_DEFAULT, other: {} }
    )
    .value();
