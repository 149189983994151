import $ from "jquery";

export default response => {
  let result = $(response)
    .find("DataItem")
    .toArray()
    .reduce(
      (acc, orgDataItem) => ({
        ...acc,
        [orgDataItem.getAttribute("name")]: orgDataItem.getAttribute("value")
      }),
      {}
    );

  result.agencyName = $(response)
    .find("Affiliation[type='location_agency']")
    .attr("targetName");

  return result;
};
