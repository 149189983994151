import {
  createModelProvider,
  externalModelUpdate,
  externalResetFields,
  metadataSelector
} from "@icg360/rex";
import { Button, ButtonToolbar, Icon } from "@icg360/ui-toolkit";
import PT from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { batch, connect } from "react-redux";
import { Element } from "react-scroll";
import { pick } from "lodash";

import {
  getAddressFusionDetails,
  changeAllPremisesBuildingNumbers,
  fusionInputsToObject,
  reduceLocationMeta
} from "../utils";
import { saveQuote } from "../../../store/quote-transaction-component";
import {
  memoDeriveForm,
  selectIsCommercial
} from "../../../store/selectors/qtc-selectors";
import { COMMERCIAL_FUSIONS, PL_FUSIONS } from "../constants";

import allFusions from ".";

const sharedLocationPropTypes = {
  inputs: PT.array.isRequired,
  passthroughProps: PT.shape({ emitter: PT.object.isRequired }).isRequired,
  meta: PT.shape({
    cloneInputs: PT.string.isRequired,
    inputNameSuffix: PT.string.isRequired,
    total: PT.string.isRequired
  }).isRequired
};

const getTheLatestAddressFusionDetails = state => {
  // This is not ideal
  // We need to know the address for each location to calculate premises and building numbers.
  // We could try to pull this data directly out of the model but then we need to know about specific term names.
  // The addressFusionDetails object already gathers this data for us based on fusion names.
  // The current addressFusionDetails is out sync because of the change we just made.
  // Rebuild the form with deriveForm and then re-calculate addressFusionDetails.
  const isCommercial = selectIsCommercial(state);
  const fusions = pick(
    allFusions,
    isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS
  );
  const groups = memoDeriveForm.quote(state, {
    fusions
  });
  return getAddressFusionDetails(groups, isCommercial);
};

const cloneMachine = ({
  cloneInputs = [],
  inputNameSuffix,
  values,
  from,
  to,
  formId,
  formIndex
}) => {
  if (!to || !inputNameSuffix) {
    console.warn("Missing clone arguments", { to, inputNameSuffix });
    return [];
  }
  const toSuffix = inputNameSuffix.replace("#", to);
  const toRegex = new RegExp(`^${toSuffix}.+|.+${toSuffix}$`);
  const resetAction = externalResetFields({
    useForm: { id: formId, index: formIndex },
    fields: Object.keys(values).filter(name => toRegex.test(name))
  });
  if (!from) {
    return [resetAction];
  }
  const fromSuffix = inputNameSuffix.replace("#", from);
  const setData = cloneInputs.reduce((acc, inputName) => {
    const hasPrefix = !!values[`${fromSuffix}${inputName}`];
    const toLabel = hasPrefix
      ? `${toSuffix}${inputName}`
      : `${inputName}${toSuffix}`;
    const fromLabel = hasPrefix
      ? `${fromSuffix}${inputName}`
      : `${inputName}${fromSuffix}`;
    return {
      ...acc,
      [toLabel]: values[fromLabel]
    };
  }, {});
  return [resetAction, externalModelUpdate({ formId, data: setData })];
};

const eraseMachine = ({
  number,
  total,
  inputNameSuffix,
  values,
  formId,
  formIndex
}) => {
  const intTotal = parseInt(total, 10);
  const intTo = parseInt(number, 10);
  const intFrom = intTo < intTotal ? intTo + 1 : 0;
  const fromRegex = intFrom
    ? new RegExp(
        `(^${inputNameSuffix.replace(
          "#",
          intFrom
        )}(.+)|(.+)${inputNameSuffix.replace("#", intFrom)}$)`
      )
    : undefined;
  const cloneActions = cloneMachine({
    cloneInputs: intFrom
      ? Object.keys(values)
          .filter(name => fromRegex.test(name))
          .map(key => key.replace(inputNameSuffix.replace("#", intFrom), ""))
      : undefined,
    inputNameSuffix,
    values,
    from: intFrom,
    to: intTo,
    formId,
    formIndex
  });
  return intFrom
    ? [
        ...cloneActions,
        ...eraseMachine({
          number: intFrom,
          total,
          inputNameSuffix,
          values,
          formId,
          formIndex
        })
      ]
    : cloneActions;
};

export const cloneThunk = (options, emitter) => (dispatch, getState) => {
  const { values } = createModelProvider(getState(), {
    type: "JSON",
    onlyTerms: true
  })("default");
  batch(() => {
    cloneMachine({ ...options, values }).forEach(action => {
      dispatch(action);
    });

    changeAllPremisesBuildingNumbers({
      addressFusionDetails: getTheLatestAddressFusionDetails(getState()),
      dispatch
    });

    emitter.current.emit("location:add");
    dispatch(saveQuote());
  });
};

export const eraseThunk = (options, emitter) => (dis, getState) => {
  const { values } = createModelProvider(getState(), {
    type: "JSON",
    onlyTerms: true
  })("default");
  batch(() => {
    eraseMachine({ ...options, values }).forEach(action => {
      dis(action);
    });

    changeAllPremisesBuildingNumbers({
      addressFusionDetails: getTheLatestAddressFusionDetails(getState()),
      dispatch: dis
    });

    emitter.current.emit("location:remove");
    dis(saveQuote());
  });
};

// Fusion
// name: location
// inputs: locationNumber, additionalLocations, numberOfLocations
LocationFusion.propTypes = {
  ...sharedLocationPropTypes,
  clone: PT.func.isRequired,
  erase: PT.func.isRequired
};

function LocationFusion({
  groupLabel,
  clone,
  erase,
  inputs,
  meta,
  passthroughProps: { addressFusionDetails }
}) {
  const { locationNumber, additionalLocations, numberOfLocations } =
    fusionInputsToObject(inputs);
  const intLocations =
    additionalLocations.value === "100"
      ? parseInt(numberOfLocations.value, 10)
      : 1;
  const showCloneButton = false;
  // const showCloneButton = intLocations < parseInt(meta.total, 10);
  const showDeleteButton = intLocations > 1;
  const addressDetails = addressFusionDetails[groupLabel];
  const locationLabel = addressDetails
    ? addressDetails.address
      ? [
          "Location",
          addressDetails.premisesNumber,
          "Building",
          addressDetails.buildingNumber
        ].join(" ")
      : "the new location"
    : `"Location ${locationNumber.value}"`;

  // We'll wrap these in `setTimout`s below so that we can make the
  // appearance in the UI that something is happening when we clone/delete
  // Rather than just having the UI freeze
  const [isCloning, setIsCloning] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => {
    if (isCloning) {
      clone({
        ...meta,
        cloneInputs: meta.cloneInputs.split(",").map(s => s.trim()),
        from: locationNumber.value,
        to: intLocations + 1
      });
      setIsCloning(false);
    }
    if (isDeleting) {
      erase({
        ...meta,
        number: locationNumber.value,
        total: intLocations
      });
      setIsDeleting(false);
    }
  }, [isCloning, isDeleting]);
  const disabled = isCloning || isDeleting;

  return (
    <ButtonToolbar className="LocationFusion">
      {showCloneButton && (
        <Button
          disabled={disabled}
          bsSize="xsmall"
          onClick={() => {
            setIsCloning(true);
          }}
        >
          {isCloning ? "Cloning..." : "Clone"}
        </Button>
      )}
      {showDeleteButton && (
        <Button
          disabled={disabled}
          bsSize="xsmall"
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to delete ${locationLabel}?`
              )
            ) {
              setIsDeleting(true);
            }
          }}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
      )}
    </ButtonToolbar>
  );
}

const mapLocationState = (state, { formId, formIndex }) => ({
  meta: reduceLocationMeta(
    metadataSelector(state, {
      useForm: {
        id: formId,
        index: formIndex
      }
    }).locations
  )
});

const mapLocationDispatch = (
  dispatch,
  { passthroughProps: { emitter }, formId, formIndex }
) => ({
  clone: options => {
    dispatch(cloneThunk({ formId, formIndex, ...options }, emitter));
  },
  erase: options => {
    dispatch(eraseThunk({ formId, formIndex, ...options }, emitter));
  }
});
export const Location = connect(
  mapLocationState,
  mapLocationDispatch
)(LocationFusion);

// Fusion
// name: location-count
// inputs: indicator, total
LocationCountFusion.propTypes = {
  ...sharedLocationPropTypes
};

function LocationCountFusion({
  label,
  meta,
  onChange,
  inputs,
  passthroughProps: { emitter }
}) {
  const eventRef = useRef({ add: null, remove: null });
  const { indicator, total } = fusionInputsToObject(inputs);
  const intTotalCount =
    indicator.value === "100" ? parseInt(total.value, 10) : 1;
  const enable = intTotalCount < parseInt(meta.total, 10);
  const handleAdd = enable
    ? () =>
        onChange({
          indicator: "100",
          total: intTotalCount + 1
        })
    : null;
  const handleRemove =
    intTotalCount > 1
      ? () =>
          onChange({
            indicator: intTotalCount === 2 ? "200" : "100",
            total: intTotalCount - 1
          })
      : null;
  eventRef.current = {
    add: handleAdd,
    remove: handleRemove
  };
  useEffect(() => {
    emitter.current.on("location:add", () => {
      if (eventRef.current.add) {
        eventRef.current.add();
      }
    });
    emitter.current.on("location:remove", () => {
      if (eventRef.current.remove) {
        eventRef.current.remove();
      }
    });
    return () => {
      emitter.current.off("location:add");
      emitter.current.off("location:remove");
    };
  }, []);
  return enable ? (
    <Element name={label}>
      <button className="LocationCountFusion" onClick={handleAdd}>
        <Icon name="add" size="sm" /> Add Location/Building
      </button>
    </Element>
  ) : null;
}

const mapLocationCountState = (state, { formId, formIndex }) => ({
  meta: reduceLocationMeta(
    metadataSelector(state, {
      useForm: {
        id: formId,
        index: formIndex
      }
    }).locations
  )
});

export const LocationCount = connect(mapLocationCountState)(
  LocationCountFusion
);
