import React from "react";
import PropTypes from "prop-types";

const Download = ({ className }) => (
  <svg
    className={`icon-download${className ? ` ${className}` : ""}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <path
      d="M11,13.2457864 L11,3 C11,2.44771525 11.4477153,2 12,2 C12.5522847,2 13,2.44771525 13,3 L13,13.2468542 L15.5397475,10.7071068 C15.9302718,10.3165825 16.5634367,10.3165825 16.953961,10.7071068 C17.3444853,11.0976311 17.3444853,11.7307961 16.953961,12.1213203 L12.0042136,17.0710678 L12.0005339,17.0673882 L11.9968542,17.0710678 L7.04710678,12.1213203 C6.65658249,11.7307961 6.65658249,11.0976311 7.04710678,10.7071068 C7.43763107,10.3165825 8.07079605,10.3165825 8.46132034,10.7071068 L11,13.2457864 Z M19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L7,3 C7.55228475,3 8,3.44771525 8,4 C8,4.55228475 7.55228475,5 7,5 L4.99441483,5 L4.99441483,19 L19.0055852,19 L19.0055852,5 L17,5 C16.4477153,5 16,4.55228475 16,4 C16,3.44771525 16.4477153,3 17,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 Z"
      id="path-1"
    />
  </svg>
);

Download.propTypes = {
  className: PropTypes.string
};

export default Download;
