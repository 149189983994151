import $ from "jquery";
import mixpanel from "mixpanel-browser";

import debounce from "../utils/debounce";
import { hasLocalStorage } from "../utils/feature-detection";

let hasInitialized = false;

export const init = ({ enabled, token, cookieDomain } = {}) => {
  if (document.cookie.indexOf("DoNotTrack=true") >= 0) return;
  if (!hasInitialized && enabled && hasLocalStorage()) {
    hasInitialized = true;
    mixpanel.init(token, {
      track_pageview: false,
      persistence: "localStorage"
    });
  } else if (cookieDomain) {
    // remove mixpanel cookies if disabled
    document.cookie
      .split(";")
      .map(cookie => cookie.split("=")[0])
      .filter(name => name.trim().indexOf("mp_") === 0)
      .forEach(name => {
        document.cookie = `${name}=;path=/;domain=${cookieDomain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      });
  }
};

export const configureUser = ({
  affiliation,
  alc,
  channel,
  dateAppointed,
  email,
  featureFlagsEnabled,
  fullname,
  isAdmin,
  isBackoffice,
  isLicensee,
  isPrimaryContact,
  isPrincipal,
  licenseNumber,
  loginType,
  org: { agencyLocationCode, pupStatus },
  organizationID,
  roles = [],
  username
}) => {
  if (!hasInitialized) return;
  mixpanel.identify(username);
  // user properties to set
  mixpanel.people.set({
    $distinct_id: username,
    $email: email,
    $name: fullname,
    affiliation,
    agencyLocationID: agencyLocationCode,
    agentPortalVersion: VERSION, // version defined as global var in webpack
    ALC: alc,
    Channel: channel.toUpperCase(),
    dateAppointed,
    featureFlagsEnabled,
    "Is Admin": isAdmin,
    "Is Backoffice": isBackoffice,
    isLicensee,
    isPrimaryContact,
    isPrincipal,
    lastLoggedIn: Date.now(),
    licenseNumber,
    loginType,
    org: { pupStatus },
    "Organization ID": organizationID.toLowerCase(),
    "Pup Status": pupStatus,
    Roles: roles.join(", "),
    username
  });
  // grab user browser Information
  // browser height and browser width
  const userBrowserHeight = window.innerHeight;
  const userBrowserWidth = window.innerWidth;
  // set mixpanel super properties
  const trackObject = {
    affiliation,
    agencyLocationID: agencyLocationCode,
    agentPortalVersion: VERSION, // version defined as global var in webpack
    alc,
    browserHeight: userBrowserHeight,
    browserWidth: userBrowserWidth,
    channel,
    dateAppointed,
    featureFlagsEnabled,
    isAdmin,
    isBackoffice,
    isLicensee,
    isPrimaryContact,
    isPrincipal,
    licenseNumber,
    loginType,
    organizationID,
    roles,
    username
  };
  mixpanel.register(trackObject);
  mixpanel.track("User- User Logged in", trackObject);
};

export const track = (...args) => {
  if (hasInitialized) mixpanel.track(...args);
};

export const register = (...args) => {
  if (hasInitialized) mixpanel.register(...args);
};

export const setUserProperties = (...args) => {
  if (hasInitialized) mixpanel.people.set(...args);
};

const lastValTyped = {};
const trackTyping = debounce(e => {
  const $target = $(e.currentTarget);
  const eventName = $target.attr("data-track-typing");
  const value = $target.val();
  if (lastValTyped[eventName] === value) return;
  lastValTyped[eventName] = value;
  if (value) {
    track(eventName, {
      "Typing Value": value
    });
  }
}, 2000);

// listening to route changes based off react-router's history context in B
export const trackPageChange = pathname => {
  if (!hasInitialized) return;
  mixpanel.track("Page- Page Loaded", {
    pathname,
    browserHeight: window.innerHeight,
    browserWidth: window.innerWidth
  });
  // send pageLoad data as super property
  mixpanel.register({
    pathname,
    browserHeight: window.innerHeight,
    browserWidth: window.innerWidth
  });
};

export const listen = () => {
  if (!hasInitialized) return;
  $(document)
    .on("click", "[data-track]", e => {
      const $target = $(e.currentTarget);
      const eventName = $target.attr("data-track");
      const rawData = $target.attr("data-track-json");
      if (rawData) {
        try {
          const json = JSON.parse(rawData);
          track(eventName, json);
        } catch (e) {
          console.warn("Malformed JSON in data-track-json attribute", e); // eslint-disable-line no-console
          track(eventName);
        }
      } else {
        track(eventName);
      }
    })
    .on("keyup", "[data-track-typing]", trackTyping)
    .on("change", "[data-track-change]", e => {
      const $target = $(e.currentTarget);
      const eventName = $target.attr("data-track-change");
      if ($target.prop("tagName") === "SELECT") {
        track(eventName, {
          Selection: $target.val()
        });
        return;
      }
      const type = $target.prop("type");
      if (type === "checkbox") {
        track(eventName, {
          "Checkbox Value": $target.prop("checked")
        });
      }
      if (type === "radio" && $target.prop("checked")) {
        track(eventName);
      }
    });
};

// Reset hasInitialized for testing purposes
export const resetHasInitialized = () => {
  hasInitialized = false;
};
