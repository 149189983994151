import getTransactions from "../promises/get-transactions";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickEnvInfo, pickTransactions } from "./selectors";

const LOADING_STATE = "transactions/LOADING_STATE";
const COLLECTION = "transactions/COLLECTION";
const ERROR = "transactions/ERROR";
const CATEGORY = "transactions/CATEGORY";
const FILTER_TEXT = "transactions/FILTER_TEXT";
const SORT_FIELD = "transactions/SORT_FIELD";

const setLoadingState = loadingState => ({
  type: LOADING_STATE,
  loadingState
});

const setCollection = collection => ({
  type: COLLECTION,
  collection
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

export const setCategory = category => ({
  type: CATEGORY,
  category
});

export const setFilterText = filterText => ({
  type: FILTER_TEXT,
  filterText
});

export const setSortField = sortField => ({
  type: SORT_FIELD,
  sortField
});

export const loadTransactions = () => (dispatch, getState) => {
  const state = getState();
  const { programID, alc } = pickEnvInfo(state);
  const { loadingState } = pickTransactions(state);
  if (loadingState !== LOADING) {
    dispatch(setLoadingState(LOADING));
    return getTransactions({ programID, alc })
      .then(collection => dispatch(setCollection(collection)))
      .catch(errorMessage => dispatch(setError(errorMessage)));
  }
  return Promise.resolve();
};

export const loadTransactionsOnce = () => (dispatch, getState) => {
  const { loadingState } = pickTransactions(getState());
  if (loadingState === PRELOAD) {
    return dispatch(loadTransactions());
  }
  return Promise.resolve();
};

export const transactions = (
  state = {
    loadingState: PRELOAD,
    collection: [],
    category: "",
    filterText: "",
    sortField: "transactionDate",
    sortAscending: false,
    errorMessage: null
  },
  data
) => {
  switch (data.type) {
    case LOADING_STATE: {
      return {
        ...state,
        loadingState: data.loadingState
      };
    }
    case COLLECTION: {
      return {
        ...state,
        collection: data.collection,
        loadingState: LOADED,
        errorMessage: null
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        collection: []
      };
    }
    case CATEGORY: {
      return {
        ...state,
        category: data.category
      };
    }
    case FILTER_TEXT: {
      return {
        ...state,
        filterText: data.filterText
      };
    }
    case SORT_FIELD: {
      return data.sortField === state.sortField
        ? {
            ...state,
            sortAscending: !state.sortAscending
          }
        : {
            ...state,
            sortField: data.sortField
          };
    }
    default:
      return state;
  }
};
