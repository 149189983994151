import React, { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";

import { flattenInputs } from "../utils";

const cleanInput = ({ id, label, inputs, ...rest }) => {
  if (inputs && inputs.length) {
    return {
      id: inputs[0].id || kebabCase(inputs[0].label),
      label,
      inputs,
      ...rest
    };
  }

  const idWithFallback = id || kebabCase(label);
  return {
    id: idWithFallback,
    label,
    ...rest
  };
};

const QuoteEditFormContext = createContext({});
export const QuoteProvider = ({ children, derivedForm }) => {
  const allInputs = derivedForm
    .flatMap(({ inputs }) => inputs && flattenInputs(inputs))
    .map(cleanInput);

  const requiredInputs = allInputs.filter(({ isRequired, inputs }) => {
    if (inputs) {
      return inputs.some(({ isRequired: fieldRequired }) => !!fieldRequired);
    }
    return !!isRequired;
  });
  const [activeQuestionId, setActiveQuestionId] = useState();

  useEffect(() => {
    // Sets initial activeQuestionId once the form loads
    if (
      allInputs.length &&
      (!activeQuestionId ||
        !allInputs.some(
          ({ id, label }) =>
            id === activeQuestionId || label === activeQuestionId
        ))
    )
      setActiveQuestionId(allInputs[0].id || allInputs[0].label);
  }, [allInputs]);

  return (
    <QuoteEditFormContext.Provider
      value={{
        activeQuestionId,
        setActiveQuestionId,
        allInputs,
        requiredInputs
      }}
    >
      {children}
    </QuoteEditFormContext.Provider>
  );
};

QuoteProvider.propTypes = {
  children: PropTypes.node.isRequired,
  derivedForm: PropTypes.array.isRequired
};

export const useQuoteEditForm = () => useContext(QuoteEditFormContext);
