import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const ExpireTimeOut = ({ expireInSeconds, onTimeout, children }) => {
  const timerRef = useRef();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const timer = expireInSeconds * 1000;
    timerRef.current = setTimeout(() => {
      setExpired(true);
      if (onTimeout) {
        onTimeout();
      }
    }, timer);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  if (expired) {
    return null;
  } else {
    return <>{children}</>;
  }
};

ExpireTimeOut.propTypes = {
  expireInSeconds: PropTypes.number,
  onTimeout: PropTypes.func,
  children: PropTypes.node
};

export default ExpireTimeOut;
