import { Auth } from "@icg360/auth-js";
import storage from "../utils/storage";
import * as cookies from "./cookies";
import AP from "../legacy/global";

// eslint-disable-next-line import/prefer-default-export
export const logout = message => {
  if (typeof message === "string") {
    storage.setItem("login-message", message);
  }
  const cookieOptions = createCookieOptions();
  cookies.write("aptoken", "", cookieOptions);
  cookies.write("apb64", "", cookieOptions);
  cookies.write("auth-session-uri", "", cookieOptions);
  cookies.write("Authorization", "", cookieOptions);
  cookies.write("apagenttoken", "", cookieOptions);
  if (AP && AP.config) {
    const authClient = new Auth({
      servicesUrl: AP.config.services.ixauth.url
    });
    authClient
      .logout()
      .catch(e => {
        console.warn(e);
      })
      .then(redirect);
  } else {
    redirect();
  }
};

function redirect() {
  if (cookies.read("agencylocationid") != null) {
    cookies.write("agencylocationid", "", createCookieOptions());
    document.location.href = "support/index.html";
  } else {
    document.location.href = `login.html${window.location.search}${
      window.location.hash
    }`;
  }
}

function createCookieOptions() {
  return {
    expires: new Date()
  };
}
