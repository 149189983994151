import { initialize } from "launchdarkly-js-client-sdk";
import { mapValues } from "lodash";

let ldClient;

export const init = ({ key, user }) => {
  ldClient = initialize(key, user, {
    fetchGoals: false,
    streaming: true,
    sendEventsOnlyForVariation: true
  });
  return ldClient.waitForInitialization().then(ldClient.allFlags);
};

export const listen = cb => {
  if (ldClient) {
    ldClient.on("change", changes => {
      cb(mapValues(changes, change => change.current));
    });
  }
};

export const trackOnce = flag => {
  if (ldClient) {
    // Call this just for LD event tracking purposes;
    // we don't need to know what the result is because
    // we already stored the result in redux, we just
    // want to tell LD that this flag is being used.
    ldClient.variation(flag, false);
  }
};

export const trackExperimentView = trackOnce;

// This triggers a conversion metric in launch darkly
// Numeric values are currently disabled until we can get all the launch darkly SDKs up to newer versions
export const trackExperiment = ({ eventName, numericValue }) => {
  if (ldClient) {
    if (numericValue) {
      ldClient.track(eventName, null, numericValue);
    } else ldClient.track(eventName);
  }
};
