import {
  Select,
  FormControl,
  FormGroup,
  ControlLabel,
  Col,
  Row,
  HelpBlock
} from "@icg360/ui-toolkit";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCcFormData } from "../../../store/quote-transaction-component";
import { selectCcFormData } from "../../../store/selectors/qtc-selectors";
import { pickEnvInfo, selectIsFednat } from "../../../store/selectors";
import {
  isValidCard,
  isValidExpirationDate,
  isZip
} from "../../../utils/validation";

const cardTypes = [
  { label: "American Express", value: "AmericanExpress" },
  { label: "Discover", value: "Discover" },
  { label: "MasterCard", value: "MasterCard" },
  { label: "Visa", value: "Visa" }
];

const prettyPrintPercentage = percentage => `${(percentage * 100).toFixed(2)}%`;

function CcForm() {
  const dispatch = useDispatch();
  const updateCcForm = input => dispatch(updateCcFormData(input));

  const ccFormData = useSelector(selectCcFormData);
  const { creditCardFeePercent } = useSelector(pickEnvInfo);
  const isFednat = useSelector(selectIsFednat);
  const {
    payorFirstName,
    payorLastName,
    creditCardType,
    billingAddressLine1,
    accountNumber,
    creditCardExpirationMonth,
    creditCardExpirationYear,
    billingAddressZipCode
  } = ccFormData;
  const selectedCardType = getSelectedOptions(creditCardType, cardTypes);

  const [validationFields, setValidationFields] = useState({
    accountNumberState: accountNumber || null,
    expirationMonthState: creditCardExpirationMonth || null,
    expirationYearState: creditCardExpirationYear || null,
    zipCodeState: billingAddressZipCode || null
  });

  return (
    <div>
      <div className="cc-payment-form-disclaimer-container">
        {isFednat
          ? `Credit Card payments will incur a ${prettyPrintPercentage(
              creditCardFeePercent
            )} processing fee for all transactions. This fee can be avoided by selecting ACH or check as the payment method.`
          : `Credit Card payments will incur a ${prettyPrintPercentage(
              creditCardFeePercent
            )} processing fee for all future transactions (today's down payment will not incur a fee). This fee can be avoided by selecting ACH as the payment method.
        `}
      </div>
      <div className="payment-form-container cc-payment-form-container">
        <Row>
          <Col lg={6}>
            <ControlLabel className="inputLabel">
              {"First Name "}
              {!payorFirstName && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormGroup>
              <FormControl
                type="text"
                value={payorFirstName || ""}
                onChange={e =>
                  updateCcForm({
                    id: "payorFirstName",
                    value: e.target.value
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col lg={6} className="payment-form-column">
            <ControlLabel className="inputLabel">
              {"Last Name "}
              {!payorLastName && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormGroup>
              <FormControl
                type="text"
                value={payorLastName || ""}
                onChange={e =>
                  updateCcForm({ id: "payorLastName", value: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ControlLabel className="inputLabel">
              {"Card Number "}
              {(!validationFields.accountNumberState ||
                !isValidCard(validationFields.accountNumberState)) && (
                <span className="text-danger">*</span>
              )}
            </ControlLabel>
            <FormGroup
              validationState={
                validationFields.accountNumberState
                  ? isValidCard(validationFields.accountNumberState)
                    ? null
                    : "error"
                  : null
              }
            >
              <FormControl
                type="text"
                value={validationFields.accountNumberState || ""}
                maxLength="16"
                onChange={e => {
                  const { value } = e.target;

                  setValidationFields(prevState => ({
                    ...prevState,
                    accountNumberState: value
                  }));
                  updateCcForm({
                    id: "accountNumber",
                    value: isValidCard(value) ? value : null
                  });
                }}
              />
              {validationFields.accountNumberState &&
                !isValidCard(validationFields.accountNumberState) && (
                  <HelpBlock>Please enter a valid credit card number</HelpBlock>
                )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ControlLabel className="inputLabel">
              {"Card Type "}
              {!creditCardType && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormGroup>
              <Select
                placeholder=""
                menuPlacement="auto"
                value={selectedCardType || ""}
                options={cardTypes.map(({ label, value }) => ({
                  label: label,
                  value: value
                }))}
                onChange={e => {
                  updateCcForm({ id: "creditCardType", value: e.value });
                }}
                className="ap-select"
              />
            </FormGroup>
          </Col>
          <Col lg={6} className="cc-expiration-container">
            <div className="cc-expiration-fields">
              <Col lg={6}>
                <ControlLabel className="inputLabel">
                  {"Exp. Month "}
                  {(!validationFields.expirationMonthState ||
                    !isValidExpirationDate(
                      validationFields.expirationMonthState,
                      validationFields.expirationYearState
                    )) && <span className="text-danger">*</span>}
                </ControlLabel>
                <FormGroup
                  validationState={
                    validationFields.expirationMonthState
                      ? isValidExpirationDate(
                          validationFields.expirationMonthState,
                          validationFields.expirationYearState
                        )
                        ? null
                        : "error"
                      : null
                  }
                >
                  <FormControl
                    type="text"
                    placeholder="MM"
                    maxLength="2"
                    max="12"
                    value={validationFields.expirationMonthState || ""}
                    onChange={e => {
                      const { value } = e.target;

                      if (!isNaN(value)) {
                        setValidationFields(prevState => ({
                          ...prevState,
                          expirationMonthState: value
                        }));

                        if (
                          isValidExpirationDate(
                            value,
                            validationFields.expirationYearState
                          )
                        ) {
                          updateCcForm({
                            id: "creditCardExpirationMonth",
                            value: value
                          });
                          updateCcForm({
                            id: "creditCardExpirationYear",
                            value: validationFields.expirationYearState
                          });
                        } else {
                          updateCcForm({
                            id: "creditCardExpirationMonth",
                            value: null
                          });
                          updateCcForm({
                            id: "creditCardExpirationYear",
                            value: null
                          });
                        }
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <ControlLabel className="inputLabel">
                  {"Exp. Year "}
                  {(!validationFields.expirationYearState ||
                    !isValidExpirationDate(
                      validationFields.expirationMonthState,
                      validationFields.expirationYearState
                    )) && <span className="text-danger">*</span>}
                </ControlLabel>
                <FormGroup
                  validationState={
                    validationFields.expirationYearState
                      ? isValidExpirationDate(
                          validationFields.expirationMonthState,
                          validationFields.expirationYearState
                        )
                        ? null
                        : "error"
                      : null
                  }
                >
                  <FormControl
                    type="text"
                    placeholder="YYYY"
                    maxLength="4"
                    minLength="4"
                    value={validationFields.expirationYearState || ""}
                    onChange={e => {
                      const { value } = e.target;

                      if (!isNaN(value)) {
                        setValidationFields(prevState => ({
                          ...prevState,
                          expirationYearState: value
                        }));

                        if (
                          isValidExpirationDate(
                            validationFields.expirationMonthState,
                            value
                          )
                        ) {
                          updateCcForm({
                            id: "creditCardExpirationYear",
                            value: value
                          });
                          updateCcForm({
                            id: "creditCardExpirationMonth",
                            value: validationFields.expirationMonthState
                          });
                        } else {
                          updateCcForm({
                            id: "creditCardExpirationMonth",
                            value: null
                          });
                          updateCcForm({
                            id: "creditCardExpirationYear",
                            value: null
                          });
                        }
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </div>
            {(validationFields.expirationMonthState ||
              validationFields.expirationYearState) &&
              !isValidExpirationDate(
                validationFields.expirationMonthState,
                validationFields.expirationYearState
              ) && (
                <div className="expiration-date-error has-error">
                  <HelpBlock>Please enter a valid expiration date</HelpBlock>
                </div>
              )}
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ControlLabel className="inputLabel">
              {"Street Address "}
              {!billingAddressLine1 && <span className="text-danger">*</span>}
            </ControlLabel>
            <FormGroup>
              <FormControl
                type="text"
                value={billingAddressLine1 || ""}
                onChange={e =>
                  updateCcForm({
                    id: "billingAddressLine1",
                    value: e.target.value
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col lg={6} className="payment-form-column">
            <ControlLabel className="inputLabel">
              {"Zip Code "}
              {(!validationFields.zipCodeState ||
                !isZip(validationFields.zipCodeState)) && (
                <span className="text-danger">*</span>
              )}
            </ControlLabel>
            <FormGroup
              validationState={
                validationFields.zipCodeState
                  ? isZip(validationFields.zipCodeState)
                    ? null
                    : "error"
                  : null
              }
            >
              <FormControl
                type="text"
                value={validationFields.zipCodeState || ""}
                onChange={e => {
                  const { value } = e.target;

                  setValidationFields(prevState => ({
                    ...prevState,
                    zipCodeState: value
                  }));
                  updateCcForm({
                    id: "billingAddressZipCode",
                    value: isZip(value) ? value : null
                  });
                }}
              />
              {validationFields.zipCodeState &&
                !isZip(validationFields.zipCodeState) && (
                  <HelpBlock>Please enter a 5-digit number</HelpBlock>
                )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
}

function getSelectedOptions(modelValue, options) {
  return options.find(({ value }) => value === modelValue);
}

export const CCForm = CcForm;
