import {
  createMemoDeriveForm,
  rulesetsSelector,
  allFormRulesetsSelector
} from "@icg360/rex";
import { createSelector } from "reselect";
import { pick, keyBy, isEmpty } from "lodash";

import {
  QTC_FORM_ID,
  COMMERCIAL_FUSIONS,
  PL_FUSIONS
} from "../../components/quote-transaction-component/constants";
import { countRequiredEmptyPaymentFields } from "../../components/quote-transaction-component/utils";
import allFusions from "../../components/quote-transaction-component/fusions";
import { printDate } from "../../utils/dates";

import { pickQTC } from ".";

export const memoDeriveForm = {
  quote: createMemoDeriveForm(),
  checkout: createMemoDeriveForm(),
  newMortgagee: createMemoDeriveForm()
};

const noQuote = {};
export const selectQTCQuote = state =>
  state.quoteTransactionComponent?.quote || noQuote;

export const selectQTCSaving = state => state.quoteTransactionComponent?.saving;
export const selectQTCLoading = state =>
  state.quoteTransactionComponent?.loading;

const noInputs = {};
export const selectQuoteInputs = createSelector(
  selectQTCQuote,
  quote => quote.inputs || noInputs
);

const noCarriers = [];
export const selectCarriers = createSelector(
  selectQTCQuote,
  quote => quote.carriers || noCarriers
);

export const selectBusinessName = createSelector(
  selectQuoteInputs,
  inputs => inputs.InsuredName || inputs.DBAName || ""
);

export const selectPolicyHolderName = createSelector(
  selectQuoteInputs,
  inputs =>
    inputs.InsuredName ||
    [inputs.InsuredFirstName, inputs.InsuredLastName]
      .filter(i => i)
      .join(" ") ||
    ""
);

export const selectExpirationDate = createSelector(
  selectQuoteInputs,
  selectCarriers,
  (inputs, carriers) => {
    // If we have a calculated expiration date from a carrier, use that.
    // Otherwise, use the value from inputs.
    if (
      carriers.length &&
      carriers[0].rateResults &&
      carriers[0].rateResults.ExpirationDate &&
      carriers[0].rateResults.ExpirationDate.result
    ) {
      return carriers[0].rateResults.ExpirationDate.result;
    }
    return inputs.ExpirationDate;
  }
);

export const selectReviewLock = createSelector(selectQTCQuote, ({ flags }) => {
  const reviewLock = flags?.ReviewLock;
  if (typeof reviewLock === "string") {
    return reviewLock.toLowerCase() === "true";
  }
  return reviewLock === true;
});

export const selectRCEData = createSelector(
  selectQuoteInputs,
  pickQTC,
  ({ CoverageA }, { replacementCost }) => ({
    hasError: replacementCost.error,
    replacementCostData: {
      CoverageA,
      ...replacementCost.replacementCostData
    }
  })
);

export const selectRulesets = createSelector(
  rulesetsSelector,
  (rulesets = []) => rulesets.filter(set => set.result === "YES")
);

export const selectAllRulesetsWithIdAsIndex = createSelector(
  allFormRulesetsSelector,
  rulesets => keyBy(rulesets, rule => rule.idref)
);

const noFormNames = [];
export const selectFormList = createSelector(
  state => state.rex.childNames,
  childNames =>
    childNames[QTC_FORM_ID] ? Object.keys(childNames[QTC_FORM_ID]) : noFormNames
);

export const selectIsCommercial = createSelector(
  selectQTCQuote,
  ({ productType }) => !!productType && productType.toLowerCase() === "bop"
);

export const selectCcFormData = createSelector(
  pickQTC,
  quoteTransactionComponent => {
    return quoteTransactionComponent.ccFormData;
  }
);

export const selectAchFormData = createSelector(
  pickQTC,
  quoteTransactionComponent => {
    return quoteTransactionComponent.achFormData;
  }
);

export const selectCheckFormData = createSelector(
  pickQTC,
  quoteTransactionComponent => {
    return quoteTransactionComponent.checkFormData;
  }
);

const inputReducer = (accumulator, currentInput) => {
  if (currentInput.type === "group") {
    // If the type of the input is "group",
    // we need to pass it's "inputs" through this function again
    accumulator = currentInput.inputs.reduce(inputReducer, accumulator);
  } else if (currentInput.type !== "repeat") {
    // We ignore inputs with type "repeat" because they currently
    // do not have any "id" for which we could target a link
    // in the future, when the API changes, this should change
    accumulator.push(currentInput);
  }
  return accumulator;
};
export const selectAllInputsWithIdAsIndex = createSelector(
  state => {
    if (!state.rex.forms[QTC_FORM_ID]) return null;
    const { id, index } = state.rex.currentForm;
    const childId = state.rex.forms[id][index].name;
    const isCommercial = selectIsCommercial(state);
    const fusions = pick(
      allFusions,
      isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS
    );
    return memoDeriveForm[childId](state, {
      fusions,
      useForm: { id: QTC_FORM_ID, childId }
    });
  },
  groups => {
    if (isEmpty(groups)) return [];

    const inputsArr = groups.reduce((accumulator, group) => {
      return group.inputs.reduce(inputReducer, accumulator);
    }, []);

    // translate array of inputs to object with "id" as keys
    const inputsWithIdAsIndex = keyBy(inputsArr, input => input.id);

    return inputsWithIdAsIndex;
  }
);

export const selectIsFormComplete = (state, childId = "quote") => {
  const isCommercial = selectIsCommercial(state);
  const fusions = pick(
    allFusions,
    ...(isCommercial ? COMMERCIAL_FUSIONS : PL_FUSIONS)
  );
  const isCompleted = createSelector(
    state => {
      if (!state.rex.forms[QTC_FORM_ID]) return null;
      return memoDeriveForm[childId](state, {
        fusions,
        useForm: { id: QTC_FORM_ID, childId }
      });
    },
    groups => {
      if (!groups) return false;
      const incomplete = groups.some(group => {
        const achFormData = selectAchFormData(state);
        const ccFormData = selectCcFormData(state);

        const count = countRequiredEmptyPaymentFields(
          group.inputs,
          achFormData,
          ccFormData
        );

        if (count > 0) return true;

        return group.inputs.some(input => {
          if (input.inputs) {
            return input.inputs.some(nestedGroup => {
              if (Array.isArray(nestedGroup))
                return nestedGroup.some(
                  nestedInput =>
                    nestedInput.isRequired || nestedInput.valid === false
                );
              return nestedGroup.isRequired || nestedGroup.valid === false;
            });
          }
          return input.isRequired || input.valid === false;
        });
      });
      return !incomplete;
    }
  );
  return isCompleted(state);
};

export const selectElgibleCarriers = createSelector(selectCarriers, carriers =>
  carriers.filter(({ eligibility, stages }) => {
    const hasBlockers = !["block", "prevent"].some(
      k => eligibility[k] && eligibility[k].result
    );
    const isFilled = ["rating"].every(k => stages[k] && stages[k].complete);
    return hasBlockers && isFilled;
  })
);

export const selectCurrentCarrier = createSelector(
  selectCarriers,
  carriers => carriers[0]
);

export const selectIsQuoteReferrable = createSelector(
  [selectCurrentCarrier, selectIsFormComplete],
  (currentCarrier, isFormComplete) => {
    if (!currentCarrier) return;
    const { eligibility } = currentCarrier;
    const isBlocked = eligibility?.block?.result;
    const isPrevented = eligibility?.prevent?.result;
    return isFormComplete && isBlocked && !isPrevented;
  }
);

export const selectIsQuoteEligible = createSelector(
  selectCurrentCarrier,
  currentCarrier =>
    !!currentCarrier &&
    !currentCarrier?.eligibility.stop?.result &&
    !currentCarrier?.eligibility.block?.result &&
    !currentCarrier?.eligibility.prevent?.result
);

export const selectQuoteEffectiveDate = createSelector(
  selectQTCQuote,
  quote => quote.effectiveDate
);

export const selectEffectiveDateRange = state =>
  state.quoteTransactionComponent.effectiveDate.range;

export const selectEffectiveDateValidation = state =>
  state.quoteTransactionComponent.effectiveDate.error;

export const selectProductSelection = state =>
  state.quoteTransactionComponent.productSelection;

export const selectQTCModals = state => state.quoteTransactionComponent.modals;

export const selectQuoteLastSaved = createSelector(
  selectQTCSaving,
  ({ lastSaved }) => printDate(lastSaved, "M/D/YYYY h:mma")
);

export const selectHasPremium = createSelector(
  selectCurrentCarrier,
  carrier => !!carrier?.rateResults?.TotalPremium?.result
);

export const selectLosses = createSelector(
  selectQTCQuote,
  ({ losses }) => losses || {}
);

export const selectConsentToRate = createSelector(
  selectCurrentCarrier,
  carrier => carrier?.rateResults?.OpConsentToRate?.result === "100"
);

export const selectSearchMortgagee = state =>
  state.quoteTransactionComponent.searchMortgagee;

export const selectQTCViewTracker = createSelector(
  selectQuoteInputs,
  selectQTCQuote,
  selectIsCommercial,
  selectQuoteLastSaved,
  selectQuoteEffectiveDate,
  selectBusinessName,
  selectPolicyHolderName,
  selectCarriers,
  selectIsQuoteEligible,
  (
    inputs,
    quote,
    isCommercial,
    lastSaved,
    effectiveDate = "",
    businessName,
    policyName,
    carriers,
    quoteEligibility
  ) => ({
    insuranceType: isCommercial ? "Commercial" : "Personal",
    productType: quote.productType || "",
    quoteCarriers: carriers.map(({ id }) => id),
    quoteLastSaved: lastSaved,
    quoteEffectiveDate: effectiveDate,
    quoteEligibility,
    quoteId: quote.quoteId || null,
    quoteName: isCommercial ? businessName : policyName,
    quoteState: inputs.PropertyState || "",
    quoteZipCode: inputs.PropertyZipCode || ""
  })
);
