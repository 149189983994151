import getContacts from "../promises/get-contacts";
import {
  PRELOAD,
  LOADING,
  LOADED,
  LOAD_ERROR
} from "../static-data/loading-state-enum";
import { pickContacts, pickEnvInfo, selectAuthString } from "./selectors";

const LOADING_STATE = "contacts/LOADING_STATE";
const COLLECTION = "contacts/COLLECTION";
const ERROR = "contacts/ERROR";

const setLoadingState = loadingState => ({
  type: LOADING_STATE,
  loadingState
});

const setCollection = collection => ({
  type: COLLECTION,
  collection
});

const setError = errorMessage => ({
  type: ERROR,
  errorMessage
});

export const loadContacts = () => (dispatch, getState) => {
  const state = getState();
  const { loadingState } = pickContacts(state);
  const { programID, programConfigID } = pickEnvInfo(state);
  const authString = selectAuthString(state);

  if (loadingState !== LOADING) {
    dispatch(setLoadingState(LOADING));
    return getContacts({ authString, programID, programConfigID })
      .then(collection => dispatch(setCollection(collection)))
      .catch(errorMessage => {
        if (typeof errorMessage === "object") {
          console.warn(errorMessage);
        }
        dispatch(
          setError(typeof errorMessage === "string" ? errorMessage : "")
        );
      });
  }
  return Promise.resolve();
};

export const loadContactsOnce = () => (dispatch, getState) => {
  const { loadingState } = pickContacts(getState());
  if (loadingState === PRELOAD) {
    return dispatch(loadContacts());
  }
  return Promise.resolve();
};

export const contacts = (
  state = {
    loadingState: PRELOAD,
    collection: [],
    errorMessage: null
  },
  data
) => {
  switch (data.type) {
    case LOADING_STATE: {
      return {
        ...state,
        loadingState: data.loadingState
      };
    }
    case COLLECTION: {
      return {
        ...state,
        collection: data.collection,
        loadingState: LOADED,
        errorMessage: null
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: data.errorMessage,
        loadingState: LOAD_ERROR,
        collection: []
      };
    }
    default:
      return state;
  }
};
