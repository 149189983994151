import {
  ADDRESS_VALIDATION,
  mapGeocodeToAddress
} from "../units/address-input";
import { track } from "../units/tracking";

let _autocompleteService;
let _placesService;
let _geocoder;
let _token;
let _tokenTime = 0;
// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
const autocompleteService = () => {
  _autocompleteService =
    _autocompleteService || new window.google.maps.places.AutocompleteService();
  return _autocompleteService;
};
// https://developers.google.com/maps/documentation/javascript/reference/places-service
const placesService = () => {
  _placesService =
    _placesService ||
    new window.google.maps.places.PlacesService(document.createElement("div"));
  return _placesService;
};
// https://developers.google.com/maps/documentation/javascript/reference/geocoder
const geocoder = () => {
  _geocoder = _geocoder || new window.google.maps.Geocoder();
  return _geocoder;
};
const token = () => {
  const now = Date.now();
  // Use the current token if it is within 3 minutes
  // https://stackoverflow.com/questions/50398801/how-long-do-the-new-places-api-session-tokens-last
  if (_token && _tokenTime + 180000 > now) {
    return _token;
  }
  _tokenTime = now;
  _token = new window.google.maps.places.AutocompleteSessionToken();
  return _token;
};
const clearToken = () => {
  _token = undefined;
};

const buildCallback = (resolve, fallback) => (result, status) => {
  const STATUSES = window.google.maps.places.PlacesServiceStatus;
  if (status === STATUSES.OK) {
    resolve(result);
    return;
  }
  if (status === STATUSES.OVER_QUERY_LIMIT) {
    throw new Error("Google Map API is over the query limit");
  }
  if (status === STATUSES.REQUEST_DENIED) {
    throw new Error("Google Maps API request was denied");
  }
  resolve(fallback);
};

export const getPlacePredictions = options =>
  new Promise(resolve => {
    autocompleteService().getPlacePredictions(
      {
        componentRestrictions: { country: "US" },
        types: ["geocode"],
        sessionToken: token(),
        ...options
      },
      buildCallback(resolve, [])
    );
  });

export const getPlaceDetails = ({ placeId, isEstablishment = false }) =>
  new Promise(resolve => {
    placesService().getDetails(
      {
        fields: isEstablishment
          ? [
              "address_components",
              "formatted_address",
              "geometry",
              "types",
              "name"
            ]
          : ["address_components", "formatted_address", "geometry", "types"],
        placeId,
        sessionToken: token()
      },
      buildCallback(data => {
        clearToken();
        resolve(mapGeocodeToAddress(data, placeId));
      }, null)
    );
  });

export const findAddress = ({ address }) =>
  new Promise(resolve => {
    geocoder().geocode(
      { componentRestrictions: { country: "US" }, address },
      buildCallback(data => {
        resolve(data.map(mapGeocodeToAddress));
      }, [])
    );
  });

export const findCoordinates = location =>
  new Promise(resolve => {
    geocoder().geocode(
      { location },
      buildCallback(data => {
        resolve(data[0] ? mapGeocodeToAddress(data[0]) : null);
      }, [])
    );
  });

export const getGeocoderPlace = ({ placeId }) =>
  new Promise(resolve => {
    geocoder().geocode(
      { placeId },
      buildCallback(data => {
        resolve(data[0] ? mapGeocodeToAddress(data[0]) : null);
      }, null)
    );
  });

export const getPlace = options =>
  Promise.all([getPlaceDetails(options), getGeocoderPlace(options)]).then(
    ([address, geocoderAddress]) => {
      if (!geocoderAddress) {
        return address;
      }
      const { validation, locationType, lat, lng, placeId } = geocoderAddress;
      // Check to see if there are actually any differences between GetPlaces and Geocoder
      // If there are, send the data to Mixpanel
      if (
        (address.validation === ADDRESS_VALIDATION.VALIDATED ||
          validation === ADDRESS_VALIDATION.VALIDATED) &&
        (address.validation !== validation ||
          address.lat !== lat ||
          address.lng !== lng)
      ) {
        track("Google address discrepancy", {
          ...address,
          geocoderLocationType: locationType,
          geocoderLat: lat,
          geocoderLng: lng,
          placeId
        });
      }
      return {
        ...address,
        validation,
        locationType,
        lat,
        lng,
        placeId
      };
    }
  );
