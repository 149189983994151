const typesMap = {
  Bound: "Bound",
  BrokerOfRecordChange: "Broker of Record Change",
  Cancel: "Cancelled",
  Chargeback: "Premium Disbursement",
  DataCorrection: "Data Correction",
  Endorse: "Endorsement",
  Invoice: "Invoice Sent",
  Issue: "Issued",
  NewPolicyRegistration: "New Policy Registration",
  PayeeChange: "Changed Payee",
  Payment: "Payment Processed",
  PayorChange: "Payor Changed",
  PendingCancellation: "Cancellation Pending",
  PolicyChangeRegistration: "Policy Change Registration",
  Renewal: "Renewed",
  RescindPendingCancellation: "Pending Cancellation Rescinded",
  ReturnPremium: "Premium Disbursement",
  sendToPrintVendor: "Sent to Print Vendor"
};

// eslint-disable-next-line no-prototype-builtins
export default rawType =>
  typesMap.hasOwnProperty(rawType) ? typesMap[rawType] : rawType;
