import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HighlightableText from "./highlightable-text";
import { pickQuoteView } from "../../store/selectors";

export const searchableTextCollection = new Set();

class SearchableText extends React.Component {
  static propTypes = {
    fieldLabel: PropTypes.string,
    fieldValue: PropTypes.string,
    searchText: PropTypes.string,
    text: PropTypes.string,
    // this should be present if there are multiple fields with the same above prop values
    // but you still want each field to show up individually.
    // useful for Policy Activity section, for example,
    // where there may be multiple instances of the same activity.
    uniqueKey: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    fieldLabel: "",
    fieldValue: "",
    text: "",
    searchText: ""
  };

  componentDidMount() {
    searchableTextCollection.add(this);
  }

  componentWillUnmount() {
    searchableTextCollection.delete(this);
  }

  render() {
    const { text, searchText, className } = this.props;
    return (
      <span
        ref={r => (this.ref = r)}
        className={`SearchBar-searchableText ${className || ""}`}
      >
        <HighlightableText
          fullText={text}
          textToMatch={searchText.trim()}
          highlightClass="SearchBar-highlightedSearch"
        />
      </span>
    );
  }
}

const mapStateToProps = state => pickQuoteView(state, "searchText");

export default connect(mapStateToProps)(SearchableText);
