import { Dropdown, FormControl, Icon, MenuItem } from "@icg360/ui-toolkit";
import classnames from "classnames";
import PT from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import useContainsFocus from "../../hooks/use-contains-focus";
import useFetch from "../../hooks/use-fetch";
import usePost from "../../hooks/use-post";
import { getPlace, getPlacePredictions } from "../../promises/google-places";
import ClearFormControl from "./clear-form-control";

const NORMALIZE_SIZE = {
  sm: "sm",
  small: "sm",
  lg: "lg",
  large: "lg",
  default: ""
};

const SEARCH_THRESHOLD = 3;
const DROPDOWN_TYPES = {
  DEFAULT: "DEFAULT",
  FALLBACK: "FALLBACK"
};

const EmphasizeMatched = ({ text, matched }) => {
  const list = matched.reduce(
    ({ parts, from }, { offset, length }) => ({
      parts: [
        ...parts,
        { type: "text", value: text.substring(from, offset) },
        { type: "match", value: text.substring(offset, offset + length) }
      ],
      from: offset + length
    }),
    { parts: [], from: 0 }
  );
  if (list.from < text.length) {
    list.parts.push({
      type: "text",
      value: text.substring(list.from)
    });
  }
  return list.parts
    .filter(({ value }) => value)
    .map(({ type, value }, i) => (
      <span key={i} className={type}>
        {value}
      </span>
    ));
};

const AddressInput = forwardRef(
  (
    {
      className,
      disabled,
      defaultMenuItemCount,
      fallbackMenuItemCount,
      includeEstablishments,
      inputProps,
      isLocked,
      readOnly,
      onAddress,
      onBlur,
      onChange,
      onEnter,
      renderDefault,
      renderFallback,
      size,
      value
    },
    ref
  ) => {
    const [highlightIndex, setHighlightIndex] = useState(-1);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const hasFocus = useContainsFocus({ ref: dropdownRef });
    const enableSuggestions = !isLocked && value.length >= SEARCH_THRESHOLD;

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }));

    // PPQ-5066 IE focuses the field but typing is ignored
    // I believe this is because focus is set before readOnly is removed.
    const delayedFocus = useRef(false);
    useEffect(() => {
      if (!isLocked && delayedFocus.current) {
        delayedFocus.current = false;
        inputRef.current.focus();
      }
    }, [isLocked]);

    const [{ data }] = useFetch({
      request: getPlacePredictions,
      using: {
        input: value,
        types: includeEstablishments
          ? ["geocode", "establishment"]
          : ["geocode"]
      },
      skipCall: !hasFocus || !enableSuggestions,
      willUpdate: state => {
        if (state.dataMatchesRequest) {
          setHighlightIndex(state.data && state.data.length ? 0 : -1);
        }
        return state;
      }
    });
    const results = data || [];

    const showSuggestions = enableSuggestions && (!data || !!results.length);

    const [, getAddressData] = usePost({
      request: getPlace,
      willUpdate: state => {
        if (state.data) {
          onAddress(state.data);
          return undefined;
        }
        return state;
      }
    });

    const onSelect = placeId => {
      getAddressData({ placeId, isEstablishment: includeEstablishments });
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleInputKeyDown = e => {
      const { key, metaKey } = e;
      if (isLocked && !readOnly && !disabled) {
        if (key === "Backspace") {
          e.preventDefault();
          onAddress(null);
        }
        return;
      }
      const highlightMax = calcHighlightMax({
        showSuggestions,
        enableSuggestions,
        resultCount: results.length,
        defaultMenuItemCount,
        fallbackMenuItemCount
      });
      if (key === "PageDown" || (key === "ArrowDown" && metaKey)) {
        e.preventDefault();
        setHighlightIndex(highlightMax);
      } else if (key === "PageUp" || (key === "ArrowUp" && metaKey)) {
        e.preventDefault();
        setHighlightIndex(-1);
      } else if (key === "ArrowDown") {
        e.preventDefault();
        setHighlightIndex(
          highlightIndex >= highlightMax ? -1 : highlightIndex + 1
        );
      } else if (key === "ArrowUp") {
        e.preventDefault();
        setHighlightIndex(
          highlightIndex <= -1 ? highlightMax : highlightIndex - 1
        );
      } else if (key === "Escape") {
        document.body.focus();
      } else if (key === "Enter") {
        e.preventDefault();
        if (showSuggestions && highlightIndex >= 0) {
          onSelect(results[highlightIndex].place_id);
        } else {
          onEnter({
            highlightIndex,
            type: enableSuggestions
              ? DROPDOWN_TYPES.FALLBACK
              : DROPDOWN_TYPES.DEFAULT
          });
        }
      }
    };

    const isOpen =
      hasFocus &&
      !isLocked &&
      (showSuggestions ||
        (enableSuggestions && renderFallback) ||
        (!enableSuggestions && renderDefault));

    return (
      <div
        ref={dropdownRef}
        className={classnames(
          "AddressInput",
          className,
          "dropdown",
          isOpen && "open"
        )}
        data-size={NORMALIZE_SIZE[size]}
        data-locked={isLocked}
      >
        <ClearFormControl
          onClear={() => {
            onAddress(null);
            delayedFocus.current = true;
          }}
          shouldHideButton={!(isLocked && !readOnly && !disabled)}
        >
          <FormControl
            autoComplete="off"
            disabled={disabled}
            readOnly={readOnly || isLocked}
            bsSize={size === "default" ? undefined : size}
            {...inputProps}
            inputRef={r => {
              // use functional ref because react bootstrap doesn't support objects
              inputRef.current = r;
            }}
            value={value}
            onChange={({ target: { value } }) => {
              onChange(value);
              if (value.length < SEARCH_THRESHOLD && highlightIndex !== -1) {
                setHighlightIndex(-1);
              }
            }}
            onBlur={onBlur}
            onKeyDown={handleInputKeyDown}
            title={isLocked ? value : undefined}
          />
        </ClearFormControl>
        <Dropdown.Menu className="AddressInput-dropdown">
          {showSuggestions &&
            results.map(
              ({ description, place_id, matched_substrings }, index) => (
                <MenuItem
                  active={highlightIndex === index}
                  eventKey={place_id}
                  key={place_id}
                  onSelect={onSelect}
                  title={description}
                  className="AddressInput-suggestion"
                >
                  <Icon name="room" className="icon" size="sm" />{" "}
                  <EmphasizeMatched
                    text={description}
                    matched={matched_substrings}
                  />
                </MenuItem>
              )
            )}
          {enableSuggestions &&
            renderFallback &&
            renderFallback({ highlightIndex, results })}
          {!enableSuggestions &&
            renderDefault &&
            renderDefault({ highlightIndex, results })}
        </Dropdown.Menu>
      </div>
    );
  }
);

AddressInput.propTypes = {
  className: PT.string,
  defaultMenuItemCount: PT.number,
  disabled: PT.bool,
  fallbackMenuItemCount: PT.number,
  includeEstablishments: PT.bool,
  inputProps: PT.object,
  isLocked: PT.bool,
  readOnly: PT.bool,
  onAddress: PT.func.isRequired,
  onBlur: PT.func,
  onChange: PT.func.isRequired,
  onEnter: PT.func,
  renderDefault: PT.func,
  renderFallback: PT.func,
  size: PT.oneOf(["sm", "small", "lg", "large", "default"]),
  value: PT.string
};

AddressInput.defaultProps = {
  className: null,
  disabled: false,
  defaultMenuItemCount: 0,
  fallbackMenuItemCount: 0,
  includeEstablishments: false,
  inputProps: {},
  isLocked: false,
  readOnly: false,
  onEnter: () => {},
  renderDefault: null,
  renderFallback: null,
  size: "default",
  value: ""
};

AddressInput.DROPDOWN_TYPES = DROPDOWN_TYPES;

export default AddressInput;

function calcHighlightMax({
  showSuggestions,
  enableSuggestions,
  resultCount,
  defaultMenuItemCount,
  fallbackMenuItemCount
}) {
  let max = 0;
  if (showSuggestions) {
    max += resultCount;
  }
  if (enableSuggestions) {
    max += fallbackMenuItemCount;
  }
  if (!enableSuggestions) {
    max += defaultMenuItemCount;
  }
  return Math.max(max - 1, 0);
}
