import PropTypes from "prop-types";
import React, { useState } from "react";
import { Element } from "react-scroll";
import PreloaderButton from "../../toolkit/preloader-button";

export const Action = ({
  label,
  handleClick,
  id,
  isReadOnly,
  passthroughProps: { quoteId }
}) => {
  const [isLoading, setLoading] = useState(false);
  const opts = {};
  if (isReadOnly) opts.disabled = true;
  const onClick = () => {
    setLoading(true);
    handleClick({ label, quoteId })
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
      });
  };
  return (
    <Element name={id} className="QTCInput">
      <div className="action">
        <PreloaderButton
          {...opts}
          isLoading={isLoading}
          id={id}
          onClick={onClick}
          data-bdd={`QTC ${id} Button`}
        >
          {label}
        </PreloaderButton>
      </div>
    </Element>
  );
};

Action.propTypes = {
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  passthroughProps: PropTypes.shape({
    quoteId: PropTypes.string
  }),
  label: PropTypes.string,
  status: PropTypes.shape({
    loading: PropTypes.bool
  })
};

Action.defaultProps = {
  status: {
    loading: false
  }
};
