import SearchIcon from "./search";
import DownloadIcon from "./download";
import DocumentIcon from "./document";
import ArrowIcon from "./arrow";
import PrintIcon from "./print";
import CompareIcon from "./compare";
import CloseIcon from "./close";

export {
  SearchIcon,
  DownloadIcon,
  DocumentIcon,
  ArrowIcon,
  PrintIcon,
  CompareIcon,
  CloseIcon
};
