import { createSelector } from "reselect";
import { last, isEmpty, find } from "lodash";

export const selectQuoteViewData = state => state.quoteView.data || {};

export const hasPolicyActions = createSelector(
  selectQuoteViewData,
  ({ management: { SystemOfRecord } = {} }) => SystemOfRecord === "mxServer"
);

export const hasPaymentPlan = createSelector(
  selectQuoteViewData,
  hasPolicyActions,
  (data, hasActions) => {
    return !!(
      hasActions &&
      data &&
      data.accounting &&
      data.accounting.PaymentPlan.type
    );
  }
);

export const selectPolicyInspections = createSelector(
  selectQuoteViewData,
  ({ inspections = [], policyData }) => {
    const { ConfirmationNumber, CreatedTimeStamp } =
      last(inspections.filter(({ Code }) => Code === "Success")) || {};
    return {
      inspectionCompany: policyData.InspectionCompany || "Millennium",
      confirmationNum: ConfirmationNumber,
      dateOrdered: CreatedTimeStamp
    };
  }
);

export const quoteViewLastUpdated = createSelector(
  selectQuoteViewData,
  quoteViewData => {
    if (!isEmpty(quoteViewData)) {
      if (quoteViewData.policyId) {
        const lastUpdated = quoteViewData.eventHistory.reduce(
          (time, { policyVersion, type, timeStamp }) =>
            policyVersion || ["Created", "Bound", "Updated"].includes(type)
              ? timeStamp
              : time,
          null
        );
        return {
          lastUpdated,
          version: quoteViewData.management.Version
        };
      } else {
        return {
          version: quoteViewData.version,
          lastUpdated: quoteViewData.updateDatetime
        };
      }
    }
    return {};
  }
);

export const selectIsCommercial = createSelector(
  selectQuoteViewData,
  ({ productType }) => !!productType && productType.toLowerCase() === "bop"
);

export const getLatestPaperlessEmail = createSelector(
  selectQuoteViewData,
  quoteViewData =>
    quoteViewData?.otherReferences
      ?.reverse()
      .find(({ featureType }) => featureType === "PaperlessEmailInvite")
);

export const selectPaymentPlan = createSelector(
  selectQuoteViewData,
  quoteViewData => {
    const paymentInformation = quoteViewData?.billing?.paymentInformation?.data;
    if (paymentInformation) {
      const paymentPlan = find(paymentInformation, {
        label: "Payment Plan"
      });
      if (paymentPlan) {
        return paymentPlan.value;
      }
      return null;
    } else {
      return null;
    }
  }
);
