import $ from "jquery";

import getPaymentPlans from "../promises/get-payment-plans";
import getTerms from "../promises/get-terms";
import postEasypay from "../promises/post-easypay";
import postPolicyNote from "../promises/post-policy-note";
import postPolicyTransaction from "../promises/post-policy-transaction";
import signerParser from "../utils/signer-parser";
import AP from "../legacy/global";

import { loadPolicy } from "./quote-view";
import {
  pickEnvInfo,
  pickPaymentPlan,
  pickQuoteView,
  selectAll,
  selectAuthString
} from "./selectors";

const MODAL = "payment-plan/MODAL";
const CHANGE_PAGE = "payment-plan/CHANGE_PAGE";
const CHANGE_PLAN = "payment-plan/CHANGE_PLAN";
const MESSAGE_CLOSE = "payment-plan/MESSAGE_CLOSE";
const MESSAGE_UPDATE = "payment-plan/MESSAGE_UPDATE";
const USE_POLICYHOLDER_CHANGE = "payment-plan/USE_POLICYHOLDER_CHANGE";
const CHANGE_EMAIL = "payment-plan/CHANGE_EMAIL";
const CHANGE_PAYOR = "payment-plan/CHANGE_PAYOR";
const CHANGE_PAY_METHOD = "payment-plan/CHANGE_PAY_METHOD";
const LOADING = "payment-plan/IS_LOADING";
const RESET_DEFAULT = "payment-plan/RESET_DEFAULT";
const INIT_POLICY_DATA = "payment-plan/INIT_POLICY_DATA";
const LOAD_TERMS = "payment-plan/LOAD_TERMS";
const ALREADY_ENROLLED = "payment-plan/ALREADY_ENROLLED";
const CHANGE_PDF_LINK = "payment-plan/CHANGE_PDF_LINK";
const DIRECT_FINISHED = "payment-plan/DIRECT_FINISHED";
const CHANGE_SELECTED = "payment-plan/CHANGE_SELECTED";
const INIT_MODAL = "payment-plan/INIT_MODAL";

export const changeSelected = data => ({
  type: CHANGE_SELECTED,
  payload: { ...data }
});

export const initEasyPay = data => ({
  type: INIT_MODAL,
  payload: { ...data }
});

export const directFinished = data => ({
  type: DIRECT_FINISHED,
  payload: { ...data }
});

export const changePdfLink = pdfLink => ({
  type: CHANGE_PDF_LINK,
  payload: pdfLink
});

export const alreadyEnrolled = () => ({
  type: ALREADY_ENROLLED,
  payload: {
    pageName: "already-enrolled",
    modalShow: true
  }
});

export const loadTerms = terms => ({
  type: LOAD_TERMS,
  payload: { terms: { ...terms } }
});

export const updatePolicyData = policyData => ({
  type: INIT_POLICY_DATA,
  payload: {
    modalShow: true,
    ...policyData
  }
});

export const changeEmail = email => ({
  type: CHANGE_EMAIL,
  payload: {
    email
  }
});

export const changePolicyHolderCheck = usePolicyholderEmail => ({
  type: USE_POLICYHOLDER_CHANGE,
  payload: {
    usePolicyholderEmail
  }
});

export const changePayor = payor => ({
  type: CHANGE_PAYOR,
  payload: { payor }
});

export const updateModal = modalShow => ({
  type: MODAL,
  payload: { modalShow }
});

export const changePayMethod = selectedPayMethod => ({
  type: CHANGE_PAY_METHOD,
  payload: { selectedPayMethod }
});

export const changePage = pageName => ({
  type: CHANGE_PAGE,
  payload: {
    pageName,
    isLoading: false
  }
});

export const changePlan = (selectedPlan, selectedFees) => ({
  type: CHANGE_PLAN,
  payload: {
    selectedPlan,
    selectedFees
  }
});

export const messageClose = () => ({
  type: MESSAGE_CLOSE,
  payload: {
    messages: []
  }
});

export const messageUpdate = message => ({
  type: MESSAGE_UPDATE,
  payload: {
    messages: [message]
  }
});

export const loading = isLoading => ({
  type: LOADING,
  payload: { isLoading }
});

export const updatePayMethod = payMethod => (dispatch, getState) => {
  const { payor, selectedPlan } = pickPaymentPlan(getState());

  const newSelectedPlan = selectPlanType(selectedPlan, payMethod, payor)();

  dispatch(
    changeSelected({
      selectedPayMethod: payMethod,
      selectedPlan: newSelectedPlan
    })
  );
};

export const mapPaymentPlans = plans => {
  const planList = plans.map(element => {
    const plan = {
      name: element.id,
      text: element.numberOfPayments === "1" ? "Payment" : "Payments",
      fees:
        element.numberOfPayments === "1"
          ? 0
          : parseInt(element.numberOfPayments, 10) *
            parseInt(element.installmentCharge, 10),
      numberOfPayments: element.numberOfPayments,
      billingMethod: element.billingMethod,
      payments: []
    };

    plan.payments.push({
      label: "Payment 1",
      amount: element.downPayment
    });

    if (element.numberOfPayments !== "1") {
      plan.payments.push({
        label: `Payment 2-${element.numberOfPayments}`,
        amount: element.installmentAmount
      });
    }

    return plan;
  });

  return planList.sort((a, b) => a.numberOfPayments - b.numberOfPayments);
};

export const selectPlanType =
  (selectedPlan, selectedPayMethod, payor) => () => {
    let planType = null;
    selectedPlan = selectedPlan ? selectedPlan.toLowerCase() : "";
    if (payor === "mortgagee") {
      planType = "invoice";
    } else if (payor === "policyholder") {
      if (selectedPayMethod === "easypay") {
        switch (selectedPlan) {
          case "fulleasypayach":
          case "fullpay":
            planType = "fullEasyPayACH";
            break;
          case "twoeasypayach":
          case "twopay":
            planType = "twoEasyPayACH";
            break;
          case "fulleasypaycc":
            planType = "fullEasyPayCC";
            break;
          case "twoeasypaycc":
            planType = "twoEasyPayCC";
            break;
          case "foureasypaycc":
            planType = "fourEasyPayCC";
            break;
          case "teneasypaycc":
            planType = "tenEasyPayCC";
            break;
          case "foureasypayach":
          case "fourpay":
            planType = "fourEasyPayACH";
            break;
          case "teneasypayach":
          case "tenpay":
          case "tenpayinvoice":
            planType = "tenEasyPayACH";
            break;
          default:
            planType = null;
        }
      } else if (selectedPayMethod === "direct") {
        switch (selectedPlan) {
          case "fullpay":
          case "fulleasypayach":
            planType = "fullPay";
            break;
          case "twopay":
          case "twoeasypayach":
            planType = "twoPay";
            break;
          case "fourpay":
          case "foureasypayach":
            planType = "fourPay";
            break;
          case "tenpay":
          case "tenpayinvoice":
          case "teneasypayach":
            planType = "tenPayInvoice";
            break;
          default:
            planType = null;
        }
      }
    }

    return planType;
  };

export const onInit = initialData => dispatch => {
  const policyData = { ...initialData };

  if (policyData.currentPayMethod === "easypay") {
    policyData.pageName = "already-enrolled";
  }

  Promise.all([getPaymentPlans(policyData.quoteId), getTerms()])
    .then(data => {
      policyData.plans = mapPaymentPlans(data[0].plans);
      const planTerms = data[1].find(item => item.name === "PaymentPlanType");
      const terms = {};
      if (planTerms) {
        planTerms.enumerations.enumerations.forEach(plan => {
          terms[plan.value] = plan.label;
        });
        mapTermsOverrides(terms);
      }
      policyData.terms = terms;
      dispatch(updatePolicyData(policyData));
    })
    .catch(error => {
      dispatch(
        messageUpdate({
          type: "error",
          title: "Failed to get terms or plans",
          description: error
        })
      );
      dispatch(updateModal(true));
    });
};

const mapTermsOverrides = terms => {
  //stip CC and ACH from display label
  for (var term in terms) {
    terms[term] = terms[term].replace(" ACH", "").replace(" CC", "");
  }
};

const getPdfLink = data => {
  let pdfLink = "";

  const attachments = $(data).find("Attachments").toArray();
  const last = $(attachments).find("Attachment").last();
  const $last = $(last);
  const attach = {
    id: $last.attr("id"),
    name: $last.attr("name"),
    href: $last.attr("href")
  };
  pdfLink = attach.href;

  return pdfLink;
};

const selectReasonCode = (payor, selectedType, currentType) => {
  if (payor === "policyholder" && selectedType !== currentType) {
    return 155;
  }
  return 151;
};

export const onSave = () => (dispatch, getState) => {
  const [
    authString,
    {
      selectedPlan,
      selectedPayMethod,
      payor,
      currentPayPlan,
      currentPayMethod,
      currentPayor,
      policyId,
      quoteId,
      policyholderName,
      email,
      producerEmail,
      producerName,
      insightPolicyId,
      version,
      pdfLink,
      terms
    }
  ] = selectAll(getState(), [selectAuthString, pickPaymentPlan]);

  dispatch(loading(true));

  if (selectedPayMethod === "easypay") {
    postEasypay({
      policyId,
      quoteId,
      insuredEmail: email,
      insuredName: policyholderName,
      producerName,
      producerEmail,
      recipients: [email],
      subject: `Complete your EasyPay Enrollment Today`,
      message:
        "In order to finalize your enrollment in SageSure EasyPay, please complete and electronically sign the Enrollment Form.  This will authorize SageSure to electronically transfer funds from your account.  For your convenience, SageSure will continue to provide this service until we hear from you.  If you have any questions please contact us at (877)304-4785.  Thanks for choosing SageSure.",
      emailUpdated: false,
      initiator: producerEmail,
      documentName: "EasyPay Enrollment"
    })
      .then(() => {
        // calling addnote here as postEasypay call needs to finish first
        const payplan =
          terms[selectPlanType(selectedPlan, selectedPayMethod, payor)()] ||
          selectedPlan;
        const today = new Date().toLocaleString();
        const note = `EasyPay payment change requested: ${payplan}
        Policy: ${policyId}
        Date Requested: ${today}
      `;
        postPolicyNote(insightPolicyId, note)
          .then(() => {
            dispatch(changePage("easypay-confirmation"));
          })
          .catch(error => {
            dispatch(loading(false));
            dispatch(
              messageUpdate({
                type: "error",
                title: "error adding note to policy",
                description: error.responseText
              })
            );
          });
      })
      .catch(error => {
        dispatch(loading(false));
        dispatch(
          messageUpdate({
            type: "error",
            title: "error submitting payment change",
            description: error.responseText
          })
        );
      });
  } else {
    const selectedPlanType = selectPlanType(
      selectedPlan,
      selectedPayMethod,
      payor
    )();
    const currentPlanType = selectPlanType(
      currentPayPlan,
      currentPayMethod,
      currentPayor
    )();
    postPolicyTransaction({
      authString,
      commit: true,
      policyId,
      insightPolicyId,
      type: "AccountingChanges",
      user: "",
      version,
      reasonCode: selectReasonCode(payor, selectedPlanType, currentPlanType),
      reason: "Accounting Change by Producer",
      specificChanges: `	<AccountingChanges>
      <Set>
        <DataItem name="PaymentPlanType" value="${selectedPlanType}" />
        <DataItem name="Payor" value="${
          payor === "mortgagee" ? "100" : "200"
        }" />
      </Set>
    </AccountingChanges>`,
      effectiveDate: new Date().toISOString().substr(0, 10)
    })
      .then(data => {
        const fullLink = pdfLink + getPdfLink(data);
        dispatch(
          directFinished({
            pdfLink: fullLink,
            pageName: "plan-email",
            isLoading: false
          })
        );
      })
      .catch(error => {
        dispatch(loading(false));
        dispatch(
          messageUpdate({
            type: "error",
            title: "error submitting payment change",
            description: error.responseText
          })
        );
      });
  }
};

const defaults = {
  pageName: "change-plan",
  modalShow: false,
  selectedPlan: null,
  selectedFees: null,
  selectedPlanType: null,
  easypayEnabled: true,
  selectedPayMethod: null,
  payor: "policyholder",
  messages: [],
  isLoading: false,

  policyholderEmail: "",
  usePolicyholderEmail: false,
  saveEmail: false,
  email: "",
  pdfLink: "",

  saveEnabled: false,

  reset: false,
  terms: {},
  currentPayor: "",
  currentPayPlan: "",
  currentPayMethod: "",
  mortgagee: {},
  plans: []
};

export const resetDefaults = () => ({
  type: RESET_DEFAULT,
  payload: { ...defaults }
});

export const onClose = () => (dispatch, getState) => {
  const [{ pageName }, { quoteNumber, isPolicy }] = selectAll(getState(), [
    pickPaymentPlan,
    pickQuoteView
  ]);
  if (
    (pageName === "plan-email" || pageName === "easypay-confirmation") &&
    quoteNumber &&
    isPolicy
  ) {
    dispatch(loadPolicy(quoteNumber));
  }
  dispatch(resetDefaults());
};

export const fireAutoSendEasyPayForm = () => (dispatch, getState) => {
  const state = getState();
  const { calculation, policy, insured, producer } = state.paymentPlan;
  const { channel } = pickEnvInfo(state);
  const isGeico = channel.toUpperCase() === "GEICO";
  // Disable this feature for non-geico producers PPQ-7648
  if (!isGeico) {
    return;
  }
  const geicoLogo = `http://externalmedia-ss-stage.s3-website-us-east-1.amazonaws.com/sagesure/images/logos/geicosagesurelogo.png`;
  const payload = {
    policyId: policy.id,
    quoteId: policy.quoteId,
    subject:
      "Complete enrollment in EasyPay on your new policy from GEICO and SageSure",
    message: `<p>Thank you for purchasing an insurance policy through <strong>GEICO Insurance Agency</strong>, underwritten by SageSure Insurance Managers. We are pleased to offer the convenience of automatic recurring payments through SageSure EasyPay. Enroll today and never worry about missing a payment!</p><p>To sign up, please complete and electronically sign this Enrollment Form. This will authorize SageSure to electronically transfer funds from your account. For your convenience, SageSure will then continue to provide this service until we hear from you. If you have any questions please contact us at (855) 721-9251.</p><p>Thanks for choosing <strong>GEICO</strong> and <strong>SageSure.</strong></p><img alt='sagesure geico png' src='${geicoLogo}' />`,
    emailUpdated: false,
    initiator: producer.email,
    documentName: "EasyPay Enrollment",
    brandId: AP.config.application.docusignGeicoBrandId || ""
  };

  if (calculation.OpPostBindESignEmailSigners) {
    payload.signers = signerParser(
      calculation.OpPostBindESignEmailSigners
    ).signers;
    payload.recipients = signerParser(
      calculation.OpPostBindESignEmailReceivers
    ).receivers;
  } else {
    // TODO: remove this code block once all products are moved
    // to the signer array
    // PPQ-4725
    payload.insuredEmail = calculation.OpInsuredEmailAddress;
    payload.insuredName =
      calculation.OpInsuredByCorporation === "100"
        ? calculation.OpInsuredName
        : insured.name;
    payload.producerEmail = producer.email;
    payload.producerName = producer.name;
    payload.recipients = [];
  }

  return postEasypay(payload).catch(console.log);
};

export const paymentPlan = (state = defaults, { type, payload }) => {
  switch (type) {
    case CHANGE_PAYOR: {
      return {
        ...state,
        ...payload,
        selectedPlan: payload.payor === "morgagee" ? "invoice" : null,
        selectedPayMethod:
          payload.payor === "mortgagee" ? null : state.selectedPayMethod
      };
    }
    case CHANGE_EMAIL:
    case DIRECT_FINISHED:
    case CHANGE_PDF_LINK:
    case ALREADY_ENROLLED:
    case USE_POLICYHOLDER_CHANGE:
    case RESET_DEFAULT:
    case MODAL:
    case CHANGE_PAGE:
    case LOADING:
    case MESSAGE_CLOSE:
    case MESSAGE_UPDATE:
    case INIT_POLICY_DATA:
    case LOAD_TERMS:
    case CHANGE_PAY_METHOD:
    case CHANGE_PLAN:
    case INIT_MODAL:
    case CHANGE_SELECTED: {
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};
