// This is going to need to be refactored
// AP.launchSignDocumentModal launches a legacy modal
// It's called from pxClient so we can't change the shape of the data that is sent to it
// We are still using the same post-bind modal in QTC so we need this function to create
// the correct shape.

export const setProvidedOrDefault = (provided, defaultVal) =>
  typeof provided !== "undefined" ? provided : defaultVal;

export const uncommentNewLines = str => str.replace(/\\n/g, "\n");

export default ({ carrier, carriers, postBindWorkflow = {}, policyId }) => {
  const { rateResults, documents } = carriers[0];

  const dataObj = {
    carrierId: "",
    producer: {},
    calculation: {},
    message: {},
    submission: {},
    documents: {},
    policy: {},
    insured: {},
    info: {}
  };

  const postBindDefaultEmailBody = uncommentNewLines(
    setProvidedOrDefault(
      postBindWorkflow.postBindDefaultEmailBody,
      "Enclosed you will find your Enrollment Package; please note action is required on your part to complete your enrollment and keep your policy active. You will find these required actions in the cover letter.\n\nSincerely,\nSageSure Insurance Managers"
    )
  );
  dataObj.carrierId = carrier;
  //producer
  dataObj.producer.email = rateResults.AgentEmailAddress?.result;
  dataObj.producer.name = rateResults.OpAgentName?.result;
  //calculation
  dataObj.calculation.OpInsuredName = rateResults.OpInsuredName?.result;
  dataObj.calculation.OpInsured2LastName =
    rateResults.OpInsured2LastName?.result;
  dataObj.calculation.OpCarrierID = rateResults.OpCarrierID?.result;
  dataObj.calculation.OpInsuredEmailAddress =
    rateResults.OpInsuredEmailAddress?.result;
  dataObj.calculation.OpPostBindWorkflows =
    rateResults.OpPostBindWorkflows?.result;
  dataObj.calculation.OpNamedInsuredIndicator =
    rateResults.OpNamedInsuredIndicator?.result;
  dataObj.calculation.OpCarrierGroupID = rateResults.OpCarrierGroupID?.result;
  dataObj.calculation.OpInsured2FirstName =
    rateResults.OpInsured2FirstName?.result;
  dataObj.calculation.OpConsentToRate = rateResults.OpConsentToRate?.result;
  dataObj.calculation.OpAnimalLiabilityWaiver =
    rateResults.OpAnimalLiabilityWaiver?.result;
  dataObj.calculation.OpESignatureRecipient =
    rateResults.OpESignatureRecipient?.result;
  dataObj.calculation.OpESignatureEmailAddress =
    rateResults.OpESignatureEmailAddress?.result;
  dataObj.calculation.OpAdditionalEPolicyEmailAddress1 =
    rateResults.OpAdditionalEPolicyEmailAddress1?.result;
  dataObj.calculation.OpAdditionalEPolicyEmailAddress2 =
    rateResults.OpAdditionalEPolicyEmailAddress2?.result;
  dataObj.calculation.OpAdditionalEPolicyEmailAddress3 =
    rateResults.OpAdditionalEPolicyEmailAddress3?.result;
  dataObj.calculation.OpInsured2EmailAddress =
    rateResults.OpInsured2EmailAddress?.result;
  dataObj.calculation.OpESignatureCoApplicantEmailRequired =
    rateResults.OpESignatureCoApplicantEmailRequired?.result;
  dataObj.calculation.OpInsuredByCorporation =
    rateResults.OpInsuredByCorporation?.result;
  dataObj.calculation.OpESignatureSender =
    rateResults.OpESignatureSender?.result;
  dataObj.calculation.OpPreBindESignEmailSigners =
    rateResults.OpPreBindESignEmailSigners?.result;
  dataObj.calculation.OpPostBindESignEmailSigners =
    rateResults.OpPostBindESignEmailSigners?.result;
  dataObj.calculation.OpPreBindESignEmailReceivers =
    rateResults.OpPreBindESignEmailReceivers?.result;
  dataObj.calculation.OpPostBindESignEmailReceivers =
    rateResults.OpPostBindESignEmailReceivers?.result;

  //message
  dataObj.message.body = postBindDefaultEmailBody;
  dataObj.message.subject = `${setProvidedOrDefault(
    postBindWorkflow.postBindDefaultEmailSubject,
    `${carrier} Enrollment Documents for `
  )}${rateResults.PolicyID.result}`;
  dataObj.message.fromName = setProvidedOrDefault(
    postBindWorkflow.postBindDefaultEmailFromName,
    "SageSure Insurance Managers"
  );
  dataObj.message.fromAddress = setProvidedOrDefault(
    postBindWorkflow.postBindDefaultEmailFromAddress,
    "no-reply@sagesure.com"
  );
  //Submission
  dataObj.submission = {
    submission: postBindWorkflow.submission
  };
  //documents
  dataObj.documents.idList = documents
    .map(function (val) {
      return val.id;
    })
    .join(",");
  dataObj.documents.nameList = documents
    .map(function (val) {
      return val.label;
    })
    .join(",");
  dataObj.documents.pdfName = "Enrollment Package.pdf";
  //policy
  dataObj.policy.enrollmentPackageShowESig = setProvidedOrDefault(
    postBindWorkflow.enrollmentPackageShowESig,
    0
  );
  dataObj.policy.quoteId = rateResults.QuoteID.result;
  dataObj.policy.id = policyId;
  dataObj.policy.NFSEnabled = postBindWorkflow.nfsEnabled;
  dataObj.policy.enrollmentPackageCreateESigRequired = setProvidedOrDefault(
    postBindWorkflow.enrollmentPackageCreateESigRequired,
    0
  );
  dataObj.policy.enrollmentPackageSuppressPDFOption = setProvidedOrDefault(
    postBindWorkflow.enrollmentPackageSuppressPDFOption,
    1
  );
  //insured
  dataObj.insured.email = rateResults.OpInsuredEmailAddress?.result;
  dataObj.insured.name = rateResults.OpInsuredName?.result;
  //info
  dataObj.info.InsuredPhoneNumber = rateResults.OpInsuredPhoneNumber?.result;
  dataObj.info.OpCarrierID = rateResults.OpCarrierID?.result;
  dataObj.info.PropertyUsage = rateResults.OpPropertyUsage?.result;
  dataObj.info.ESignatureRecipient = rateResults.OpESignatureRecipient?.result;
  dataObj.info.OccupancyType = rateResults.OpOccupancyType?.result;
  dataObj.info.InsuredMailingAddressLine2 =
    rateResults.OpInsuredMailingAddressLine2?.result;
  dataObj.info.InsuredFirstName = rateResults.OpInsuredFirstName?.result;
  dataObj.info.ConstructionYear = rateResults.OpConstructionYear?.result;
  dataObj.info.PropertyAddressLine2 =
    rateResults.OpPropertyAddressLine2?.result;
  dataObj.info.InsuredMailingAddressCountry =
    rateResults.OpInsuredMailingAddressCountry?.result;
  dataObj.info.InsuredMailingAddressState =
    rateResults.OpInsuredMailingAddressState?.result;
  dataObj.info.CarrierID = rateResults.OpCarrierID?.result;
  dataObj.info.ESignatureEmailAddress =
    rateResults.OpESignatureEmailAddress?.result;
  dataObj.info.HomeStyle = rateResults.OpHomeStyle?.result;
  dataObj.info.PropertyStreetNumber =
    rateResults.OpPropertyStreetNumber?.result;
  dataObj.info.CarrierGroupId = rateResults.OpCarrierGroupID?.result;
  dataObj.info.PropertyStreetName = rateResults.OpPropertyStreetName?.result;
  dataObj.info.InsuredMailingAddressZip =
    rateResults.OpInsuredMailingAddressZip?.result;
  dataObj.info.ConstructionType = rateResults.OpConstructionType?.result;
  dataObj.info.FoundationType = rateResults.OpFoundationType?.result;
  dataObj.info.ReplacementCostBuilding =
    rateResults.OpReplacementCostBuilding?.result;
  dataObj.info.InsuredMailingAddressLine1 =
    rateResults.OpInsuredMailingAddressLine1?.result;
  dataObj.info.InsuredLastName = rateResults.OpInsuredLastName?.result;
  dataObj.info.InsuredMailingAddressCity =
    rateResults.OpInsuredMailingAddressCity?.result;
  dataObj.info.PropertyState = rateResults.OpPropertyState?.result;
  dataObj.info.PropertyOccupancy = rateResults.OpPropertyOccupancy?.result;
  dataObj.info.PropertyZipCode = rateResults.OpPropertyZipCode?.result;
  dataObj.info.PropertyCity = rateResults.OpPropertyCity?.result;
  dataObj.info.OpCarrierGroupID = rateResults.OpCarrierGroupID?.result;

  return dataObj;
};
